import {
  AddSummaryComponentBtn,
  CalculatorFieldInput,
  CalculatorInput,
  CancelBtn,
  DeleteBtn,
  DisableAutocompleteInput,
  DraperyHeader,
  DuplicateBtn,
  EditButton,
  HContainer,
  SummaryComponentTable,
  SummaryComponentTableCell,
  SummaryComponentTableRow,
  SummaryContainer,
  SummaryContent,
  SummaryInfoBlock,
  SummaryTable,
  SummaryTableCell,
  SummaryTableCol,
  SummaryTimeline,
  WorkOrderNumber,
  CalculatorFormField,
  CalculatorYardageInput,
} from "./styles";
import EditIcon from "../../assets/icons/edit.svg";
import { Select as SelectE } from "@ramble/ramble-ui";
import styled from "../../theme";
import { DraperyModule, WorkOrderModule } from "../../redux/modules/index";
import {
  DraperyOrderDesignEmbellishment,
  DraperyOrderFabric,
  EmbellishmentCheckInfo,
  FabricPatternItem,
} from "./calculator";
import React, { ChangeEvent, PureComponent } from "react";

import SelectList from "../UI/select-list";
import { ValidationErrorInfo } from "./interfaces-common";
import { WorkOrderPdfContent } from "../../redux/modules/work_order";
import { FormFields } from "../../redux/modules/my-shop/form-fields-reducer";
import { TextArea } from "@ramble/ramble-ui";
import { getDraperyOrderPrice } from "../../api/drapery-order-price";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../reactDatePicker/ReactDatePicker.css";
import moment from "moment";
import RedWarning from "../../assets/icons/red_warning.svg";
import { searchWorkroomSetting } from "../../api/workroom-setting";
import { formatSectionTotalPrice } from "../my-shop/helpers";

const paidList = ["No", "Partial", "Yes"];
const property = ["W:", "VR:", "HR:"];

const WorkorderMeasure = styled.div`
    display: flex;
    justify-content: center;
    gap: 5px;
    font-size: 1rem;
    &.last-separator:last-of-type p span {
        display: none;
    }
`;

const WorkorderMeasureWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
`;

const WorkOrderNumberNoLink = styled.span`
    padding: 10px;
    color: rgba(27, 28, 29, 0.87);
    font-weight: bold;
`;
const DraftCheckBox = styled.div`
    display: flex;
    align-items: center;
    // accent-color: #fff;
    input[type="checkbox"] {
        margin-right: 5px;
        color: #438cac;
        border: 2px solid #3b97b1;
        height: 15px;
        width: 15px;
        background-color: transparent;
        -webkit-appearance: none;
    }
    input[type="checkbox"]:before {
        content: "";
        display: block;
        transition: transform 200ms;
    }

    input[type="checkbox"]:checked:before {
        width: 9px;
        height: 20px;
        margin: -10px 5px;
        border-bottom: 3px solid #28586f;
        border-right: 3px solid #28586f;
        transform: rotate(45deg);
    }
    span {
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }
`;
const Draft = styled.span`
    color: #e74f77;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
`;
const WOContainer = styled.div`
    display: flex;
    align-items: center;
`;
const WorkOrderLink = styled.div`
    display: flex;
    gap: 7px;
`;
const TitleNotes = styled.h3`
    text-align: start;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-weight: 400 !important;
    color: rgb(84, 84, 84);
    font-size: 15px;
    margin-bottom: 5px;
`;
const Select = styled(SelectE)`
    .select {
        border-radius: 5px;
        padding: 2px 30px 2px 2px;
        white-space: pre-line;
    }
`;
export const defaultDraperyOrderSummary =
  (): DraperyModule.DraperyOrderSummary => ({
    id: 0,
    planningJson: {
      isDesign: false,
      isFabrics: false,
      isMeasurements: false,
      isHardware: false,
    },
    estimateJson: {
      num: "",
      description: "",
      dateSent: "",
    },
    invoiceJson: {
      num: "",
      description: "",
      paid: "",
    },
    componentsJson: {
      ordered: "",
      received: "",
    },
    productionJson: {},
    installationJson: {},
    notes: "",
    totalPerUnit: null,
    headrailPerUnit: null
  });

export const defaultHeadRailSummaryComponent = (item: string) => {
  return {
    id: 0,
    item,
    draperyOrderSummaryId: 0,
  }
}
export const defaultHeadRail = () => {
  return {
    id: 0,
    draperyOrderId: 0,
    name: "HEADRAIL",
    draperyOrderSummaryComponent: defaultHeadRailSummaryComponent('HEADRAIL')
  }
}
export const duplicateHeadRail = (fabric: any) => {
  return {
      ...fabric,
      id: 0,
      draperyOrderId: 0,
      draperyOrderSummaryComponent: defaultHeadRailSummaryComponent(
          fabric.draperyOrderSummaryComponent.item
      ),
  };
};
export const filterHeadRail = (
  headRail: DraperyModule.DraperyOrderFabric & { supplierName?: string } & {
    customSupplier?: string;
  },
  component: DraperyModule.DraperyOrderSummaryComponent
): any => ({
  id: headRail.id,
  draperyOrderId: headRail.draperyOrderId,
  name: "HEADRAIL",
  draperyOrderSummaryComponent: component,
});

// validate
export const validateDraperyOrderSummary = (
  draperyOrderSummary: DraperyModule.DraperyOrderSummary
): ValidationErrorInfo[] => {
  // const prefixName = "draperyOrderSummary.";
  // const tabIndex = 2;
  const errors: ValidationErrorInfo[] = [];
  // if (draperyOrderSummary.invoiceJson.description === undefined) { errors.push({tabIndex, name: prefixName + "invoiceJson.description", message: "Please select invoice description"}); }
  return errors;
};

export const validateDraperyOrderSummaryComponent = (
  draperyOrderSummaryComponent: DraperyModule.DraperyOrderSummaryComponent,
  prefixName: string,
  isFabric?: boolean
): ValidationErrorInfo[] => {
  const tabIndex = 3;
  const errors: ValidationErrorInfo[] = [];
  // if (draperyOrderSummaryComponent.draperyOrderFabricId === 0 && isFabric) {
  //     errors.push({
  //         tabIndex: 2,
  //         name: "",
  //         message: "Please save fabric first",
  //     });
  // }
  if (!draperyOrderSummaryComponent.item) {
    errors.push({
      tabIndex,
      name: prefixName + "item",
      message: "Please input component item",
    });
  }
  return errors;
};

export const validateDraperyItemPricingTab = (
  draperyItemDetail: FormFields[]
): ValidationErrorInfo[] => {
  let errors: ValidationErrorInfo[] = [];
  const draperyItems = draperyItemDetail.filter(
    (item) => item.name === "Drapery"
  );
  const draperyItemDetailsKeys = [
    "pricingTypeId",
    "clientPricingAction",
    "pleatTypesWithPricing",
    "liningAndInterliningWithPricing",
    "cafeCurtainsWidthInches",
    "cafeCurtainsWidthPricing",
    "extraLargePanelsLengthInches",
    "extraLargePanelsLengthPricing",
    "extraLargePanelsWidthInches",
    "extraLargePanelsWidthPricing",
    "draperyPinningWidthPricing",
    "specialityFabricsMarkUpPercentage",
    "embellishmentsWithPricing",
    "addOnsWithPricing",
    "salesDescriptionSettings",
    "orderingDescriptionSettings",
  ];
  if (!draperyItems[0]["isClientPricingActionOn"]) {
    const index = draperyItemDetailsKeys.findIndex(
      (key) => key === "clientPricingValue"
    );
    if (index >= 0) draperyItemDetailsKeys.splice(index, 1);
  }
  if (!draperyItems[0]["isPartialWidthRoundingOn"]) {
    const index = draperyItemDetailsKeys.findIndex(
      (key) => key === "partialWidthRoundUpTypeId"
    );
    if (index >= 0) draperyItemDetailsKeys.splice(index, 1);
  }

  let errorAdded = false;

  for (const key of draperyItemDetailsKeys) {
    const value = draperyItems[0][key];

    if (!value || (typeof value === "string" && value.trim() === "")) {
      if (!errorAdded) {
        errors.push({
          tabIndex: 3,
          name: ["draperyItemPricing"],
          message:
            "Drapery item must be setup in My Shop prior to pricing this order.",
          itemId: draperyItems[0].itemId,
          itemClassId: draperyItems[0].itemClassId,
        });
        errorAdded = true;
      }
      break;
    }
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].price === "") {
          if (!errorAdded) {
            errors.push({
              tabIndex: 3,
              name: ["draperyItemPricing"],
              message:
                "Drapery item must be setup in My Shop prior to pricing this order.",
              itemId: draperyItems[0].itemId,
              itemClassId: draperyItems[0].itemClassId,
            });
            errorAdded = true;
          }
          break;
        }
      }
    }

    if (errorAdded) {
      break;
    }
  }
  return errors;
};



export const validateRipplefoldPricing = (
  draperyItemDetail: FormFields[]
): ValidationErrorInfo[] => {
  let errors: ValidationErrorInfo[] = [];
  const draperyItems = draperyItemDetail.filter(
    (item) => item.name === "Drapery"
  );
  const draperyItemDetailsKeys = ["pleatTypesWithPricing"];

  if (draperyItems.length > 0) {
    for (const key of draperyItemDetailsKeys) {
      const value = draperyItems[0][key];

      if (Array.isArray(value)) {
        const ripplefoldItem = value.find(
          (item) => item.id === "ripplefold" && item.price === ""
        );
        if (ripplefoldItem) {
          errors.push({
            tabIndex: 3,
            name: ["draperyItemPricing"],
            message:
              "Pricing for Ripplefold header required in My Shop > Drapery Item.",
            itemId: ripplefoldItem.id,
            itemClassId: ripplefoldItem.itemClassId || null,
          });
          break;
        }
      }
    }
  }

  return errors;
};

const getEmbellishmentComps = ({
  list,
}: {
  list: DraperyOrderDesignEmbellishment[];
}) => {
  const result: DraperyModule.DraperyOrderSummaryComponent[] = [];
  if (!list || !list.length) {
    return result;
  }
  list.forEach((item) => {
    const itemDOFabric = item.draperyOrderFabric;
    if (itemDOFabric) {
      result.push(itemDOFabric.draperyOrderSummaryComponent);
    }
  });
  return result;
};

type GetComponentListParams = {
  bandings: DraperyOrderDesignEmbellishment[];
  cordings: DraperyOrderDesignEmbellishment[];
  flanges: DraperyOrderDesignEmbellishment[];
  trims: DraperyOrderDesignEmbellishment[];
  othersEmbellishments: DraperyOrderDesignEmbellishment[];
  mainFabric: DraperyOrderFabric;
  embellishmentsList: EmbellishmentCheckInfo[];
  draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[];
};

export const getComponentList = (compListParams: GetComponentListParams) => {
  const {
    bandings,
    cordings,
    flanges,
    trims,
    othersEmbellishments,
    mainFabric,
    embellishmentsList,
    draperyOrderSummaryComponents,
  } = compListParams;
  const componentList: DraperyModule.DraperyOrderSummaryComponent[] = [];
  componentList.push(mainFabric.draperyOrderSummaryComponent);

  const [
    embell0Checked,
    embell1Checked,
    embell2Checked,
    embell3Checked,
    embell4Checked,
  ] = embellishmentsList.map((item) => item.checked);

  if (embell0Checked) {
    componentList.push(...getEmbellishmentComps({ list: bandings }));
  }
  if (embell1Checked) {
    componentList.push(...getEmbellishmentComps({ list: cordings }));
  }
  if (embell2Checked) {
    componentList.push(...getEmbellishmentComps({ list: flanges }));
  }
  if (embell3Checked) {
    componentList.push(...getEmbellishmentComps({ list: trims }));
  }
  if (embell4Checked) {
    componentList.push(
      ...getEmbellishmentComps({ list: othersEmbellishments })
    );
  }

  for (const component of draperyOrderSummaryComponents) {
    componentList.push(component);
  }
  return componentList;
};

// duplicate
export const duplicateDraperyOrderSummaryComponent = (
  component: DraperyModule.DraperyOrderSummaryComponent
): DraperyModule.DraperyOrderSummaryComponent => {
  return {
    ...component,
    id: 0,
    draperyOrderSummaryId: 0,
    draperyOrderFabricId: undefined,
  };
};

interface Props {
  draperyOrderId: number;
  draperyOrderSummary: DraperyModule.DraperyOrderSummary;

  bandings: DraperyOrderDesignEmbellishment[];
  cordings: DraperyOrderDesignEmbellishment[];
  flanges: DraperyOrderDesignEmbellishment[];
  trims: DraperyOrderDesignEmbellishment[];
  othersEmbellishments: DraperyOrderDesignEmbellishment[];
  mainFabric: DraperyOrderFabric;
  embellishmentsList: EmbellishmentCheckInfo[];
  draperyOrderSummaryComponents: DraperyModule.DraperyOrderSummaryComponent[];
  draperyOrder: DraperyModule.DraperyOrder;
  draperyOrderEditable: boolean;
  workOrder: WorkOrderModule.WorkOrder;
  draperyOrderErrors?: string[];
  draperyOrderSummaryErrors?: string[];
  workOrderStatus: "none" | "downloading" | "error";
  highlightedSection: string | null | undefined;
  data?: any;
  draperyItemDetail?: any;

  handleDuplicate(): void;
  handleCreateWorkOrder(val?: string): Promise<void>;
  handleEditWorkOrder(): Promise<void>;
  handleDownloadWorkOrderPdf(open: boolean): void;
  handleAddSummaryComponent(): void;
  handleDeleteSummaryComponent(id: number): void;
  // handleDownloadWorkOrderForSummery(): Promise<void>;
  handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
  handleInputChangeForString(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
  handleSelectListChange(value: string | number, name?: string): void;
  dispatch(arg: any): any;
  accountId: number;
  workOrderPdfContentSummery?: WorkOrderPdfContent;
  updateStateByName(name: string, value: string): void;
  draperyOrderPriceId: number;
  handleChangeDates(value: Date | null, field: string): void;
  returnDates(): any;
  editNavigate(tab: number): void;
  updateWorkOrder(val?: string): Promise<void>;
  workOrderEditMode: boolean;
  loadingUpdate: boolean;
  isLoading?: boolean;
  handleCreateOtherItemOrder(openPdf: boolean, yardage?: any): Promise<void>;
  headRail: any;
}

// interface LiningObject {
//     name: string;
//     draperyOrderLiningsFabric?: {
//         values: {
//             fabricWidth: number;
//         };
//     };
//     active: boolean;
// }

// tslint:disable-next-line:no-empty-interface

interface Price {
  basePrice: string;
  liningPrice: string;
  interliningPrice: string;
  bandingPrice: string;
  cordingPrePrice: string;
  cordingFabricPrice: string;
  flangePrePrice: string;
  flangeFabricPrice: string;
  trimPrePrice: string;
  trimFabricPrice: string;
  otherPrice: string;
  draperyPinningPrice: string;
  weightPrice: string;
  extraLongPrice: string;
  extraWidePrice: string;
  cafeCurtainPrice: string;
  calculatedSpecialityPrice: string;
  calculatedSpecialityLiningPrice: string;
  calculatedSpecialityInterliningPrice: string;
  calculatedSpecialityBandingPrice: string;
  calculatedSpecialityCordingPreMadePrice: string;
  calculatedSpecialityCordingFabricPrice: string;
  calculatedSpecialityFlangePreMadePrice: string;
  calculatedSpecialityFlangeFabricPrice: string;
  calculatedSpecialityTrimPreMadePrice: string;
  calculatedSpecialityTrimFabricPrice: string;
  calculatedSpecialityOtherPrice: string;
  totalFabricLiningPrice: string;
  totalFabricInterliningPrice: string;
  mainFabricUnitPrice: string;
  bandingUnitPrice: any;
  cordingUnitPrice: any;
  flangeUnitPrice: any;
  trimUnitPrice: any;
  liningsFabricUnitPrice: string;
  interliningsFabricUnitPrice: string;
  othersEmbellishmentsUnitPrice: any;
  mainFabricShippingPrice: string;
  bandingShippingPrice: any;
  cordingShippingPrice: any;
  trimShippingPrice: any;
  flangeShippingPrice: any;
  liningsFabricShippingPrice: string;
  interliningsFabricShippingPrice: string;
  othersEmbellishmentsShippingPrice: any;
}

interface State {
  pattern: number;
  isRecommendationsOverrides: boolean;
  calcPrice: number;
  workroomSettings: any[];
  prices: Price;
  mainFabricYardage: string | null,
  bandingsYardage: string[];
  cordingsPreMadeYardage: string[];
  flangesPreMadeYardage: string[];
  trimsPreMadeYardage: string[];
  cordingsFabricYardage: string[];
  flangesFabricYardage: string[];
  trimsFabricYardage: string[];
  liningsYardage: string[];
  otherEmbellishmentsYardage: string[];
}
const defaultPrices = (): Price => ({
  basePrice: "0.00",
  liningPrice: "0.00",
  interliningPrice: "0.00",
  bandingPrice: "0.00",
  cordingPrePrice: "0.00",
  cordingFabricPrice: "0.00",
  flangePrePrice: "0.00",
  flangeFabricPrice: "0.00",
  trimPrePrice: "0.00",
  trimFabricPrice: "0.00",
  otherPrice: "0.00",
  draperyPinningPrice: "0.00",
  weightPrice: "0.00",
  extraLongPrice: "0.00",
  extraWidePrice: "0.00",
  cafeCurtainPrice: "0.00",
  calculatedSpecialityPrice: "0.00",
  calculatedSpecialityLiningPrice: "0.00",
  calculatedSpecialityInterliningPrice: "0.00",
  calculatedSpecialityBandingPrice: "0.00",
  calculatedSpecialityCordingPreMadePrice: "0.00",
  calculatedSpecialityCordingFabricPrice: "0.00",
  calculatedSpecialityFlangePreMadePrice: "0.00",
  calculatedSpecialityFlangeFabricPrice: "0.00",
  calculatedSpecialityTrimPreMadePrice: "0.00",
  calculatedSpecialityTrimFabricPrice: "0.00",
  calculatedSpecialityOtherPrice: "0.00",
  totalFabricLiningPrice: "0.00",
  totalFabricInterliningPrice: "0.00",
  mainFabricUnitPrice: "0.00",
  bandingUnitPrice: [],
  cordingUnitPrice: [],
  flangeUnitPrice: [],
  trimUnitPrice: [],
  liningsFabricUnitPrice: "0.00",
  interliningsFabricUnitPrice: "0.00",
  othersEmbellishmentsUnitPrice: [],
  mainFabricShippingPrice: "0.00",
  bandingShippingPrice: [],
  cordingShippingPrice: [],
  trimShippingPrice: [],
  flangeShippingPrice: [],
  liningsFabricShippingPrice: "0.00",
  interliningsFabricShippingPrice: "0.00",
  othersEmbellishmentsShippingPrice: [],
});

class RomanShadeSummaryTab extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isRecommendationsOverrides: false,
      pattern: 0,
      calcPrice: 0,
      workroomSettings: [],
      prices: defaultPrices(),
      mainFabricYardage: null,
      bandingsYardage: [],
      cordingsPreMadeYardage: [],
      flangesPreMadeYardage: [],
      trimsPreMadeYardage: [],
      cordingsFabricYardage: [],
      flangesFabricYardage: [],
      trimsFabricYardage: [],
      liningsYardage: [],
      otherEmbellishmentsYardage: [],
    };
  }

  async componentDidMount(): Promise<void> {
    const { accountId } = this.props;
    try {
      const res = await searchWorkroomSetting(accountId);
      if (res && res.length > 0) {
        const workroomSettings = res;
        this.setState({ workroomSettings });
      } else {
        console.error("There is no information about the settings.");
      }
    } catch (error) {
      console.error("Error executing the dispatch function:", error);
    }
    this.props.handleDownloadWorkOrderPdf(true);
  }

  async componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): Promise<void> {
    if (nextProps.draperyOrderPriceId) {
      try {
        const response = await getDraperyOrderPrice(
          nextProps.draperyOrderPriceId
        );
        this.setState({
          prices: {
            ...response,
            bandingUnitPrice: response.bandingUnitPrice || [],
            cordingUnitPrice: response.cordingUnitPrice || [],
            flangeUnitPrice: response.flangeUnitPrice || [],
            trimUnitPrice: response.trimUnitPrice || [],
            othersEmbellishmentsUnitPrice:
              response.othersEmbellishmentsUnitPrice || [],
            bandingShippingPrice:
              response.bandingShippingPrice || [],
            cordingShippingPrice:
              response.cordingShippingPrice || [],
            trimShippingPrice: response.trimShippingPrice || [],
            flangeShippingPrice: response.flangeShippingPrice || [],
            othersEmbellishmentsShippingPrice:
              response.othersEmbellishmentsShippingPrice || [],
          },

        });
      } catch (error) {
        console.error("Error executing the dispatch function:", error);
      }
    }

    if (this.props.mainFabric.yardsNeeded !== nextProps.mainFabric.yardsNeeded) {
      this.setState({ mainFabricYardage: nextProps.mainFabric && nextProps.mainFabric.yardsNeeded !== undefined && nextProps.mainFabric.yardsNeeded !== null ? nextProps.mainFabric.yardsNeeded.toString() : "" })
    }
    const bandingsYardage = [...this.state.bandingsYardage]; // Create a copy of the existing state
    nextProps.bandings.forEach((nextBanding, index) => {
      const currentBanding = this.props.bandings[index];
      if (
        currentBanding &&
        currentBanding.draperyOrderFabric &&
        nextBanding.draperyOrderFabric &&
        nextBanding.draperyOrderFabric.yardsNeeded !== undefined && nextBanding.draperyOrderFabric.yardsNeeded !== null &&
        currentBanding.draperyOrderFabric.yardsNeeded !== nextBanding.draperyOrderFabric.yardsNeeded && this.props.workOrderEditMode
        || !this.props.workOrderEditMode && nextBanding.draperyOrderFabric && nextBanding.draperyOrderFabric.yardsNeeded !== undefined && nextBanding.draperyOrderFabric.yardsNeeded !== null
      ) {
        bandingsYardage[index] = nextBanding.draperyOrderFabric.yardsNeeded.toString();
      }
    });
    let cordingsPreMadeYardage = [...this.state.cordingsPreMadeYardage];
    let cordingsFabricYardage = [...this.state.cordingsFabricYardage];
    let flangesPreMadeYardage = [...this.state.flangesPreMadeYardage];
    let flangesFabricYardage = [...this.state.flangesFabricYardage];
    let trimsPreMadeYardage = [...this.state.trimsPreMadeYardage];
    let trimsFabricYardage = [...this.state.trimsFabricYardage];
    let liningsYardage = [...this.state.liningsYardage];
    let otherEmbellishmentsYardage = [...this.state.otherEmbellishmentsYardage];
    nextProps.cordings.filter((item) => item.subType === 0).forEach((nextCording, index) => {
      const currentCording = this.props.cordings.filter((item) => item.subType === 0)[index];
      if (
        currentCording &&
        currentCording.draperyOrderFabric &&
        nextCording.draperyOrderFabric &&
        nextCording.draperyOrderFabric.yardsNeeded !== undefined &&
        nextCording.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentCording.draperyOrderFabric.yardsNeeded !== nextCording.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextCording.subType === 0) {
          cordingsPreMadeYardage[index] = nextCording.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.cordings.filter((item) => item.subType === 1).forEach((nextCording, index) => {
      const currentCording = this.props.cordings.filter((item) => item.subType === 1)[index];
      if (
        currentCording &&
        currentCording.draperyOrderFabric &&
        nextCording.draperyOrderFabric &&
        nextCording.draperyOrderFabric.yardsNeeded !== undefined &&
        nextCording.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentCording.draperyOrderFabric.yardsNeeded !== nextCording.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextCording.subType === 1) {
          cordingsFabricYardage[index] = nextCording.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.flanges.filter((item) => item.subType === 0).forEach((nextFlange, index) => {
      const currentFlange = this.props.flanges.filter((item) => item.subType === 0)[index];
      if (
        currentFlange &&
        currentFlange.draperyOrderFabric &&
        nextFlange.draperyOrderFabric &&
        nextFlange.draperyOrderFabric.yardsNeeded !== undefined &&
        nextFlange.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentFlange.draperyOrderFabric.yardsNeeded !== nextFlange.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextFlange.subType === 0) {
          flangesPreMadeYardage[index] = nextFlange.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.flanges.filter((item) => item.subType === 1).forEach((nextFlange, index) => {
      const currentFlange = this.props.flanges.filter((item) => item.subType === 1)[index];
      if (
        currentFlange &&
        currentFlange.draperyOrderFabric &&
        nextFlange.draperyOrderFabric &&
        nextFlange.draperyOrderFabric.yardsNeeded !== undefined &&
        nextFlange.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentFlange.draperyOrderFabric.yardsNeeded !== nextFlange.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextFlange.subType === 1) {
          flangesFabricYardage[index] = nextFlange.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.trims.filter((item) => item.subType === 0).forEach((nextTrim, index) => {
      const currentTrim = this.props.trims.filter((item) => item.subType === 0)[index];
      if (
        currentTrim &&
        currentTrim.draperyOrderFabric &&
        nextTrim.draperyOrderFabric &&
        nextTrim.draperyOrderFabric.yardsNeeded !== undefined &&
        nextTrim.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentTrim.draperyOrderFabric.yardsNeeded !== nextTrim.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextTrim.subType === 0) {
          trimsPreMadeYardage[index] = nextTrim.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.trims.filter((item) => item.subType === 1).forEach((nextTrim, index) => {
      const currentTrim = this.props.trims.filter((item) => item.subType === 1)[index];
      if (
        currentTrim &&
        currentTrim.draperyOrderFabric &&
        nextTrim.draperyOrderFabric &&
        nextTrim.draperyOrderFabric.yardsNeeded !== undefined &&
        nextTrim.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentTrim.draperyOrderFabric.yardsNeeded !== nextTrim.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextTrim.subType === 1) {
          trimsFabricYardage[index] = nextTrim.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.othersEmbellishments.forEach((nextOtherEmbellishmnet, index) => {
      const currentothersEmbellishment = this.props.othersEmbellishments[index];
      if (currentothersEmbellishment &&
        currentothersEmbellishment.draperyOrderFabric &&
        nextOtherEmbellishmnet.draperyOrderFabric &&
        nextOtherEmbellishmnet.draperyOrderFabric.yardsNeeded !== undefined &&
        nextOtherEmbellishmnet.draperyOrderFabric.yardsNeeded !== null &&
        (
          (this.props.workOrderEditMode &&
            currentothersEmbellishment.draperyOrderFabric.yardsNeeded !== nextOtherEmbellishmnet.draperyOrderFabric.yardsNeeded) ||
          (!this.props.workOrderEditMode)
        )
      ) {
        if (nextOtherEmbellishmnet) {
          otherEmbellishmentsYardage[index] = nextOtherEmbellishmnet.draperyOrderFabric.yardsNeeded.toString();
        }
      }
    });
    nextProps.data.liningsList.forEach((nextLining: any, index: any) => {
      const currentLining = this.props.data.liningsList[index];
      if (
        currentLining &&
        currentLining.draperyOrderLiningsFabric &&
        nextLining.draperyOrderLiningsFabric &&
        nextLining.draperyOrderLiningsFabric.yardsNeeded !== undefined && nextLining.draperyOrderLiningsFabric.yardsNeeded !== null &&
        currentLining.draperyOrderLiningsFabric.yardsNeeded !== nextLining.draperyOrderLiningsFabric.yardsNeeded && this.props.workOrderEditMode
        || !this.props.workOrderEditMode && nextLining.draperyOrderLiningsFabric && nextLining.draperyOrderLiningsFabric.yardsNeeded !== undefined && nextLining.draperyOrderLiningsFabric.yardsNeeded !== null
      ) {
        liningsYardage[index] = nextLining.draperyOrderLiningsFabric.yardsNeeded.toString();
      }
    })
    this.setState({ bandingsYardage, cordingsPreMadeYardage, cordingsFabricYardage, flangesPreMadeYardage, flangesFabricYardage, trimsPreMadeYardage, trimsFabricYardage, liningsYardage, otherEmbellishmentsYardage });
  }

  private getPricingSectionPrice = (item: FabricPatternItem) => {
    const client = this.props.data.client;
    if (client) {
      const pricingSection = client.billToParent ? client.billingDetails.billingType : client.billingDetailsDirectly.billingType;
      const pricingSectionLower = pricingSection.toLowerCase();

      const pricingMethodTextKey = `${pricingSectionLower}PricingMethod`;
      const pricingMethodValueKey = `${pricingSectionLower}PricingValue`;

      // Initialize empty or default values
      let pricingMethodText = null;
      let pricingMethodValue = null;
      let msrp = null;
      let cost = null;

      // Conditionally assign values if 'item' is defined
      if (item) {
        pricingMethodText = item[pricingMethodTextKey];
        pricingMethodValue = item[pricingMethodValueKey];
        msrp = item.msrp;
        cost = item.cost;
      }

      if (
        ["discountMsrpRetail", "discountMsrpTrade"].includes(
          pricingMethodText
        )
      ) {
        if (msrp && pricingMethodValue) {
          msrp = +msrp;
          pricingMethodValue = +pricingMethodValue;
          const discountedVal = (pricingMethodValue / 100) * msrp;
          return formatSectionTotalPrice(msrp - discountedVal).replace("$", "");
        } else {
          return "TBD";
        }
      }

      if (
        ["markUpCostRetail", "markUpCostTrade"].includes(pricingMethodText)
      ) {
        if (cost && pricingMethodValue) {
          cost = +cost;
          pricingMethodValue = +pricingMethodValue;
          const markUpVal = (pricingMethodValue / 100) * cost;
          return formatSectionTotalPrice(cost + markUpVal).replace("$", "");
        } else {
          return "TBD";
        }
      }

      if (["costPlusRetail", "costPlusTrade"].includes(pricingMethodText)) {
        if (cost && pricingMethodValue) {
          cost = +cost;
          pricingMethodValue = +pricingMethodValue;
          return formatSectionTotalPrice(cost + pricingMethodValue).replace("$", "");
        } else {
          return "TBD";
        }
      }

      return pricingMethodValue ? `$${pricingMethodValue}`.replace("$", "") : "";
    }

  };

  private setYardage = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const nameList = name.split('.')
    if (nameList.length === 1) {
      let processedValue = value.replace(/^0+(?=\d)/, '');
      if (processedValue === '') {
        processedValue = '';
      }
      const regex = /^\d{0,5}(\.\d{0,2})?$/;
      if (regex.test(processedValue)) {
        this.setState((state) => ({
          ...state,
          [nameList[0]]: processedValue
        }));
      }
    } else if (nameList.length === 2) {
      const index = +nameList[1];
      const fabricName = [...this.state[nameList[0]]];
      let processedValue = value.replace(/^0+(?=\d)/, '');
      if (processedValue === '') {
        processedValue = '';
      }
      const regex = /^\d{0,5}(\.\d{0,2})?$/;
      if (regex.test(processedValue)) {
        fabricName[index] = processedValue;
        this.setState((state) => ({
          ...state,
          [nameList[0]]: fabricName
        }))
      }
    }
  }

  getChildState(): any {
    const yardage = {
      mainFabricYardage: this.state.mainFabricYardage,
      bandingsYardage: this.state.bandingsYardage,
      cordingsPreMadeYardage: this.state.cordingsPreMadeYardage,
      flangesPreMadeYardage: this.state.flangesPreMadeYardage,
      trimsPreMadeYardage: this.state.trimsPreMadeYardage,
      cordingsFabricYardage: this.state.cordingsFabricYardage,
      flangesFabricYardage: this.state.flangesFabricYardage,
      trimsFabricYardage: this.state.trimsFabricYardage,
      liningsYardage: this.state.liningsYardage,
      otherEmbellishmentsYardage: this.state.otherEmbellishmentsYardage,
    }
    return yardage
  }

  getChildStateEmpty(): any{
    this.setState({
        mainFabricYardage: null,
        bandingsYardage: [],
        cordingsPreMadeYardage: [],
        flangesPreMadeYardage: [],
        trimsPreMadeYardage: [],
        cordingsFabricYardage: [],
        flangesFabricYardage: [],
        trimsFabricYardage: [],
        liningsYardage: [],
        otherEmbellishmentsYardage: [],
    })
  }

  public render() {
    const {
      draperyOrderSummary,
      workOrder,
      mainFabric,
      bandings,
      cordings,
      flanges,
      trims,
      othersEmbellishments,
      embellishmentsList,
      draperyOrderSummaryComponents,
      workOrderEditMode,
      loadingUpdate,
      headRail,
    } = this.props;

    const datesForPicker = this.props.returnDates();

    const componentList = getComponentList({
      bandings,
      cordings,
      flanges,
      trims,
      othersEmbellishments,
      mainFabric,
      embellishmentsList,
      draperyOrderSummaryComponents,
    });

    let componentChecked = true;
    for (const component of componentList) {
      if (!component.receivedDate) {
        componentChecked = false;
        break;
      }
    }

    let cancelClickHandler = () =>
      this.props.handleCreateWorkOrder("delete_messag");
    let editCreate = "Create";

    if (workOrder.id) {
      cancelClickHandler = this.props.handleEditWorkOrder;
      editCreate = "Edit";
    }
    const yardage = {
      mainFabricYardage: this.state.mainFabricYardage,
      bandingsYardage: this.state.bandingsYardage,
      cordingsPreMadeYardage: this.state.cordingsPreMadeYardage,
      flangesPreMadeYardage: this.state.flangesPreMadeYardage,
      trimsPreMadeYardage: this.state.trimsPreMadeYardage,
      cordingsFabricYardage: this.state.cordingsFabricYardage,
      flangesFabricYardage: this.state.flangesFabricYardage,
      trimsFabricYardage: this.state.trimsFabricYardage,
      liningsYardage: this.state.liningsYardage,
      otherEmbellishmentsYardage: this.state.otherEmbellishmentsYardage,
    }
    const cancelBtn = (
      <CancelBtn
        uiColor="primary"
        type="button"
        onClick={this.props.data.orderType === 'roman-shade-order' ? () => this.props.handleCreateOtherItemOrder(false, yardage) : cancelClickHandler}
        className="create-btn"
        disabled={this.props.isLoading}
        uiDisabled={this.props.isLoading}
      >
        {editCreate}
      </CancelBtn>
    );

    let quantity: number =
      this.props.data &&
      this.props.data.workOrderPdfContent &&
      this.props.data.workOrderPdfContent.quantity;

    let shippingCostLining: number = 0;
    let costLining: number = 0;
    let shippingCostInterlining: number = 0;
    let costInterlining: number = 0;
    let liningYards: number = 0;
    let interliningYards: number = 0;
    let totalFabricLining: number = 0;
    let totalFabricInterlining: number = 0;
    let sumLiningInterlining: number = 0;
    if (this.props.data && this.props.data.liningsList) {
      this.props.data.liningsList.forEach((item: any) => {
        if (item.active && item.name === "Lining") {
          shippingCostLining =
            (item.draperyOrderLiningsFabric &&
              item.draperyOrderLiningsFabric.amount) ||
            0;
          costLining =
            (item.draperyOrderLiningsFabric &&
              item.draperyOrderLiningsFabric.calcPrice) ||
            0;
          if (
            this.props.data &&
            this.props.data.workOrderPdfContentSummery
          ) {
            liningYards =
              this.props.data.workOrderPdfContentSummery
                .liningYDS || 0;
          } else {
            liningYards = 0;
          }
          totalFabricLining =
            liningYards * costLining + shippingCostLining;
        } else if (item.active && item.name === "Interlining") {
          shippingCostInterlining =
            (item.draperyOrderLiningsFabric &&
              item.draperyOrderLiningsFabric.amount) ||
            0;
          costInterlining =
            (item.draperyOrderLiningsFabric &&
              item.draperyOrderLiningsFabric.calcPrice) ||
            0;
          if (
            this.props.data &&
            this.props.data.workOrderPdfContentSummery
          ) {
            interliningYards =
              this.props.data.workOrderPdfContentSummery
                .interliningYDS || 0;
          } else {
            interliningYards = 0;
          }
          totalFabricInterlining =
            quantity * interliningYards * costInterlining +
            shippingCostInterlining;
        }
      });
    }

    const calculateTotal = () => {
      const {
        basePrice,
        liningPrice,
        interliningPrice,
        bandingPrice,
        cordingPrePrice,
        cordingFabricPrice,
        flangePrePrice,
        flangeFabricPrice,
        trimPrePrice,
        trimFabricPrice,
        otherPrice,
        draperyPinningPrice,
        weightPrice,
        cafeCurtainPrice,
        extraLongPrice,
        extraWidePrice,
        calculatedSpecialityPrice,
        calculatedSpecialityLiningPrice,
        calculatedSpecialityInterliningPrice,
        calculatedSpecialityBandingPrice,
        calculatedSpecialityFlangePreMadePrice,
        calculatedSpecialityFlangeFabricPrice,
        calculatedSpecialityCordingPreMadePrice,
        calculatedSpecialityCordingFabricPrice,
        calculatedSpecialityTrimPreMadePrice,
        calculatedSpecialityTrimFabricPrice,
        calculatedSpecialityOtherPrice,
      } = this.state.prices;

      let total = 0;

      total += parseFloat(basePrice);

      total += parseFloat(liningPrice);

      total += parseFloat(interliningPrice);

      total += parseFloat(bandingPrice);

      total += parseFloat(cordingPrePrice);

      total += parseFloat(cordingFabricPrice);

      total += parseFloat(flangePrePrice);

      total += parseFloat(flangeFabricPrice);

      total += parseFloat(trimPrePrice);

      total += parseFloat(trimFabricPrice);

      total += parseFloat(draperyPinningPrice);

      const weightPriceParsed =
        weightPrice !== null ? weightPrice : "0.00";
      total += parseFloat(weightPriceParsed);

      total += parseFloat(otherPrice);

      total += parseFloat(extraLongPrice);

      total += parseFloat(extraWidePrice);

      total += parseFloat(cafeCurtainPrice);

      total += parseFloat(calculatedSpecialityPrice);

      total += parseFloat(calculatedSpecialityLiningPrice);

      total += parseFloat(calculatedSpecialityInterliningPrice);

      total += parseFloat(calculatedSpecialityBandingPrice);

      total += parseFloat(calculatedSpecialityFlangePreMadePrice);

      total += parseFloat(calculatedSpecialityFlangeFabricPrice);

      total += parseFloat(calculatedSpecialityCordingPreMadePrice);

      total += parseFloat(calculatedSpecialityCordingFabricPrice);

      total += parseFloat(calculatedSpecialityTrimPreMadePrice);

      total += parseFloat(calculatedSpecialityTrimFabricPrice);

      total += parseFloat(calculatedSpecialityOtherPrice);

      const liningsList = this.props.data.liningsList;
      let liningYardageNeeded: number = 0;

      if (liningsList) {
        const liningsFabric = liningsList.find(
          (item: any) => item.name === "Lining"
        );
        if (
          liningsFabric &&
          liningsFabric.draperyOrderLiningsFabric &&
          liningsFabric.name === "Lining"
        ) {
          liningYardageNeeded =
            liningsFabric.draperyOrderLiningsFabric.yardsNeeded ||
            0;
        }
      }

      const liningUnitPrice = parseFloat(
        this.state.prices.liningsFabricUnitPrice
      );
      const liningSHPrice = parseFloat(
        this.state.prices.liningsFabricShippingPrice
      );

      totalFabricLining =
        isNaN(liningYardageNeeded) ||
          isNaN(liningUnitPrice) ||
          isNaN(liningSHPrice) ||
          !quantity
          ? 0
          : (liningYardageNeeded * liningUnitPrice + liningSHPrice) /
          quantity;

      let interliningYardageNeeded: number = 0;

      if (liningsList) {
        const interliningsFabric = liningsList.find(
          (item: any) => item.name === "Interlining"
        );
        if (
          interliningsFabric &&
          interliningsFabric.draperyOrderLiningsFabric &&
          interliningsFabric.name === "Interlining"
        ) {
          interliningYardageNeeded =
            interliningsFabric.draperyOrderLiningsFabric
              .yardsNeeded || 0;
        }
      }
      const interliningUnitPrice = parseFloat(
        this.state.prices.interliningsFabricUnitPrice
      );
      const interliningSHPrice = parseFloat(
        this.state.prices.interliningsFabricShippingPrice
      );

      totalFabricInterlining =
        isNaN(interliningYardageNeeded) ||
          isNaN(interliningUnitPrice) ||
          isNaN(interliningSHPrice) ||
          !quantity
          ? 0
          : (interliningYardageNeeded * interliningUnitPrice +
            interliningSHPrice) /
          quantity;

      sumLiningInterlining = totalFabricLining + totalFabricInterlining;

      const fullTotal = total + sumLiningInterlining;
      return fullTotal.toFixed(2);
    };

    let liningMaterialYardage: number | undefined;
    let interliningMaterialYardage: number | undefined;

    let totalPerUnit: string | undefined;
    const totalPriceQuantity = parseFloat(calculateTotal());
    totalPerUnit = totalPriceQuantity.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    //WORK ORDER DESCRIPTION

    const draperyItemDetail = this.props.data.draperyItemDetail;
    let salesDescriptionSettingsMapped: any[] = [];

    if (draperyItemDetail && draperyItemDetail.length > 0) {
      const firstDraperyItem = draperyItemDetail[0];

      if (firstDraperyItem.salesDescriptionSettings) {
        salesDescriptionSettingsMapped =
          firstDraperyItem.salesDescriptionSettings;
      }
    }

    // BASE PRICE TOTAL
    let calculatedBasePrice: string = this.state.prices.basePrice;

    const formattedPrice = calculatedBasePrice;

    const basePriceElement = document.querySelector(".basePrice");
    if (basePriceElement) {
      basePriceElement.textContent = `${formattedPrice} Base`;
    }
    // LINING AND INTERLINING PRICE
    const liningActive = this.props.data.liningsList;

    let calculatedLiningPrice: string = this.state.prices.liningPrice;
    let calculatedInterliningPrice: string =
      this.state.prices.interliningPrice;

    const formattedLiningPrice = calculatedLiningPrice;
    const formattedInterliningPrice = calculatedInterliningPrice;

    const liningPriceElement = document.querySelector(".liningPrice");
    const interliningPriceElement =
      document.querySelector(".interliningPrice");

    const liningMaterialPriceElement = document.querySelector(
      ".liningMaterialPrice"
    );
    const interliningMaterialPriceElement = document.querySelector(
      ".interliningMaterialPrice"
    );
    const formattedTotalFabricLining = totalFabricLining.toFixed(2);
    const formattedTotalFabricInterlining =
      totalFabricInterlining.toFixed(2);

    if (Array.isArray(liningActive)) {
      const lining = liningActive.find(
        (item) => item.name === "Lining" && item.active === true
      );

      if (lining) {
        if (liningPriceElement) {
          liningPriceElement.textContent = `${formattedLiningPrice} Lining Fabrication`;
        }
        if (
          liningMaterialPriceElement &&
          parseFloat(formattedTotalFabricLining) >= 0
        ) {
          liningMaterialPriceElement.textContent = `${formattedTotalFabricLining} Lining Material`;
        }
      } else {
        if (liningPriceElement) {
          liningPriceElement.textContent = "";
        }
      }
    }

    if (Array.isArray(liningActive)) {
      const interlining = liningActive.find(
        (item) => item.name === "Interlining" && item.active === true
      );

      if (interlining) {
        if (interliningPriceElement) {
          interliningPriceElement.textContent = `${formattedInterliningPrice} Interlining Fabrication`;
        }
        if (
          interliningMaterialPriceElement &&
          parseFloat(formattedTotalFabricInterlining) >= 0
        ) {
          interliningMaterialPriceElement.textContent = `${formattedTotalFabricInterlining} Interlining Material`;
        }
      } else {
        if (interliningPriceElement) {
          interliningPriceElement.textContent = "";
        }
      }
    }

    // EMBELLISHMENT PRICE
    const activeEmbellishmet = this.props.data.embellishmentsList;
    // BANDINGS PRICE
    let calculatedBandingPrice: string = this.state.prices.bandingPrice;
    const bandingPriceElement = document.querySelector(".bandingPrice");

    if (Array.isArray(activeEmbellishmet)) {
      const activeBanding = activeEmbellishmet.find(
        (item) => item.title === "Banding" && item.checked === true
      );

      if (activeBanding) {
        if (bandingPriceElement) {
          bandingPriceElement.textContent =
            calculatedBandingPrice + " Banding";
        }
      }
    }

    // CORDING PRE MADE PRICE
    let calculatedCordingPrePrice: string =
      this.state.prices.cordingPrePrice;
    const cordingPriceElement = document.querySelector(".cordingPrice");

    if (Array.isArray(activeEmbellishmet)) {
      const activeCording = activeEmbellishmet.find(
        (item) => item.title === "Cording" && item.checked === true
      );
      if (activeCording) {
        const activeCordingPreMade = this.props.data.cordings.find(
          (cording: { subType: number }) => cording.subType === 0
        );
        if (activeCordingPreMade) {
          if (cordingPriceElement) {
            cordingPriceElement.textContent =
              calculatedCordingPrePrice + " Cording pre-made";
          }
        }
      }
    }

    // CORDING FROM FABRIC
    let calculatedCordingFabricPrice: string =
      this.state.prices.cordingFabricPrice;

    const cordingFabricPriceElement = document.querySelector(
      ".cordingFabricPrice"
    );
    if (Array.isArray(activeEmbellishmet)) {
      const activeCording = activeEmbellishmet.find(
        (item) => item.title === "Cording" && item.checked === true
      );
      if (activeCording) {
        const activeCordingFabric = this.props.data.cordings.find(
          (cording: { subType: number }) => cording.subType === 1
        );
        if (activeCordingFabric) {
          if (cordingFabricPriceElement) {
            cordingFabricPriceElement.textContent =
              calculatedCordingFabricPrice +
              " Cording from fabric";
          }
        }
      }
    }

    // FLANGE PRE MADE PRICE
    let calculatedFlangePrePrice: string = this.state.prices.flangePrePrice;

    const flangePriceElement = document.querySelector(".flangePrice");

    if (Array.isArray(activeEmbellishmet)) {
      const activeFlange = activeEmbellishmet.find(
        (item) => item.title === "Flange" && item.checked === true
      );
      if (activeFlange) {
        const activeFlangePreMade = this.props.data.flanges.find(
          (flange: { subType: number }) => flange.subType === 0
        );
        if (activeFlangePreMade) {
          if (flangePriceElement) {
            flangePriceElement.textContent =
              calculatedFlangePrePrice + " Flange pre-made";
          }
        }
      }
    }

    // FLANGE FABRIC PRICE
    let calculatedFlangeFabricPrice: string =
      this.state.prices.flangeFabricPrice;

    const flangeFabricPriceElement =
      document.querySelector(".flangeFabricPrice");

    if (Array.isArray(activeEmbellishmet)) {
      const activeFlange = activeEmbellishmet.find(
        (item) => item.title === "Flange" && item.checked === true
      );
      if (activeFlange) {
        const activeFlangeFabric = this.props.data.flanges.find(
          (flange: { subType: number }) => flange.subType === 1
        );
        if (activeFlangeFabric) {
          if (flangeFabricPriceElement) {
            flangeFabricPriceElement.textContent =
              calculatedFlangeFabricPrice + " Flange from fabric";
          }
        }
      }
    }

    // TRIMS PRE MADE PRICE
    let calculatedTrimPrePrice: string = this.state.prices.trimPrePrice;

    const trimPriceElement = document.querySelector(".trimPrice");

    if (Array.isArray(activeEmbellishmet)) {
      const activeTrim = activeEmbellishmet.find(
        (item) => item.title === "Trim" && item.checked === true
      );
      if (activeTrim) {
        const activeTrimPreMade = this.props.data.trims.find(
          (trim: { subType: number }) => trim.subType === 0
        );
        if (activeTrimPreMade) {
          if (trimPriceElement) {
            trimPriceElement.textContent =
              calculatedTrimPrePrice + " Trim pre-made";
          }
        }
      }
    }

    // TRIMS FABRIC PRICE
    let calculatedTrimFabricPrice: string =
      this.state.prices.trimFabricPrice;

    const trimFabricPriceElement =
      document.querySelector(".trimFabricPrice");

    if (Array.isArray(activeEmbellishmet)) {
      const activeTrim = activeEmbellishmet.find(
        (item) => item.title === "Trim" && item.checked === true
      );
      if (activeTrim) {
        const activeTrimFabric = this.props.data.trims.find(
          (trim: { subType: number }) => trim.subType === 1
        );
        if (activeTrimFabric) {
          if (trimFabricPriceElement) {
            trimFabricPriceElement.textContent =
              calculatedTrimFabricPrice + " Trim from fabric";
          }
        }
      }
    }

    //OTHER EMBELLISHMENTS
    let calculatedOtherPrice: string = this.state.prices.otherPrice;

    const formatedOtherPrice = calculatedOtherPrice;

    const othersEmbelishmentsPrice = document.querySelector(
      ".othersEmbelishmentsPrice"
    );

    if (Array.isArray(activeEmbellishmet)) {
      const activeOther = activeEmbellishmet.find(
        (item) =>
          item.stateName === "othersEmbellishments" &&
          item.checked === true
      );
      if (activeOther) {
        if (othersEmbelishmentsPrice) {
          othersEmbelishmentsPrice.textContent = `${formatedOtherPrice} Other embellishments`;
        }
      }
    }
    //DRAPERY PINING

    let draperyPininngTotal: string = this.state.prices.draperyPinningPrice;
    const draperyActive =
      this.props.data &&
      this.props.data.draperyOrderDesign &&
      this.props.data.draperyOrderDesign.isPinSet;

    const pinningPriceElement = document.querySelector(".pinningPrice");

    if (draperyActive) {
      if (pinningPriceElement) {
        pinningPriceElement.textContent =
          draperyPininngTotal + " Drapery pinning";
      }
    }
    //WEIGHT

    let weightTotal: string = this.state.prices.weightPrice;
    const weightActive =
      this.props.data &&
      this.props.data.draperyOrderDesign &&
      this.props.data.draperyOrderDesign.isWeight;

    const weightPriceElement = document.querySelector(".weightPrice");

    if (weightActive) {
      let weightDescription = "";
      if (
        this.props.data.draperyOrderDesign.weightRadio ===
        "standard-corner"
      ) {
        weightDescription = "Weight: Standard Corner";
      } else if (
        this.props.data.draperyOrderDesign.weightRadio ===
        "beaded-chain"
      ) {
        weightDescription = "Weight: Beaded Chain";
      }

      if (weightPriceElement) {
        weightPriceElement.textContent = `${weightTotal} ${weightDescription}`;
      }
    }
    // EXTRA LONG PANELS
    let extraLongDeduction: string = this.state.prices.extraLongPrice;

    const extraLongElement = document.querySelector(".extraLongPrice");
    if (parseFloat(extraLongDeduction) > 0) {
      if (extraLongElement) {
        extraLongElement.textContent =
          extraLongDeduction + " Extra Long Panels";
      }
    } else {
      if (extraLongElement) {
        extraLongElement.textContent = "";
      }
    }

    // EXTRA WIDE PANELS
    let extraWideTotal: string = this.state.prices.extraWidePrice;

    let extraWideElement = document.querySelector(".extraWidePrice");

    if (parseFloat(extraWideTotal) > 0) {
      if (extraWideElement) {
        extraWideElement.textContent =
          extraWideTotal + " Extra Wide Panels";
      }
    } else {
      if (extraWideElement) {
        extraWideElement.textContent = "";
      }
    }

    // CAFE CURTAIN DISCOUNT
    let cafeCurtainDeduction: string = this.state.prices.cafeCurtainPrice;

    const cafePriceElement = document.querySelector(".cafePrice");
    if (parseFloat(cafeCurtainDeduction) < 0) {
      if (cafePriceElement) {
        cafePriceElement.textContent =
          cafeCurtainDeduction + " Café Curtain Discount";
      }
    } else {
      if (cafePriceElement) {
        cafePriceElement.textContent = "";
      }
    }

    // SPECIALTY BASE PRICE

    let calculatedSpeciality: any =
      this.state.prices.calculatedSpecialityPrice;

    if (
      typeof calculatedSpeciality === "number" &&
      !isNaN(calculatedSpeciality)
    ) {
      calculatedSpeciality = calculatedSpeciality.toFixed(2);
    }

    const specialityBasePriceElement =
      document.querySelector(".specialityPrice");

    if (specialityBasePriceElement) {
      specialityBasePriceElement.innerHTML =
        parseFloat(calculatedSpeciality) > 0
          ? calculatedSpeciality + " Specialty Fabrics Surcharge"
          : "";
    }

    // SPECIALTY LINING PRICE
    let calculatedSpecialityLining: string =
      this.state.prices.calculatedSpecialityLiningPrice;

    const specialityLiningPriceElement = document.querySelector(
      ".specialityPriceLining"
    );

    if (specialityLiningPriceElement) {
      if (parseFloat(calculatedSpecialityLining) !== 0) {
        specialityLiningPriceElement.textContent =
          calculatedSpecialityLining + " Specialty Lining";
      } else {
        specialityLiningPriceElement.textContent = "";
      }
    }

    // SPECIALTY INTERLINING PRICE
    let calculatedSpecialityInterlining: string =
      this.state.prices.calculatedSpecialityInterliningPrice;

    const specialityInterliningPriceElement = document.querySelector(
      ".specialityPriceInterlining"
    );

    if (specialityInterliningPriceElement) {
      if (parseFloat(calculatedSpecialityInterlining) !== 0) {
        specialityInterliningPriceElement.textContent =
          calculatedSpecialityInterlining + " Specialty Interlining";
      } else {
        specialityInterliningPriceElement.textContent = "";
      }
    }

    // SPECIALTY BANDINGS PRICE
    let calculatedSpecialityBanding: string =
      this.state.prices.calculatedSpecialityBandingPrice;

    const specialityBandingPriceElement = document.querySelector(
      ".specialityPriceBanding"
    );

    if (specialityBandingPriceElement) {
      if (
        calculatedSpecialityBanding &&
        parseFloat(calculatedSpecialityBanding) !== 0
      ) {
        specialityBandingPriceElement.textContent =
          calculatedSpecialityBanding + " Specialty Banding";
      } else {
        specialityBandingPriceElement.textContent = "";
      }
    }
    // SPECIALTY CORDING PRE MADE
    let calculatedSpecialityCordingPreMade: string =
      this.state.prices.calculatedSpecialityCordingPreMadePrice;

    const specialityCordingPriceElement = document.querySelector(
      ".specialityPriceCordingPreMade"
    );

    if (specialityCordingPriceElement) {
      if (
        calculatedSpecialityCordingPreMade &&
        parseFloat(calculatedSpecialityCordingPreMade) !== 0
      ) {
        specialityCordingPriceElement.textContent =
          calculatedSpecialityCordingPreMade +
          " Specialty Cording pre-made";
      } else {
        specialityCordingPriceElement.textContent = "";
      }
    }
    // SPECIALTY CORDING FROM FABRIC
    let calculatedSpecialityCordingFabric: string =
      this.state.prices.calculatedSpecialityCordingFabricPrice;

    const specialityCordingFabricPriceElement = document.querySelector(
      ".specialityPriceCordingFabric"
    );

    if (specialityCordingFabricPriceElement) {
      if (
        calculatedSpecialityCordingFabric &&
        parseFloat(calculatedSpecialityCordingFabric) !== 0
      ) {
        specialityCordingFabricPriceElement.textContent =
          calculatedSpecialityCordingFabric +
          " Specialty Cording from fabric";
      } else {
        specialityCordingFabricPriceElement.textContent = "";
      }
    }

    // SPECIALTY FLANGE PRE MADE
    let calculatedSpecialityFlangePreMade: string =
      this.state.prices.calculatedSpecialityFlangePreMadePrice;

    const specialityFlangePriceElement = document.querySelector(
      ".specialityPriceFlangePreMade"
    );

    if (specialityFlangePriceElement) {
      if (
        calculatedSpecialityFlangePreMade &&
        parseFloat(calculatedSpecialityFlangePreMade) !== 0
      ) {
        specialityFlangePriceElement.textContent =
          calculatedSpecialityFlangePreMade +
          " Specialty Flange pre-made";
      } else {
        specialityFlangePriceElement.textContent = "";
      }
    }

    // SPECIALTY FLANGE FROM FABRIC
    let calculatedSpecialityFlangeFabric: string =
      this.state.prices.calculatedSpecialityFlangeFabricPrice;

    const specialityFlangeFabricPriceElement = document.querySelector(
      ".specialityPriceFlangeFabric"
    );

    if (specialityFlangeFabricPriceElement) {
      if (
        calculatedSpecialityFlangeFabric &&
        parseFloat(calculatedSpecialityFlangeFabric) !== 0
      ) {
        specialityFlangeFabricPriceElement.textContent =
          calculatedSpecialityFlangeFabric +
          " Specialty Flange from fabric";
      } else {
        specialityFlangeFabricPriceElement.textContent = "";
      }
    }

    // SPECIALTY TRIM PRE MADE
    let calculatedSpecialityTrimPreMade: string =
      this.state.prices.calculatedSpecialityTrimPreMadePrice;

    const specialityTrimPriceElement = document.querySelector(
      ".specialityPriceTrimPreMade"
    );

    if (specialityTrimPriceElement) {
      if (
        calculatedSpecialityTrimPreMade &&
        parseFloat(calculatedSpecialityTrimPreMade) !== 0
      ) {
        specialityTrimPriceElement.textContent =
          calculatedSpecialityTrimPreMade +
          " Specialty Trim pre-made";
      } else {
        specialityTrimPriceElement.textContent = "";
      }
    }

    // SPECIALTY TRIM FROM FABRIC
    let calculatedSpecialityTrimFabric: string =
      this.state.prices.calculatedSpecialityTrimFabricPrice;

    const specialityTrimFabricPriceElement = document.querySelector(
      ".specialityPriceTrimFabric"
    );

    if (specialityTrimFabricPriceElement) {
      if (
        calculatedSpecialityTrimFabric &&
        parseFloat(calculatedSpecialityTrimFabric) !== 0
      ) {
        specialityTrimFabricPriceElement.textContent =
          calculatedSpecialityTrimFabric +
          " Specialty Trim from fabric";
      } else {
        specialityTrimFabricPriceElement.textContent = "";
      }
    }

    // SPECIALTY OTHER EMBELLISHMENTS
    let calculatedSpecialityOther: string =
      this.state.prices.calculatedSpecialityOtherPrice;

    const specialityOtherPriceElement = document.querySelector(
      ".specialityPriceOther"
    );
    if (specialityOtherPriceElement) {
      if (
        calculatedSpecialityOther &&
        parseFloat(calculatedSpecialityOther) !== 0
      ) {
        specialityOtherPriceElement.textContent =
          calculatedSpecialityOther +
          " Specialty other embellishments";
      } else {
        specialityOtherPriceElement.textContent = "";
      }
    }

    const formatPleatType = (pleatType: any) => {
      let formattedPleatType = pleatType.replace(/\d{4}$/, "");

      formattedPleatType = formattedPleatType.replace(/_/g, " ");

      formattedPleatType = formattedPleatType.replace(
        /\b\w/g,
        (char: string) => char.toUpperCase()
      );
      return formattedPleatType;
    };

    const changeStatus = (e: ChangeEvent<HTMLSelectElement>): void => {
      this.props.updateStateByName(
        "draperyOrder.status",
        e.currentTarget.value
      );
    };

    const handleEnterPressed = (
      e: React.KeyboardEvent<HTMLTextAreaElement>
    ): void => {
      const key = e.key;
      if (key === "Enter" || key === "Return") {
        e.preventDefault();

        const textarea = e.currentTarget;
        const value = textarea.value;
        const selectionStart = textarea.selectionStart;
        const selectionEnd = textarea.selectionEnd;

        const part1 = value.slice(0, selectionStart);
        const part2 = value.slice(selectionEnd);

        const newValue = part1 + "\n" + part2;

        textarea.value = newValue;
        textarea.selectionStart = textarea.selectionEnd =
          selectionStart + 1;
      }
    };

    const isDraperyOrder: boolean = this.props.data.orderType !== "roman-shade-order"
    const isCreateMode: boolean = this.props.draperyOrder.id === 0

    const otherItemTotal: string | number = 
    (this.props.draperyOrder.quantity != null && draperyOrderSummary.totalPerUnit != null && 
    !isNaN(Number(this.props.draperyOrder.quantity)) && !isNaN(Number(draperyOrderSummary.totalPerUnit)) && 
    Number(this.props.draperyOrder.quantity) >= 0 && Number(draperyOrderSummary.totalPerUnit) >= 0 && 
    !isCreateMode && !workOrderEditMode && this.props.data.workOrder.id > 0) 
    ? (Number(this.props.draperyOrder.quantity) * Number(draperyOrderSummary.totalPerUnit)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "TBD";
  
    const romanShadeHeadrailTotal: string | number = 
    (this.props.draperyOrder.quantity != null && draperyOrderSummary.headrailPerUnit != null && 
    !isNaN(Number(this.props.draperyOrder.quantity)) && !isNaN(Number(draperyOrderSummary.headrailPerUnit)) && 
    Number(this.props.draperyOrder.quantity) >= 0 && Number(draperyOrderSummary.headrailPerUnit) >= 0 && 
    !isCreateMode && !workOrderEditMode && this.props.data.workOrder.id > 0) 
    ? (Number(this.props.draperyOrder.quantity) * Number(draperyOrderSummary.headrailPerUnit)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "TBD";  

      const { romanShadeJson } = this.props.data.draperyOrderDesign;
      const draperyOrderDesignFunction = {
        0: "Functional",
        1: "Faux",
      };
      const draperyOrderDesignStyleWindowMount={
          0: "Inside",
          1: "Outside",
      };
      const draperyOrderDesignStyleBoardMount={
          0: "Waterfall",
          1: "Reverse",
      }
      const draperyOrderDesignValanceAndReturnsSides = {
          "side-flaps": "Sides Flaps",
          "returns": "Returns",
      }

      const forBackValance = romanShadeJson && 
        romanShadeJson.valanceAndReturns && 
        romanShadeJson.valanceAndReturns.backValance &&
        romanShadeJson.valanceAndReturns.backValance > 0 &&
        this.props.data.liningsList.every((lin: any) => !lin.active) ? true : false;

    let shippingPriceMainFabric: string = "";
    let shippingPriceTrim: any[] = [];
    let shippingOthersEmbellishments: any[] = [];
    let shippingPriceLiningsFabric: string = "";
    let shippingPriceInterliningsFabric: string = "";

    if(this.props.data.fabricsPatternItems && this.props.data.mainFabric) {
        this.props.data.fabricsPatternItems
            .filter(
                (item: any) =>
                    item.name ===
                    this.props.data
                        .mainFabric.pattern
            )
            .map(
                (item: any) => {
                    if (
                        item.handlingFeeMethod ===
                        "markUpShippingCost"
                    ) {
                        const amount =
                            (
                                Number(
                                    item.shippingCost
                                ) +
                                (Number(
                                        item.shippingCost
                                    ) *
                                    Number(
                                        item.handlingFeeValue
                                    )) /
                                100
                            ).toFixed(2)
                        shippingPriceMainFabric = amount;
                    } else if (
                        item.handlingFeeMethod ===
                        "markUpProductCost"
                    ) {
                        const amount =
                            (
                                Number(
                                    item.shippingCost
                                ) +
                                (Number(
                                        this.state.mainFabricYardage || "0"
                                    ) *
                                    (Number(
                                            item.cost
                                        ) *
                                        Number(
                                            item.handlingFeeValue
                                        ))) /
                                100
                            ).toFixed(2)
                        shippingPriceMainFabric = amount;
                    } else {
                        const amount =
                            (
                                Number(
                                    item.shippingCost
                                ) +
                                Number(
                                    item.handlingFeeValue
                                )
                            ).toFixed(2);
                        shippingPriceMainFabric = amount;
                    }
                }
            );
    }

  this.props.data.trims && this.props.data.trims.filter((item: any) => item.subType === 0).map(
          (
              item: any,
              num: number
          ) => {
              const newItem =
                  item.draperyOrderFabric;
              this.props.data && this.props.data.fabricsPatternItems && newItem && newItem.pattern &&

               this.props.data.fabricsPatternItems
                  .filter(
                      (
                          item: any
                      ) =>
                          item.name ===
                          newItem.pattern
                  )
                  .map(
                      (
                          item: any,
                          index: number
                      ) => {
                          if (
                              item.handlingFeeMethod ===
                              "markUpShippingCost"
                          ) {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      (Number(
                                              item.shippingCost
                                          ) *
                                          Number(
                                              item.handlingFeeValue
                                          )) /
                                      100
                                  ).toFixed(2)
                              shippingPriceTrim.push({ price: amount, subType: 0 });

                          } else if (
                              item.handlingFeeMethod ===
                              "markUpProductCost"
                          ) {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      (Number(
                                              this.state.trimsPreMadeYardage[num] || "0"
                                          ) *
                                          (Number(
                                                  item.cost
                                              ) *
                                              Number(
                                                  item.handlingFeeValue
                                              ))) /
                                      100
                                  ).toFixed(2);
                              shippingPriceTrim.push({ price: amount, subType: 0 });


                          } else {

                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      Number(
                                          item.handlingFeeValue
                                      )
                                  ).toFixed(2);
                              shippingPriceTrim.push({ price: amount, subType: 0 });

                          }
                      }
                  )}
      );
      this.props.data.trims && this.props.data.trims.filter((item: any) => item.subType === 1).map(
          (
              item: any,
              num: number
          ) => {
              const newItem = item && item.draperyOrderFabric &&
                   item
                       .draperyOrderFabric
                       .pattern;
                this.props.data && this.props.data
                  .fabricsPatternItems && this.props.data.fabricsPatternItems
                  .filter(
                      (
                          item: any
                      ) =>
                          item.name ===
                          newItem
                  )
                  .map(
                      (
                          item: any,
                          index: number
                      ) => {
                          if (
                              item.handlingFeeMethod ===
                              "markUpShippingCost"
                          ) {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      (Number(
                                              item.shippingCost
                                          ) *
                                          Number(
                                              item.handlingFeeValue
                                          )) /
                                      100
                                  ).toFixed(
                                      2
                                  );

                              shippingPriceTrim.push({  price: amount, subType: 1});
                          } else if (
                              item.handlingFeeMethod ===
                              "markUpProductCost"
                          ) {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      (Number(this.state.trimsFabricYardage[num] || "0") *
                                          (Number(
                                                  item.cost
                                              ) *
                                              Number(
                                                  item.handlingFeeValue
                                              ))) /
                                      100
                                  ).toFixed(
                                      2
                                  );
                              shippingPriceTrim.push({  price: amount, subType: 1});
                          } else {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      Number(
                                          item.handlingFeeValue
                                      )
                                  ).toFixed(
                                      2
                                  );
                              shippingPriceTrim.push({  price: amount, subType: 1});
                          }
                      }
                  )}

      )
      this.props.data && this.props.data.othersEmbellishments &&
      this.props.data.othersEmbellishments &&
      this.props.data.othersEmbellishments.map(
          (
              item: any,
              num: number
          ) => {
              const newItem = item && item.draperyOrderFabric &&
                  item
                      .draperyOrderFabric
                      .pattern;
              this.props.data && this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems
                  .filter(
                      (
                          item: any
                      ) =>
                          item.name ===
                          newItem
                  )
                  .map(
                      (
                          item: any,
                          index: number
                      ) => {
                          if (
                              item.handlingFeeMethod ===
                              "markUpShippingCost"
                          ) {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      (Number(
                                              item.shippingCost
                                          ) *
                                          Number(
                                              item.handlingFeeValue
                                          )) /
                                      100
                                  ).toFixed(
                                      2
                                  );
                              const arrItem = this.props.data
                                  .othersEmbellishments;
                              arrItem[
                                  num
                                  ][
                                  "amount"
                                  ] =
                                  +amount;

                              shippingOthersEmbellishments.push({ price: amount });
                          } else if (
                              item.handlingFeeMethod ===
                              "markUpProductCost"
                          ) {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      (Number(this.state.otherEmbellishmentsYardage[num] || "0"
                                          ) *
                                          (Number(
                                                  item.cost
                                              ) *
                                              Number(
                                                  item.handlingFeeValue
                                              ))) /
                                      100
                                  ).toFixed(
                                      2
                                  );
                              const arrItem = this.props.data
                                  .othersEmbellishments;
                              arrItem[
                                  num
                                  ][
                                  "amount"
                                  ] =
                                  +amount;

                              shippingOthersEmbellishments.push({ price: amount });
                          } else {
                              const amount =
                                  (
                                      Number(
                                          item.shippingCost
                                      ) +
                                      Number(
                                          item.handlingFeeValue
                                      )
                                  ).toFixed(
                                      2
                                  );

                              shippingOthersEmbellishments.push({ price: amount });
                          }
                      }
                  )

          }
      )

      this.props.data.liningsList.map(
          (
              item: any,
              num: number
          ) => {
              const newItem =
                  item &&
                  item.draperyOrderLiningsFabric &&
                  item
                      .draperyOrderLiningsFabric
                      .pattern;
              const tempItem = item;

              if (newItem) {
                  this.props.data && this.props.data.liningsPatternItems && this.props.data.liningsPatternItems
                      .filter(
                          (
                              item: any
                          ) => {
                              return (
                                  item.name ===
                                  newItem
                              );
                          }
                      )
                      .map(
                          (
                              item: any,
                              index: number
                          ) => {
                              if (
                                  item.handlingFeeMethod ===
                                  "markUpShippingCost"
                              ) {
                                  const amount =
                                      (
                                          Number(
                                              item.shippingCost
                                          ) +
                                          (Number(
                                                  item.shippingCost
                                              ) *
                                              Number(
                                                  item.handlingFeeValue
                                              )) /
                                          100
                                      ).toFixed(
                                          2
                                      );
                                  if(tempItem.name === 'Lining'){
                                      shippingPriceLiningsFabric = amount;
                                  } else {
                                      shippingPriceInterliningsFabric = amount;
                                  }

                              } else if (
                                  item.handlingFeeMethod ===
                                  "markUpProductCost"
                              ) {
                                  const amount =
                                      (
                                          Number(
                                              item.shippingCost
                                          ) +
                                          (Number(
                                                  this.state.liningsYardage[num] || "0"
                                              ) *
                                              (Number(
                                                      item.cost
                                                  ) *
                                                  Number(
                                                      item.handlingFeeValue
                                                  ))) /
                                          100
                                      ).toFixed(
                                          2
                                      );
                                  if(tempItem.name === 'Lining'){
                                      shippingPriceLiningsFabric = amount;
                                  } else {
                                      shippingPriceInterliningsFabric = amount;
                                  }
                              } else {
                                  const amount =
                                      (
                                          Number(
                                              item.shippingCost
                                          ) +
                                          Number(
                                              item.handlingFeeValue
                                          )
                                      ).toFixed(
                                          2
                                      );
                                  if(tempItem.name === 'Lining'){
                                      shippingPriceLiningsFabric = amount;
                                  } else {
                                      shippingPriceInterliningsFabric = amount;
                                  }
                              }
                          }
                      )}

          }
      )
      return (
      <>
        <DisableAutocompleteInput name="firstName" />
        <SummaryContainer>
          <SummaryTimeline />
          <SummaryContent>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
              ></DraperyHeader>

              <HContainer uiBaseLine uiGap>
                <CalculatorFormField uiInline uiNoMargin>
                  <TitleNotes>Status</TitleNotes>
                  <Select
                    name="draperyOrder.status"
                    value={this.props.draperyOrder.status}
                    onChange={changeStatus}
                    uiDisabled={
                      this.props.draperyOrder.archived
                    }
                  >
                    <option value={"New"}>New</option>
                    <option value={"Planning"}>
                      Planning
                    </option>
                    <option value={"Measured"}>
                      Measured
                    </option>
                    <option value={"Need Measure"}>
                      Need Measure
                    </option>
                    <option value={"Pending"}>
                      Pending
                    </option>
                    <option value={"Approved"}>
                      Approved
                    </option>
                    <option value={"Production"}>
                      Production
                    </option>
                    <option value={"Ready"}>Ready</option>
                    <option value={"Closed"}>Closed</option>
                    <option value={"Rejected"}>
                      Rejected
                    </option>
                  </Select>
                </CalculatorFormField>

                <CalculatorFormField
                  uiInline
                  uiNoMargin
                  style={{ width: "50%" }}
                >
                  <TitleNotes>
                    Project Overview Notes
                  </TitleNotes>
                  <TextArea
                    style={{
                      border: "1px solid #B3B3B3",
                      height: "131px",
                    }}
                    name="draperyOrderSummary.notes"
                    onChange={
                      this.props
                        .handleInputChangeForString
                    }
                    onKeyDown={handleEnterPressed}
                    placeholder="Click to enter notes"
                    value={draperyOrderSummary.notes || ""}
                    uiDisabled={
                      this.props.draperyOrder.archived
                    }
                  />
                </CalculatorFormField>
              </HContainer>
            </SummaryInfoBlock>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
                uiTimeStatusChecked={!!workOrder.id}
                className="firstCheck"
              >
                ITEM SUMMARY
                <div
                  style={{ gap: "20px" }}
                  className="flex items-center"
                >
                  <div>
                    {this.props.data.workOrder.id > 0 && (
                      <DraftCheckBox>
                        <input
                          type="checkbox"
                          id="isDraft"
                          name="draperyOrder.isDraft"
                          checked={
                            this.props.data
                              .draperyOrder
                              .isDraft
                          }
                          onChange={
                            this.props
                              .handleInputChangeForBoolean
                          }
                          value={
                            this.props.data
                              .draperyOrder
                              .isDraft
                          }
                          disabled={
                            this.props.draperyOrder
                              .archived
                          }
                          style={
                            this.props.draperyOrder
                              .archived
                              ? {
                                border: "2px solid #EEEEEE",
                              }
                              : {}
                          }
                        />
                        <span>Mark as Draft</span>
                      </DraftCheckBox>
                    )}
                  </div>

                  <div className="flex items-center">
                    <DuplicateBtn
                      uiDisabled={
                        this.props.workOrderEditMode
                          ? true
                          : this.props.draperyOrder
                            .archived
                      }
                      uiColor="primary"
                      type="button"
                      onClick={this.props.handleDuplicate}
                    >
                      Duplicate
                    </DuplicateBtn>

                    {!!this.props.draperyOrderEditable &&
                      cancelBtn}
                    {this.props.data.workOrder.id > 0 && (
                      <EditButton
                        uiDisabled={loadingUpdate}
                        uiColor="primary"
                        type="button"
                        onClick={() =>
                          this.props.workOrderEditMode && (this.props.data.orderType !== 'roman-shade-order')
                            ? this.props.updateWorkOrder(
                              "delete_messag"
                            )
                            : this.props.workOrderEditMode && (this.props.data.orderType === 'roman-shade-order') ? this.props.handleCreateOtherItemOrder(false, yardage) : this.props.editNavigate(
                              0
                            )
                        }
                      >
                        {!this.props
                          .workOrderEditMode && (
                            <EditIcon className="inline-flex mr-2" />
                          )}
                        <span>
                          {this.props
                            .workOrderEditMode
                            ? "Update"
                            : "Edit"}
                        </span>
                      </EditButton>
                    )}
                  </div>
                </div>
              </DraperyHeader>

              {(this.props.data &&
                this.props.data.workOrderPdfContentSummery &&
                this.props.data.workOrder.id > 0) || !isDraperyOrder ? (
                <HContainer
                  text="fabrics"
                  style={{ marginBottom: "30px" }}
                  uiColumnDirection
                >
                  <SummaryTable>
                    <SummaryTableCol
                      text="not-fabrics"
                      color="header"
                    >
                      <SummaryTableCell text="title">
                        Work Order
                      </SummaryTableCell>
                      {(workOrderEditMode && isDraperyOrder) ? null : salesDescriptionSettingsMapped.some(
                        (item) =>
                          item.id ===
                          "saleslinktoworkorder" &&
                          item.isEnabled
                      ) ? (
                        !this.props.workOrderEditMode && (
                          <SummaryTableCell
                            style={{
                              textAlign: "left",
                            }}
                            onClick={() =>
                              this.props.handleDownloadWorkOrderPdf(
                                true
                              )
                            }
                          >
                            <WorkOrderLink
                              style={{
                                flexWrap: "wrap",
                              }}
                            >
                              {this.props.data.workOrderPdfContentSummery && workOrder.id ?
                                (<WorkOrderNumber
                                  className="woNumber"
                                  style={{
                                    textAlign:
                                      "left",
                                    fontWeight:
                                      "600",
                                    minHeight:
                                      "40px",
                                  }}
                                >
                                  {"WO - " +
                                    workOrder.num}
                                </WorkOrderNumber>) : null
                              }
                              {this.props.data
                                .draperyOrder
                                .isDraft && (
                                  <Draft>DRAFT</Draft>
                                )}
                            </WorkOrderLink>
                          </SummaryTableCell>
                        )
                      ) : (
                        workOrder.id ? (
                          <WOContainer>
                            <WorkOrderNumberNoLink>
                              WO {workOrder.num}
                            </WorkOrderNumberNoLink>
                            {this.props.data
                              .draperyOrder
                              .isDraft && (
                                <Draft>DRAFT</Draft>
                              )}
                          </WOContainer>
                        ) : null
                      )}
                      <SummaryTableCell style={{ display: 'flex', marginTop: 'auto', borderTop: '1px solid rgb(218, 218, 218)', height: '70px' }}>
                      </SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol
                      text="fabrics-center"
                      color="header"
                    >
                      <SummaryTableCell text="title">
                        Description <span>&nbsp;</span>
                        <span
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          (per unit)
                        </span>
                      </SummaryTableCell>
                      {(workOrderEditMode && isDraperyOrder) ? null : (
                        <>

                          {salesDescriptionSettingsMapped.some(
                            (item) =>
                              item.id ===
                              "saleswindowid" &&
                              item.isEnabled
                          ) ? (
                            <SummaryTableCell
                              text="content"
                              style={{
                                textAlign:
                                  "left",
                                textTransform:
                                  "uppercase",
                              }}
                            >
                              <span style={{ fontWeight: "600", paddingBottom: "20px" }}>
                                {isDraperyOrder ? (
                                  <>
                                    {this.props.data.workOrderPdfContent.room} / {this.props.data.draperyOrder.window}
                                  </>
                                ) : (
                                  <>
                                      {this.props.data.draperyOrder.roomName} {this.props.data.draperyOrder.window || (!this.props.data.draperyOrder.window && !this.props.data.draperyOrder.roomName) ? "/" : "" } {this.props.data.draperyOrder.window}
                                  </>
                                )}
                              </span>

                            </SummaryTableCell>
                          ) : (
                            <span></span>
                          )}

                          <SummaryTableCell
                            style={{
                              flex: 1,
                              borderRight:
                                "1px solid #dadada",
                              borderLeft:
                                "1px solid #dadada",
                            }}
                          >
                            {
                              isDraperyOrder ?
                                <>
                                  {salesDescriptionSettingsMapped.some(
                                    (item) =>
                                      item.id ===
                                      "salesfunctionalityandstyle" &&
                                      item.isEnabled
                                  ) && (
                                      <>
                                        {this.props.data
                                          .draperyOrderDesign
                                          .category ===
                                          0 && (
                                            <span className="boldText">
                                              Functional
                                            </span>
                                          )}
                                        {this.props.data
                                          .draperyOrderDesign
                                          .category ===
                                          1 && (
                                            <span className="boldText">
                                              Stationary
                                            </span>
                                          )}

                                        {(this.props
                                          .data
                                          .draperyOrderDesign
                                          .draperyType ===
                                          "full-length" ||
                                          this.props
                                            .data
                                            .draperyOrderDesign
                                            .draperyType ===
                                          "cafe-curtain") && (
                                            <>
                                              <span className="boldText">
                                                {this
                                                  .props
                                                  .data
                                                  .draperyOrderDesign
                                                  .headingType !==
                                                  "ripplefold" &&
                                                  (this
                                                    .props
                                                    .data
                                                    .draperyOrderDesign
                                                    .draperyType ===
                                                    "full-length"
                                                    ? "/ Full Length"
                                                    : "/ Cafe Curtain")}
                                              </span>
                                            </>
                                          )}
                                      </>
                                    )}
                                  {salesDescriptionSettingsMapped.some(
                                    (item) =>
                                      item.id ===
                                      "salesfinishedlength" &&
                                      item.isEnabled
                                  ) && (
                                      <>
                                        {salesDescriptionSettingsMapped.some(
                                          (item) =>
                                            item.id ===
                                            "salesfunctionalityandstyle" &&
                                            item.isEnabled
                                        ) && (
                                            <span className="boldText">
                                              {" "}
                                              /{" "}
                                            </span>
                                          )}
                                        <span className="nowrap">
                                          <span className="boldText">
                                            Finished
                                            Length:{" "}
                                          </span>
                                          <span className="nonBold">
                                            {
                                              this
                                                .props
                                                .data
                                                .draperyOrderDesign
                                                .finishedLengthOfPanels
                                            }
                                            {""}
                                            ''
                                          </span>
                                        </span>
                                      </>
                                    )}
                                  <br></br>
                                  {salesDescriptionSettingsMapped.some(
                                    (item) =>
                                      item.id ===
                                      "salesheadingandpleattype" &&
                                      item.isEnabled
                                  ) ? (
                                    <>
                                      <span className="boldText">
                                        {this.props
                                          .data
                                          .draperyOrderDesign
                                          .headingType ===
                                          "rodandrings"
                                          ? "Rod and Rings / "
                                          : this
                                            .props
                                            .data
                                            .draperyOrderDesign
                                            .headingType ===
                                            "ripplefold"
                                            ? "Ripplefold / "
                                            : ""}
                                      </span>
                                      <span
                                        className="boldText"
                                        style={{
                                          textTransform:
                                            "capitalize",
                                        }}
                                      >
                                        {(() => {
                                          if (
                                            this
                                              .props
                                              .data
                                              .draperyOrderDesign
                                              .headingType ===
                                            "ripplefold"
                                          ) {
                                            const {
                                              masterCarrier,
                                            } =
                                              (this
                                                .props
                                                .data &&
                                                this
                                                  .props
                                                  .data
                                                  .draperyOrderDesign &&
                                                this
                                                  .props
                                                  .data
                                                  .draperyOrderDesign
                                                  .ripplefoldOptions) ||
                                              "";
                                            if (
                                              masterCarrier ===
                                              "butt"
                                            ) {
                                              return "Butt";
                                            } else if (
                                              masterCarrier ===
                                              "overlap"
                                            ) {
                                              return "Overlap";
                                            }
                                            return "";
                                          }

                                          if (
                                            this
                                              .props
                                              .draperyItemDetail &&
                                            this
                                              .props
                                              .draperyItemDetail[0]
                                              .pleatTypesWithPricing
                                          ) {
                                            const pleat =
                                              this.props.draperyItemDetail[0].pleatTypesWithPricing.find(
                                                (
                                                  pleat: any
                                                ) =>
                                                  pleat.id ===
                                                  this
                                                    .props
                                                    .data
                                                    .workOrderPdfContentSummery
                                                    .pleatType
                                              );
                                            if (
                                              pleat
                                            ) {
                                              return pleat.name.toLowerCase();
                                            }
                                            return formatPleatType(
                                              this.props.data.draperyOrderDesign.pleatType.toLowerCase()
                                            );
                                          }
                                          return "";
                                        })()}
                                      </span>
                                    </>
                                  ) : (
                                    <span></span>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems:
                                        "center",
                                    }}
                                  >
                                    {salesDescriptionSettingsMapped.some(
                                      (item) =>
                                        item.id ===
                                        "salespanelandwidthdetails" &&
                                        item.isEnabled
                                    ) ? (
                                      <>
                                        <span className="boldText">
                                          Panels:
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft:
                                              "8px",
                                            fontWeight:
                                              "400",
                                          }}
                                        >
                                          {Object.keys(
                                            this
                                              .props
                                              .data
                                              .draperyOrderDesign
                                              .panelJson
                                          )
                                            .map(
                                              (
                                                key
                                              ) =>
                                                this
                                                  .props
                                                  .data
                                                  .draperyOrderDesign
                                                  .panelJson[
                                                key
                                                ]
                                            )
                                            .filter(
                                              (
                                                value
                                              ) =>
                                                value !==
                                                undefined
                                            )
                                            .map(
                                              (
                                                value
                                              ) =>
                                                value +
                                                "w"
                                            )
                                            .join(
                                              " - "
                                            )}
                                        </span>
                                      </>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems:
                                        "center",
                                    }}
                                  >
                                    {salesDescriptionSettingsMapped.some(
                                      (item) =>
                                        item.id ===
                                        "salestotalnumberofpanelsandwidths" &&
                                        item.isEnabled
                                    ) ? (
                                      <>
                                        <span className="boldText">
                                          Total
                                          Panels:
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft:
                                              "8px",
                                            fontWeight:
                                              "400",
                                          }}
                                        >
                                          {
                                            Object.keys(
                                              this
                                                .props
                                                .data
                                                .draperyOrderDesign
                                                .panelJson
                                            ).filter(
                                              (
                                                key
                                              ) =>
                                                typeof this
                                                  .props
                                                  .data
                                                  .draperyOrderDesign
                                                  .panelJson[
                                                key
                                                ] !==
                                                "undefined"
                                            )
                                              .length
                                          }{" "}
                                          /
                                        </span>
                                        <span
                                          className="boldText"
                                          style={{
                                            paddingLeft:
                                              "2px",
                                          }}
                                        >
                                          Total
                                          Widths:
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft:
                                              "8px",
                                            fontWeight:
                                              "400",
                                          }}
                                        >
                                          {Object.values(
                                            this
                                              .props
                                              .data
                                              .draperyOrderDesign
                                              .panelJson
                                          )
                                            .map(
                                              (
                                                value
                                              ) => {
                                                const numericValue =
                                                  parseFloat(
                                                    value as string
                                                  );
                                                return isNaN(
                                                  numericValue
                                                )
                                                  ? 0
                                                  : numericValue;
                                              }
                                            )
                                            .reduce(
                                              (
                                                acc,
                                                currentValue
                                              ) =>
                                                acc +
                                                currentValue,
                                              0
                                            )
                                            .toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                        </span>
                                      </>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                  {salesDescriptionSettingsMapped.some(
                                    (item) =>
                                      item.id ===
                                      "salesifliningandinterliningselected" &&
                                      item.isEnabled
                                  ) ? (
                                    <>
                                      <span className="boldText">
                                        Lining:
                                      </span>
                                      <span
                                        style={{
                                          paddingLeft:
                                            "8px",
                                          fontWeight:
                                            "400",
                                        }}
                                      >
                                        {this.props.data.liningsList.some(
                                          (
                                            lining: any
                                          ) =>
                                            lining.name ===
                                            "Lining" &&
                                            (lining.active || (romanShadeJson.valanceAndReturns && romanShadeJson.valanceAndReturns.backValance && romanShadeJson.valanceAndReturns.backValance > 0))
                                        )
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                      <br></br>
                                      <span className="boldText">
                                        Interlining:
                                      </span>
                                      <span
                                        style={{
                                          paddingLeft:
                                            "8px",
                                          fontWeight:
                                            "400",
                                        }}
                                      >
                                        {this.props.data.liningsList.some(
                                          (
                                            lining: any
                                          ) =>
                                            lining.name ===
                                            "Interlining" &&
                                            lining.active
                                        )
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                      <br></br>
                                      {this.props.data.liningsList.some(
                                        (
                                          lining: any
                                        ) =>
                                          lining.name ===
                                          "Other interlining" &&
                                          lining.active
                                      ) && (
                                          <div>
                                            <span
                                              className="boldText"
                                              style={{
                                                paddingLeft:
                                                  "2px",
                                              }}
                                            >
                                              Additional
                                              Interlining:
                                            </span>
                                            <span
                                              style={{
                                                paddingLeft:
                                                  "8px",
                                                fontWeight:
                                                  "400",
                                              }}
                                            >
                                              Yes
                                            </span>
                                          </div>
                                        )}
                                    </>
                                  ) : (
                                    <span></span>
                                  )}
                                </>
                                :
                                <div>
                                  <div>
                                    <span className="nonBold">
                                        { romanShadeJson && romanShadeJson.style.function !== -1 ? draperyOrderDesignFunction[romanShadeJson.style.function] : "" }
                                        { romanShadeJson && romanShadeJson.style.type !== "" ? (romanShadeJson.style.function !== -1 ? " / " : "") + romanShadeJson.style.type.charAt(0).toUpperCase() + romanShadeJson.style.type.substring(1) : ""}

                                        {romanShadeJson && romanShadeJson.dimensions ? 
                                        !romanShadeJson.dimensions.finishedWidth && !romanShadeJson.dimensions.finishedLength && !romanShadeJson.dimensions.foldSize && !romanShadeJson.dimensions.numOfPermanentFolds ? 
                                        "" : 
                                        (romanShadeJson.style.function !== -1 || romanShadeJson.style.type !== ""  ? " / " : "") 
                                        +`${Math.ceil(
                                          ((+romanShadeJson.dimensions.finishedWidth && +romanShadeJson.dimensions.finishedLength ? +romanShadeJson.dimensions.finishedWidth : 1) * 
                                            ((+romanShadeJson.dimensions.finishedWidth && +romanShadeJson.dimensions.finishedLength ? +romanShadeJson.dimensions.finishedLength : 0) + 
                                              (+romanShadeJson.dimensions.foldSize !== null && +romanShadeJson.dimensions.numOfPermanentFolds !== null ?
                                              +romanShadeJson.dimensions.foldSize * 2 * +romanShadeJson.dimensions.numOfPermanentFolds 
                                              : 0)
                                            )
                                          )
                                          /144)} Total Sq. Ft.` : "" }

                                    </span>

                                    <span className="nonBold">
                                      {this.props.data.draperyOrderDesign.description || ''}
                                    </span>
                                  </div>
                                    <div>
                                          <span className="nonBold">
                                          {romanShadeJson && romanShadeJson.dimensions.finishedWidth !== null && romanShadeJson.dimensions.finishedLength !== null ? romanShadeJson.dimensions.finishedWidth + "''W x " + romanShadeJson.dimensions.finishedLength + "''L" : ""}
                                          {romanShadeJson && romanShadeJson.dimensions.numOfPermanentFolds !== null && +romanShadeJson.dimensions.numOfPermanentFolds !== 0 && romanShadeJson.dimensions.foldSize !== null && +romanShadeJson.dimensions.foldSize !== 0 ? " + " + +romanShadeJson.dimensions.numOfPermanentFolds * +romanShadeJson.dimensions.foldSize * 2 + "''L for Permanent Folds" : "" }
                                      </span>
                                    </div>
                                    <div>
                                         <span className="nonBold">
                                             {romanShadeJson && romanShadeJson.style.windowMount !== -1 ?  draperyOrderDesignStyleWindowMount[romanShadeJson.style.windowMount] + " Mount "  : ""}
                                             {romanShadeJson && romanShadeJson.style.boardMount !== - 1 ?  (romanShadeJson.style.windowMount !== -1 ?  "/ " : "") + draperyOrderDesignStyleBoardMount[romanShadeJson.style.boardMount] : "" }
                                             {romanShadeJson && romanShadeJson.valanceAndReturns.backValance === 1 ? (romanShadeJson.style.boardMount !== - 1 || romanShadeJson.style.windowMount !== - 1 ? " / " : "") + " Back Valance" : "" }
                                         </span>
                                    </div>
                                    <div>
                                        <span className="nonBold">
                                            {romanShadeJson && romanShadeJson.valanceAndReturns.frontValance === 1 ? "Front Valance " : ""}
                                            {romanShadeJson && romanShadeJson.valanceAndReturns.sides !== null && romanShadeJson.valanceAndReturns.sides !== "none" ? (romanShadeJson.valanceAndReturns.frontValance === 1 ?  "/ " : "") + draperyOrderDesignValanceAndReturnsSides[romanShadeJson.valanceAndReturns.sides] : "" }
                                            {romanShadeJson && romanShadeJson.dimensions.bottomSkirit === 1 ?  (romanShadeJson.valanceAndReturns.sides !== null && romanShadeJson.valanceAndReturns.sides !== "none" || romanShadeJson.valanceAndReturns.frontValance === 1 ?  " / " : "") + " Bottom Skirt" : ""}
                                        </span>
                                    </div>
                                        <>
                                      <span className="nonBold">
                                        Lining:
                                      </span>
                                      <span
                                        style={{
                                          paddingLeft:
                                            "8px",
                                          fontWeight:
                                            "400",
                                        }}
                                      >
                                        {this.props.data.liningsList.some(
                                          (
                                            lining: any
                                          ) =>
                                            lining.name ===
                                            "Lining" &&
                                            lining.active && !lining.draperyOrderLiningsFabric.pattern
                                        )
                                          ? "Yes" : this.props.data.liningsList.some(
                                            (
                                              lining: any
                                            ) =>
                                              lining.name ===
                                              "Lining" &&
                                              lining.active && lining.draperyOrderLiningsFabric.pattern
                                          ) ? this.props.data.liningsList.find((item: any) => item.name === "Lining").draperyOrderLiningsFabric.pattern 
                                          : "No"}
                                      </span>
                                      <br></br>
                                      <span className="nonBold">
                                        Interlining:
                                      </span>
                                      <span
                                        style={{
                                          paddingLeft:
                                            "8px",
                                          fontWeight:
                                            "400",
                                        }}
                                      >
                                        {this.props.data.liningsList.some(
                                          (
                                            lining: any
                                          ) =>
                                            lining.name ===
                                            "Interlining" &&
                                            lining.active && !lining.draperyOrderLiningsFabric.pattern
                                        ) 
                                          ? "Yes" : this.props.data.liningsList.some(
                                            (
                                              lining: any
                                            ) =>
                                              lining.name ===
                                              "Interlining" &&
                                              lining.active && lining.draperyOrderLiningsFabric.pattern
                                          ) ? this.props.data.liningsList.find((item: any) => item.name === "Interlining").draperyOrderLiningsFabric.pattern  
                                          : "No"}
                                      </span>
                                      <br></br>
                                      {this.props.data.liningsList.some(
                                        (
                                          lining: any
                                        ) =>
                                          lining.name ===
                                          "Other interlining" &&
                                          lining.active
                                      ) && (
                                          <div>
                                            <span
                                              className="boldText"
                                              style={{
                                                paddingLeft:
                                                  "2px",
                                              }}
                                            >
                                              Additional
                                              Interlining:
                                            </span>
                                            <span
                                              style={{
                                                paddingLeft:
                                                  "8px",
                                                fontWeight:
                                                  "400",
                                              }}
                                            >
                                              Yes
                                            </span>
                                          </div>
                                        )}
                                    </>

                                </div>
                            }
                            {salesDescriptionSettingsMapped.some(
                              (item) =>
                                item.id ===
                                "salesembellishmentsselected" &&
                                item.isEnabled
                            ) ? (
                                <div>
                                <span className="nonBold">
                                  Embellishments:
                                </span>
                                    <span>
                                  {this.props.data.embellishmentsList.some(
                                      (
                                          embellishment: any
                                      ) =>
                                          embellishment.checked &&
                                          embellishment.title ===
                                          "Banding"
                                  ) && (
                                      <span>
                                        {this.props.data.bandings.map(
                                            (
                                                banding: any,
                                                index: number
                                            ) => (
                                                <span
                                                    key={
                                                        index
                                                    }
                                                    className="nonBold"
                                                >
                                              {" "}
                                                    Banding
                                                    {banding
                                                        .topEdge
                                                        .active
                                                        ? " on Top Edge" +
                                                        (banding
                                                            .bottomEdge
                                                            .active >
                                                        0
                                                            ? ","
                                                            : "")
                                                        : ""}
                                                    {banding
                                                        .bottomEdge
                                                        .active
                                                        ? banding
                                                            .topEdge
                                                            .active
                                                            ? " Bottom Edge"
                                                            : " on Bottom Edge"
                                                        : ""}
                                                    {banding
                                                        .leadReturnEdge
                                                        .active &&
                                                    banding.totalStrips >
                                                    0 &&
                                                    banding
                                                        .topEdge
                                                        .active &&
                                                    banding
                                                        .bottomEdge
                                                        .active
                                                        ? ","
                                                        : ""}
                                                    {banding
                                                        .leadReturnEdge
                                                        .active &&
                                                    banding.totalStrips >
                                                    0 &&
                                                    !banding
                                                        .topEdge
                                                        .active &&
                                                    !banding
                                                        .bottomEdge
                                                        .active
                                                        ? " on"
                                                        : banding
                                                            .leadReturnEdge
                                                            .active &&
                                                        banding.totalStrips >
                                                        0 &&
                                                        (banding
                                                                .topEdge
                                                                .active ||
                                                            banding
                                                                .bottomEdge
                                                                .active)
                                                            ? " and"
                                                            : ""}
                                                    {banding
                                                        .leadReturnEdge
                                                        .active &&
                                                    (banding.totalStrips >
                                                        0 || (this.props.data.orderType === 'roman-shade-order'))
                                                        ? ` ${banding.totalStrips ? banding.totalStrips : ' and '
                                                        } Vertical Edge${banding.totalStrips >
                                                        1
                                                            ? "s"
                                                            : ""
                                                        }`
                                                        : ""}

                                                    {/*.*/}
                                            </span>
                                            )
                                        )}
                                      </span>
                                  )}
                                        {this.props.data.embellishmentsList.some(
                                            (
                                                embellishment: any
                                            ) =>
                                                embellishment.checked &&
                                                embellishment.title ===
                                                "Cording"
                                        ) && (
                                            <span>
                                        {this.props.data.cordings.map(
                                            (
                                                cording: any,
                                                index: number
                                            ) => (
                                                <span
                                                    key={
                                                        index
                                                    }
                                                    className="nonBold"
                                                >
                                              {cording &&
                                                  cording.draperyOrderFabric &&
                                                  (cording.draperyOrderFabric.name.includes(
                                                      "WELT CORDING FABRIC"
                                                  )
                                                      ? " Welt Cording"
                                                      : cording.draperyOrderFabric.name.includes(
                                                          "CORDING (PRE-MADE)"
                                                      )
                                                          ? " Cording (Pre-Made)"
                                                          : " Cording")}
                                                    {cording
                                                        .topEdge
                                                        .active
                                                        ? " on Top Edge" +
                                                        (cording
                                                            .bottomEdge
                                                            .active >
                                                        0
                                                            ? ","
                                                            : "")
                                                        : ""}
                                                    {cording
                                                        .bottomEdge
                                                        .active
                                                        ? cording
                                                            .topEdge
                                                            .active
                                                            ? " Bottom Edge"
                                                            : " on Bottom Edge"
                                                        : ""}
                                                    {cording
                                                        .leadReturnEdge
                                                        .active &&
                                                    cording.totalStrips >
                                                    0 &&
                                                    cording
                                                        .topEdge
                                                        .active &&
                                                    cording
                                                        .bottomEdge
                                                        .active
                                                        ? ","
                                                        : ""}
                                                    {cording
                                                        .leadReturnEdge
                                                        .active &&
                                                    cording.totalStrips >
                                                    0 &&
                                                    !cording
                                                        .topEdge
                                                        .active &&
                                                    !cording
                                                        .bottomEdge
                                                        .active
                                                        ? " on"
                                                        : cording
                                                            .leadReturnEdge
                                                            .active &&
                                                        cording.totalStrips >
                                                        0 &&
                                                        (cording
                                                                .topEdge
                                                                .active ||
                                                            cording
                                                                .bottomEdge
                                                                .active)
                                                            ? " and"
                                                            : ""}
                                                    {cording
                                                        .leadReturnEdge
                                                        .active &&
                                                    (cording.totalStrips >
                                                        0 || (this.props.data.orderType === 'roman-shade-order'))
                                                        ? ` ${cording.totalStrips ? cording.totalStrips : ' and '
                                                        } Vertical Edge${cording.totalStrips >
                                                        1
                                                            ? "s"
                                                            : ""
                                                        }`
                                                        : ""}

                                                    {/*.*/}
                                            </span>
                                            )
                                        )}
                                      </span>
                                        )}
                                        {this.props.data.embellishmentsList.some(
                                            (
                                                embellishment: any
                                            ) =>
                                                embellishment.checked &&
                                                embellishment.title ===
                                                "Flange"
                                        ) && (
                                            <span>
                                        {this.props.data.flanges.map(
                                            (
                                                flange: any,
                                                index: number
                                            ) => (
                                                <span
                                                    key={
                                                        index
                                                    }
                                                    className="nonBold"
                                                >
                                              {flange &&
                                                  flange.draperyOrderFabric &&
                                                  (flange.draperyOrderFabric.name.includes(
                                                      "FLANGE FABRIC"
                                                  )
                                                      ? " Flange from Fabric"
                                                      : flange.draperyOrderFabric.name.includes(
                                                          "FLANGE (PRE-MADE)"
                                                      )
                                                          ? " Flange (Pre-Made)"
                                                          : " Flange")}
                                                    {flange
                                                        .topEdge
                                                        .active
                                                        ? " on Top Edge" +
                                                        (flange
                                                            .bottomEdge
                                                            .active >
                                                        0
                                                            ? ","
                                                            : "")
                                                        : ""}
                                                    {flange
                                                        .bottomEdge
                                                        .active
                                                        ? flange
                                                            .topEdge
                                                            .active
                                                            ? " Bottom Edge"
                                                            : " on Bottom Edge"
                                                        : ""}
                                                    {flange
                                                        .leadReturnEdge
                                                        .active &&
                                                    flange.totalStrips >
                                                    0 &&
                                                    flange
                                                        .topEdge
                                                        .active &&
                                                    flange
                                                        .bottomEdge
                                                        .active
                                                        ? ","
                                                        : ""}
                                                    {flange
                                                        .leadReturnEdge
                                                        .active &&
                                                    flange.totalStrips >
                                                    0 &&
                                                    !flange
                                                        .topEdge
                                                        .active &&
                                                    !flange
                                                        .bottomEdge
                                                        .active
                                                        ? " on"
                                                        : flange
                                                            .leadReturnEdge
                                                            .active &&
                                                        flange.totalStrips >
                                                        0 &&
                                                        (flange
                                                                .topEdge
                                                                .active ||
                                                            flange
                                                                .bottomEdge
                                                                .active)
                                                            ? " and"
                                                            : ""}
                                                    {flange
                                                        .leadReturnEdge
                                                        .active &&
                                                    (flange.totalStrips >
                                                        0 || (this.props.data.orderType === 'roman-shade-order'))
                                                        ? ` ${flange.totalStrips ? flange.totalStrips : ' and '
                                                        } Vertical Edge${flange.totalStrips >
                                                        1
                                                            ? "s"
                                                            : ""
                                                        }`
                                                        : ""}

                                                    {/*.*/}
                                            </span>
                                            )
                                        )}
                                      </span>
                                        )}
                                        {this.props.data.embellishmentsList.some(
                                            (
                                                embellishment: any
                                            ) =>
                                                embellishment.checked &&
                                                embellishment.title ===
                                                "Trim"
                                        ) && (
                                            <span>
                                        {this.props.data.trims.map(
                                            (
                                                trim: any,
                                                index: number
                                            ) => (
                                                <span
                                                    key={
                                                        index
                                                    }
                                                >
                                              {trim &&
                                                  trim.draperyOrderFabric &&
                                                  (trim.draperyOrderFabric.name.includes(
                                                      "TRIM FABRIC"
                                                  )
                                                      ? " Trim from Fabric" + (!trim.topEdge.shade && !trim.bottomEdge.shade && !trim.leadReturnEdge.shade ? ". " : " ")
                                                      : trim.draperyOrderFabric.name.includes(
                                                          "TRIM (PRE-MADE)"
                                                      )
                                                          ? " Trim (Pre-Made)" + (!trim.topEdge.shade && !trim.bottomEdge.shade && !trim.leadReturnEdge.shade ? ". " : " ")
                                                          : " Trim" + (!trim.topEdge.shade && !trim.bottomEdge.shade && !trim.leadReturnEdge.shade ? ". " : " "))}
                                                    {trim
                                                        .topEdge
                                                        .active
                                                        ? " on Top Edge" +
                                                        (trim
                                                            .bottomEdge
                                                            .active >
                                                        0
                                                            ? ","
                                                            : "")
                                                        : ""}
                                                    {trim
                                                        .bottomEdge
                                                        .active
                                                        ? trim
                                                            .topEdge
                                                            .active
                                                            ? " Bottom Edge"
                                                            : " on Bottom Edge"
                                                        : ""}
                                                    {trim
                                                        .leadReturnEdge
                                                        .active &&
                                                    trim.totalStrips >
                                                    0 &&
                                                    trim
                                                        .topEdge
                                                        .active &&
                                                    trim
                                                        .bottomEdge
                                                        .active
                                                        ? ","
                                                        : ""}
                                                    {trim
                                                        .leadReturnEdge
                                                        .active &&
                                                    trim.totalStrips >
                                                    0 &&
                                                    !trim
                                                        .topEdge
                                                        .active &&
                                                    !trim
                                                        .bottomEdge
                                                        .active
                                                        ? " on"
                                                        : trim
                                                            .leadReturnEdge
                                                            .active &&
                                                        trim.totalStrips >
                                                        0 &&
                                                        (trim
                                                                .topEdge
                                                                .active ||
                                                            trim
                                                                .bottomEdge
                                                                .active)
                                                            ? " and"
                                                            : ""}
                                                    {trim
                                                        .leadReturnEdge
                                                        .active &&
                                                    (trim.totalStrips >
                                                        0 || (this.props.data.orderType === 'roman-shade-order'))
                                                        ? ` ${trim.totalStrips ? trim.totalStrips : ' and '
                                                        } Vertical Edge${trim.totalStrips >
                                                        1
                                                            ? "s"
                                                            : ""
                                                        }`
                                                        : ""}
                                                  {trim.topEdge.shade && trim.bottomEdge.shade &&  trim.leadReturnEdge.shade ? `on top edge, sides, and bottom edge of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` :
                                                            trim.topEdge.shade && trim.bottomEdge.shade && !trim.leadReturnEdge.shade ? `on top edge and bottom edge of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` :
                                                            !trim.topEdge.shade && trim.bottomEdge.shade && trim.leadReturnEdge.shade  ? `on sides and bottom edge of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` :
                                                            trim.topEdge.shade && !trim.bottomEdge.shade && trim.leadReturnEdge.shade ? `on top edge and sides of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` :

                                                            trim.topEdge.shade && !trim.bottomEdge.shade && !trim.leadReturnEdge.shade ? `on top edge of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` :
                                                            !trim.topEdge.shade && trim.bottomEdge.shade && !trim.leadReturnEdge.shade ? `on bottom edge of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` :
                                                            !trim.topEdge.shade && !trim.bottomEdge.shade && trim.leadReturnEdge.shade ? `on sides of Shade${trim.topEdge.valance || trim.bottomEdge.valance || trim.leadReturnEdge.valance ? " and" : "."} ` : ""

                                                    }

                                                       {trim.topEdge.valance && trim.bottomEdge.valance &&  trim.leadReturnEdge.valance ? "on top edge, sides, and bottom edge of Valance." :
                                                           trim.topEdge.valance && trim.bottomEdge.valance && !trim.leadReturnEdge.valance ? "on top edge and bottom edge of Valance." :
                                                               !trim.topEdge.valance && trim.bottomEdge.valance && trim.leadReturnEdge.valance  ? "on sides and bottom edge of Valance." :
                                                                   trim.topEdge.valance && !trim.bottomEdge.valance && trim.leadReturnEdge.valance ? "on top edge and sides of Valance." :

                                                                       trim.topEdge.valance && !trim.bottomEdge.valance && !trim.leadReturnEdge.valance ? "on top edge of Valance." :
                                                                           !trim.topEdge.valance && trim.bottomEdge.valance && !trim.leadReturnEdge.valance ? "on bottom edge of Valance." :
                                                                               !trim.topEdge.valance && !trim.bottomEdge.valance && trim.leadReturnEdge.valance ? "on sides of Valance." : ""

                                                       }


                                            </span>
                                            )
                                        )}
                                      
                                      </span>
                                        )}
                                        {this.props.data.embellishmentsList.some(
                                            (
                                                embellishment: any
                                            ) =>
                                                embellishment.checked &&
                                                embellishment.stateName ===
                                                "othersEmbellishments"
                                        ) && (
                                            <span>
                                        {" "}
                                                {this.props.data.othersEmbellishments.filter((other: any) => other.name)
                                                    .map(
                                                        (
                                                            other: any
                                                        ) =>
                                                            other.name + ""
                                                    )
                                                    .join(
                                                        ", "
                                                    ) + (this.props.data.othersEmbellishments.filter((other: any) => other.name).length > 0 ? "." : "")}
                                                {/*{"."}*/}
                                      </span>
                                        )}
                                        {!this.props.data.embellishmentsList.some(
                                            (
                                                embellishment: any
                                            ) =>
                                                embellishment.checked
                                        ) && (
                                            <span>
                                        {" "}
                                                None
                                      </span>
                                        )}
                                </span>
       
                                </div>

                            ) : (
                                <span></span>
                            )}
                          </SummaryTableCell>
                          <SummaryTableCell style={{ display: 'flex', marginTop: 'auto', border: '1px solid rgb(218, 218, 218)', borderBottom: 0 , height: '70px'  }}>
                          <div>
                          <span className="boldText">
                              HEADRAIL: {" "}
                          </span>
                              <span>
                              {romanShadeJson && romanShadeJson.headrail && romanShadeJson.headrail.liftSystem || ""}
                          </span>
                          </div>
                          </SummaryTableCell>
                        </>
                      )}
                    </SummaryTableCol>
                      <SummaryTableCol
                          text="fabrics-center"
                          color="header"
                      style={{
                        minWidth: "5%",
                        maxWidth: "10%",
                      }}
                    >
                      <SummaryTableCell text="title">
                        Quantity
                      </SummaryTableCell>

                      {(workOrderEditMode && isDraperyOrder) ? null : (
                        <SummaryTableCell
                          style={{
                            textAlign: "left",
                            textTransform:
                              "uppercase",
                            whiteSpace: "pre-wrap",
                            borderRight: "none"
                          }}
                        >
                          <span className="boldText">
                            {this.props.draperyOrder.quantity}
                          </span>
                        </SummaryTableCell>
                      )}
                      {(workOrderEditMode && isDraperyOrder) ? null : (
                        <SummaryTableCell
                        style={{
                          textAlign: "left",
                          textTransform:
                          "uppercase",
                          whiteSpace: "pre-wrap",
                          marginTop: 'auto',
                          borderTop: '1px solid rgb(218, 218, 218)', 
                           height: '70px' 
                          }}
                        >
                          <span className="boldText">
                            {this.props.draperyOrder.quantity}
                            
                          </span>
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>
                    <SummaryTableCol
                      text="fabrics"
                      color="header"
                      style={{
                        minWidth: "20%",
                        maxWidth: "25%",
                      }}
                    >
                      <SummaryTableCell
                        text="title"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Fabrication price{" "}
                        <span>&nbsp;</span>
                        <span
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          (per unit)
                        </span>
                      </SummaryTableCell>

                      {(workOrderEditMode || !isDraperyOrder) ? null : (
                        <SummaryTableCell text="total-per-unit">
                          <span
                            style={{
                              fontWeight: "600",
                              paddingBottom:
                                "20px",
                            }}
                          >
                            $ {totalPerUnit} TOTAL
                            PER UNIT
                          </span>
                          <br></br>
                          <br></br>
                          <div className="nonBold">
                            <span className="basePrice"></span>
                          </div>
                          <div
                            className="price-item nonBold"
                            style={{
                              display: "flex",
                              flexDirection:
                                "column",
                            }}
                          >
                            <span
                              className="liningPrice"
                              style={{
                                marginLeft:
                                  "5px",
                              }}
                            ></span>
                            <span
                              className="liningMaterialPrice"
                              style={{
                                marginLeft:
                                  "5px",
                              }}
                            ></span>
                          </div>
                          <div
                            className="price-item nonBold"
                            style={{
                              display: "flex",
                              flexDirection:
                                "column",
                            }}
                          >
                            <span
                              className="interliningPrice"
                              style={{
                                marginLeft:
                                  "5px",
                              }}
                            ></span>
                            <span
                              className="interliningMaterialPrice"
                              style={{
                                marginLeft:
                                  "5px",
                              }}
                            ></span>
                          </div>
                          <div
                            className="price-item nonBold"
                            style={{
                              display: "flex",
                              flexDirection:
                                "column",
                            }}
                          >
                            <span className="bandingPrice"></span>
                            <span className="cordingPrice"></span>
                            <span className="cordingFabricPrice"></span>
                            <span className="flangePrice"></span>
                            <span className="flangeFabricPrice"></span>
                            <span className="trimPrice"></span>
                            <span className="trimFabricPrice"></span>
                            <span className="othersEmbelishmentsPrice"></span>
                          </div>
                          <div
                            className="price-item"
                            style={{
                              display: "flex",
                              flexDirection:
                                "column",
                            }}
                          >
                            <span className="pinningPrice"></span>
                            <span className="weightPrice"></span>
                            <span className="cafePrice"></span>
                            <span className="extraLongPrice"></span>
                            <span className="extraWidePrice"></span>
                          </div>
                          <div
                            className="price-item"
                            style={{
                              display: "flex",
                              flexDirection:
                                "column",
                            }}
                          >
                            <span className="specialityPrice"></span>
                            <span className="specialityPriceLining"></span>
                            <span className="specialityPriceInterlining"></span>
                            <span className="specialityPriceBanding"></span>
                            <span className="specialityPriceCordingPreMade"></span>
                            <span className="specialityPriceCordingFabric"></span>
                            <span className="specialityPriceFlangePreMade"></span>
                            <span className="specialityPriceFlangeFabric"></span>
                            <span className="specialityPriceTrimPreMade"></span>
                            <span className="specialityPriceTrimFabric"></span>
                            <span className="specialityPriceOther"></span>
                          </div>
                          <br></br>
                          <br></br>
                        </SummaryTableCell>
                      )}
                      {!isDraperyOrder &&
                        <SummaryTableCell style={{ height: '100%', display: 'flex' }} text="total-per-unit">
                          <span
                            style={{
                              fontWeight: "600",
                              marginRight: 20
                            }}
                          >
                            $
                          </span>
                          <CalculatorFieldInput uiWidth={250} style={{ border: '1px solid #b3b3b3', borderRadius: "0.5rem", height: '33px', marginTop: '-6px' }}>
                            <CalculatorYardageInput
                              id="draperyOrderSummary.totalPerUnit"
                              name="draperyOrderSummary.totalPerUnit"
                              type="text"
                              min={0}
                              disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                              uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                              value={
                                draperyOrderSummary.totalPerUnit != null
                                  ? (!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0
                                    ? Number(draperyOrderSummary.totalPerUnit).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                    : draperyOrderSummary.totalPerUnit.toString())
                                  : ''
                              }

                              onChange={this.props.handleInputChangeForNumber}
                            />

                          </CalculatorFieldInput>
                          <span style={{ fontWeight: "600", paddingLeft: "8px" }}>TOTAL PER UNIT</span>
                        </SummaryTableCell>
                      }
                      {!isDraperyOrder &&
                        <SummaryTableCell style={{ display: 'flex',  marginTop: 'auto', border: '1px solid rgb(218, 218, 218)', borderBottom: 0 , height: '70px', }} text="headrail-per-unit">
                          <span
                            style={{
                              fontWeight: "600",
                              marginRight: 20,
                              height: '70px',
                            }}
                          >
                            $
                          </span>
                          <CalculatorFieldInput uiWidth={250} style={{ border: '1px solid #b3b3b3', borderRadius: "0.5rem", height: '33px', marginTop: '-5px'}}>
                            <CalculatorYardageInput
                              id="draperyOrderSummary.headrailPerUnit"
                              name="draperyOrderSummary.headrailPerUnit"
                              type="text"
                              min={0}
                              disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                              uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                              value={
                                draperyOrderSummary.headrailPerUnit != null
                                  ? (!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0
                                    ? Number(draperyOrderSummary.headrailPerUnit).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                    : draperyOrderSummary.headrailPerUnit.toString())
                                  : ''
                              }

                              onChange={this.props.handleInputChangeForNumber}
                            />
                          </CalculatorFieldInput>
                          <span style={{ fontWeight: "600", paddingLeft: "8px" }}>PER UNIT</span>
                        </SummaryTableCell>
                      }
                    </SummaryTableCol>
                    <SummaryTableCol
                      text="fabrics"
                      color="header"
                      style={{
                        minWidth: "13%",
                        maxWidth: "18%",
                      }}
                    >
                      <SummaryTableCell text="title">
                        Fabrication Price
                      </SummaryTableCell>

                      {(workOrderEditMode && isDraperyOrder) ? null : (
                        <SummaryTableCell>
                          <span
                            style={{
                              fontWeight: "600",
                              paddingBottom:
                                "20px",
                            }}
                          >
                            $ {otherItemTotal} TOTAL
                          </span>
                        </SummaryTableCell>
                      )}

                      {(workOrderEditMode && isDraperyOrder) ? null : (
                        <SummaryTableCell style={{marginTop: 'auto', borderTop: '1px solid rgb(218, 218, 218)' , height: '70px' }}>
                          <span
                            style={{
                              fontWeight: "600",
                                marginTop: 'auto',
                                display: 'block'

                            }}
                          >
                            $ {romanShadeHeadrailTotal} TOTAL
                          </span>
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>
                  </SummaryTable>

                  {workOrderEditMode && isDraperyOrder && this.props.data.orderType !== 'roman-shade-order' &&
                    (loadingUpdate ? (
                      <div className="work_order_edit_mode_alert_container">
                      </div>
                    ) : (
                      <div className="work_order_edit_mode_alert_container">
                        <RedWarning />
                        <p className="work_order_edit_mode_alert_message">
                          This field will be
                          re-calculated after update
                        </p>
                      </div>
                    ))}
                </HContainer>
              ) : (
                <HContainer text="fabrics">
                  <SummaryTable>
                    <SummaryTableCol>
                      <SummaryTableCell text="title">
                        Work Order
                      </SummaryTableCell>
                      <SummaryTableCell></SummaryTableCell>
                      <SummaryTableCell></SummaryTableCell>
                      <SummaryTableCell></SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol>
                      <SummaryTableCell text="title">
                        Description <span>&nbsp;</span>
                        <span
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          (per unit)
                        </span>
                      </SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol>
                      <SummaryTableCell text="title">
                        Quantity
                      </SummaryTableCell>
                    </SummaryTableCol>

                    <SummaryTableCol>
                      <SummaryTableCell
                        text="title"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Fabrication price{" "}
                        <span>&nbsp;</span>
                        <span
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          (per unit)
                        </span>
                      </SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol>
                      <SummaryTableCell text="title">
                        Fabrication price
                      </SummaryTableCell>
                    </SummaryTableCol>
                  </SummaryTable>
                </HContainer>
              )}
              {this.props.data &&
                this.props.data.workOrderPdfContentSummery &&
                this.props.data.workOrder.id > 0 && (this.props.data.orderType !== "roman-shade-order") ? (
                <HContainer text="fabrics" uiColumnDirection>
                  <SummaryTable>
                    <SummaryTableCol
                      text="fabrics"
                      color="header"
                    >
                      <SummaryTableCell
                        className="fabricHeader"
                        text="title"
                        style={{
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Fabrics
                      </SummaryTableCell>

                      {workOrderEditMode ? null : (
                        <SummaryTableCell text="fabrics">
                          <WorkOrderNumber className="left">
                            <div>
                              <p>
                                {this.props.data
                                  .mainFabric &&
                                  this.props
                                    .data
                                    .mainFabric
                                    .name}
                              </p>
                              <p>
                                {this.props.data
                                  .mainFabric &&
                                  (this.props
                                    .data
                                    .mainFabric
                                    .supplierName
                                    ? this
                                      .props
                                      .data
                                      .mainFabric
                                      .supplierName
                                    : this
                                      .props
                                      .data
                                      .mainFabric
                                      .customSupplier
                                      ? this
                                        .props
                                        .data
                                        .mainFabric
                                        .customSupplier
                                      : this
                                        .props
                                        .data
                                        .mainFabric
                                        .orderOrigin ===
                                        1
                                        ? "Unspecified"
                                        : this
                                          .props
                                          .data
                                          .mainFabric
                                          .orderOrigin ===
                                          2
                                          ? "COM"
                                          : "")}
                              </p>
                              <p>
                                {(this.props
                                  .data
                                  .mainFabric &&
                                  this.props
                                    .data
                                    .mainFabric
                                    .pattern &&
                                  this.props
                                    .data
                                    .mainFabric
                                    .pattern) ||
                                  "Unspecified"}
                              </p>

                              <div>
                                {this.props
                                  .data &&
                                  this.props
                                    .data
                                    .fabricsPatternItems &&
                                  this.props.data.fabricsPatternItems
                                    .filter(
                                      (
                                        item: any
                                      ) =>
                                        item.name ===
                                        this
                                          .props
                                          .data
                                          .mainFabric
                                          .pattern
                                    )
                                    .map(
                                      (
                                        item: any
                                      ) => {
                                        return (
                                          <div
                                            style={{
                                              color: "#545454",
                                            }}
                                          >
                                            {
                                              item.itemNumber
                                            }
                                          </div>
                                        );
                                      }
                                    )}
                              </div>
                              <WorkorderMeasureWrapper>
                                {this.props
                                  .data &&
                                  this.props
                                    .data
                                    .fabricsPatternItems &&
                                  Object.values(
                                    this
                                      .props
                                      .data
                                      .mainFabric
                                      .values
                                  ).map(
                                    (
                                      value,
                                      index
                                    ) => {
                                      return (
                                        <WorkorderMeasure
                                          className="last-separator"
                                          style={{
                                            color: "#545454",
                                          }}
                                        >
                                          <span>
                                            {" "}
                                            {
                                              property[
                                              index
                                              ]
                                            }
                                          </span>
                                          <p>
                                            {
                                              value
                                            }

                                            "
                                            <span>
                                              /
                                            </span>
                                          </p>
                                        </WorkorderMeasure>
                                      );
                                    }
                                  )}
                              </WorkorderMeasureWrapper>
                            </div>
                          </WorkOrderNumber>

                          {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const newItem =
                                item &&
                                item.pattern;
                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.orderOrigin ===
                                          1
                                          ? "Unspecified"
                                          : item.orderOrigin ===
                                            2
                                            ? "COM"
                                            : item.name.split(
                                              "/"
                                            )[0] ||
                                            ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                    <WorkorderMeasureWrapper>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        Object.entries(
                                          this
                                            .props
                                            .data
                                            .workOrderPdfContentSummery
                                            .bandingFabricContents[
                                            index
                                          ]
                                            .trimWidth
                                        )
                                          .filter(
                                            ([
                                              key,
                                              value,
                                            ]) =>
                                              key !==
                                              "trimWidth"
                                          )
                                          .map(
                                            (
                                              [
                                                key,
                                                value,
                                              ],
                                              index
                                            ) => {
                                              return (
                                                <WorkorderMeasure
                                                  className="last-separator"
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  <span>
                                                    {" "}
                                                    {
                                                      property[
                                                      index
                                                      ]
                                                    }
                                                  </span>
                                                  <p>
                                                    {
                                                      value
                                                    }

                                                    "
                                                    <span>
                                                      /
                                                    </span>
                                                  </p>
                                                </WorkorderMeasure>
                                              );
                                            }
                                          )}
                                    </WorkorderMeasureWrapper>
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.pattern;
                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.name.split(
                                          "/"
                                        )[0]
                                          ? item.name.split(
                                            "/"
                                          )[0]
                                          : item
                                            .fabric
                                            .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item
                                              .fabric
                                              .orderOrigin ===
                                              2
                                              ? "COM"
                                              : ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.pattern;
                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.name.split(
                                          "/"
                                        )[0]
                                          ? item.name.split(
                                            "/"
                                          )[0]
                                          : item
                                            .fabric
                                            .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item
                                              .fabric
                                              .orderOrigin ===
                                              2
                                              ? "COM"
                                              : ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.pattern;
                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.name.split(
                                          "/"
                                        )[0]
                                          ? item.name.split(
                                            "/"
                                          )[0]
                                          : item
                                            .fabric
                                            .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item
                                              .fabric
                                              .orderOrigin ===
                                              2
                                              ? "COM"
                                              : ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                    {item.trimWidth && (
                                      <div>
                                        <span>
                                          W:
                                        </span>
                                        {
                                          item.trimWidth
                                        }

                                        "
                                      </div>
                                    )}
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.pattern;
                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.name.split(
                                          "/"
                                        )[0]
                                          ? item.name.split(
                                            "/"
                                          )[0]
                                          : item
                                            .fabric
                                            .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item
                                              .fabric
                                              .orderOrigin ===
                                              2
                                              ? "COM"
                                              : ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                    <div>
                                      <span>
                                        W:
                                      </span>
                                      {
                                        item.trimWidth
                                      }
                                      "
                                    </div>
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.pattern;

                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.name.split(
                                          "/"
                                        )[0]
                                          ? item.name.split(
                                            "/"
                                          )[0]
                                          : item
                                            .fabric
                                            .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item
                                              .fabric
                                              .orderOrigin ===
                                              2
                                              ? "COM"
                                              : ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                    <div>
                                      <span>
                                        W:
                                      </span>
                                      {
                                        item.trimWidth
                                      }
                                      "
                                    </div>
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const newItem =
                                item &&
                                item.pattern;
                              return (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {
                                        item.fabric
                                      }
                                    </p>
                                    <p>
                                      {item.supplier
                                        ? item.supplier
                                        : item.name.split(
                                          "/"
                                        )[0]
                                          ? item.name.split(
                                            "/"
                                          )[0]
                                          : item
                                            .fabric
                                            .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item
                                              .fabric
                                              .orderOrigin ===
                                              2
                                              ? "COM"
                                              : ""}
                                    </p>
                                    <p>
                                      {item.pattern ||
                                        "Unspecified"}
                                    </p>

                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                    <WorkorderMeasureWrapper>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        Object.entries(
                                          this
                                            .props
                                            .data
                                            .workOrderPdfContentSummery
                                            .fabricTrimFabricContents[
                                            index
                                          ]
                                            .trimWidth
                                        )
                                          .filter(
                                            ([
                                              key,
                                              value,
                                            ]) =>
                                              key !==
                                              "trimWidth"
                                          )
                                          .map(
                                            (
                                              [
                                                key,
                                                value,
                                              ],
                                              index
                                            ) => {
                                              return (
                                                <WorkorderMeasure
                                                  className="last-separator"
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  <span>
                                                    {" "}
                                                    {
                                                      property[
                                                      index
                                                      ]
                                                    }
                                                  </span>
                                                  <p>
                                                    {
                                                      value
                                                    }

                                                    "
                                                    <span>
                                                      /
                                                    </span>
                                                  </p>
                                                </WorkorderMeasure>
                                              );
                                            }
                                          )}
                                    </WorkorderMeasureWrapper>
                                  </div>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data &&
                            this.props.data
                              .liningsList &&
                            this.props.data.liningsList.map(
                              (item: any) => {
                                const newItem =
                                  item &&
                                    item.draperyOrderLiningsFabric &&
                                    item
                                      .draperyOrderLiningsFabric
                                      .pattern &&
                                    item
                                      .draperyOrderLiningsFabric
                                      .orderOrigin ===
                                    1
                                    ? item
                                      .draperyOrderLiningsFabric
                                      .pattern
                                    : item.draperyOrderLiningsFabric;
                                if (newItem) {
                                  return (
                                    <WorkOrderNumber className="left">
                                      <div>
                                        <p>
                                          {
                                            item
                                              .draperyOrderLiningsFabric
                                              .name
                                          }
                                        </p>
                                        <p>
                                          {item
                                            .draperyOrderLiningsFabric
                                            .supplierName
                                            ? item
                                              .draperyOrderLiningsFabric
                                              .supplierName
                                            : item
                                              .draperyOrderLiningsFabric
                                              .customSupplier
                                              ? item
                                                .draperyOrderLiningsFabric
                                                .customSupplier
                                              : item
                                                .draperyOrderLiningsFabric
                                                .orderOrigin ===
                                                1
                                                ? "Unspecified"
                                                : item
                                                  .draperyOrderLiningsFabric
                                                  .orderOrigin ===
                                                  2
                                                  ? "COM"
                                                  : ""}
                                        </p>
                                        <p>
                                          {item
                                            .draperyOrderLiningsFabric
                                            .pattern ||
                                            "Unspecified"}
                                        </p>

                                        <div>
                                          {this
                                            .props
                                            .data &&
                                            this
                                              .props
                                              .data
                                              .liningsPatternItems &&
                                            this.props.data.liningsPatternItems
                                              .filter(
                                                (
                                                  item: any,
                                                  index: number
                                                ) => {
                                                  return (
                                                    item.name ===
                                                    newItem
                                                  );
                                                }
                                              )
                                              .map(
                                                (
                                                  item: any
                                                ) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        color: "#545454",
                                                      }}
                                                    >
                                                      {
                                                        item.itemNumber
                                                      }
                                                    </div>
                                                  );
                                                }
                                              )}
                                        </div>
                                        <div>
                                          <span className="mr-2">
                                            W:
                                          </span>
                                          {item &&
                                            item.draperyOrderLiningsFabric &&
                                            item
                                              .draperyOrderLiningsFabric
                                              .values &&
                                            item
                                              .draperyOrderLiningsFabric
                                              .values
                                              .fabricWidth}

                                          "
                                        </div>
                                      </div>
                                    </WorkOrderNumber>
                                  );
                                }
                              }
                            )}
                          {this.props.data
                            .othersEmbellishments[0]
                            .name &&
                            this.props.data.othersEmbellishments.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const newItem =
                                  item &&
                                  item.draperyOrderFabric &&
                                  item
                                    .draperyOrderFabric
                                    .pattern;
                                return (
                                  <WorkOrderNumber className="left">
                                    <div>
                                      <p>
                                        {item &&
                                          item.name &&
                                          `${item.name.toUpperCase()} FABRIC`}
                                      </p>
                                      <p>
                                        {item &&
                                          item.draperyOrderFabric &&
                                          item
                                            .draperyOrderFabric
                                            .supplierName
                                          ? item
                                            .draperyOrderFabric
                                            .supplierName
                                          : item &&
                                            item.draperyOrderFabric &&
                                            item
                                              .draperyOrderFabric
                                              .customSupplier
                                            ? item &&
                                            item.draperyOrderFabric &&
                                            item
                                              .draperyOrderFabric
                                              .customSupplier
                                            : item &&
                                              item.draperyOrderFabric &&
                                              item
                                                .draperyOrderFabric
                                                .orderOrigin ===
                                              1
                                              ? "Unspecified"
                                              : item &&
                                                item.draperyOrderFabric &&
                                                item
                                                  .draperyOrderFabric
                                                  .orderOrigin ===
                                                2
                                                ? "COM"
                                                : ""}
                                      </p>
                                      <p>
                                        {item &&
                                          item.draperyOrderFabric &&
                                          item
                                            .draperyOrderFabric
                                            .pattern
                                          ? item
                                            .draperyOrderFabric
                                            .pattern
                                          : "Unspecified"}
                                      </p>
                                      <div>
                                        {this
                                          .props
                                          .data &&
                                          this
                                            .props
                                            .data
                                            .fabricsPatternItems &&
                                          this.props.data.fabricsPatternItems
                                            .filter(
                                              (
                                                item: any,
                                                index: number
                                              ) => {
                                                return (
                                                  item.name ===
                                                  newItem
                                                );
                                              }
                                            )
                                            .map(
                                              (
                                                item: any
                                              ) => {
                                                return (
                                                  <div
                                                    style={{
                                                      color: "#545454",
                                                    }}
                                                  >
                                                    {
                                                      item.itemNumber
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                      </div>
                                      <WorkorderMeasureWrapper>
                                        {this
                                          .props &&
                                          this
                                            .props
                                            .data &&
                                          this
                                            .props
                                            .data
                                            .fabricsPatternItems &&
                                          this
                                            .props
                                            .data
                                            .othersEmbellishments &&
                                          this
                                            .props
                                            .data
                                            .othersEmbellishments[
                                          index
                                          ] &&
                                          this
                                            .props
                                            .data
                                            .othersEmbellishments[
                                            index
                                          ]
                                            .draperyOrderFabric &&
                                          this
                                            .props
                                            .data
                                            .othersEmbellishments[
                                            index
                                          ]
                                            .draperyOrderFabric
                                            .values &&
                                          Object.entries(
                                            this
                                              .props
                                              .data
                                              .othersEmbellishments[
                                              index
                                            ]
                                              .draperyOrderFabric
                                              .values
                                          )
                                            .filter(
                                              ([
                                                key,
                                                value,
                                              ]) =>
                                                key !==
                                                "trimWidth"
                                            )
                                            .map(
                                              (
                                                [
                                                  key,
                                                  value,
                                                ],
                                                index
                                              ) => {
                                                return (
                                                  <WorkorderMeasure
                                                    className="last-separator"
                                                    style={{
                                                      color: "#545454",
                                                    }}
                                                  >
                                                    <span>
                                                      {" "}
                                                      {
                                                        property[
                                                        index
                                                        ]
                                                      }
                                                    </span>
                                                    <p>
                                                      {
                                                        value
                                                      }

                                                      "
                                                      <span>
                                                        /
                                                      </span>
                                                    </p>
                                                  </WorkorderMeasure>
                                                );
                                              }
                                            )}
                                      </WorkorderMeasureWrapper>
                                    </div>
                                  </WorkOrderNumber>
                                );
                              }
                            )}
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Yardage
                      </SummaryTableCell>

                      {workOrderEditMode ? null : (
                        <SummaryTableCell text="fabrics">
                          <WorkOrderNumber>
                            {
                              this.props.data
                                .workOrderPdfContentSummery
                                .mainFabricContent
                                .yardsNeeded
                            }
                          </WorkOrderNumber>
                          {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                            (content: any) => {
                              return (
                                <WorkOrderNumber>
                                  {
                                    content.yardsNeeded
                                  }
                                </WorkOrderNumber>
                              );
                            }
                          )}
                          {this.props.data &&
                            this.props.data
                              .liningsList &&
                            this.props.data
                              .liningsList
                              .length > 0 &&
                            this.props.data
                              .liningsList[0] &&
                            (this.props.data
                              .liningsList[0]
                              .active || forBackValance) ? (
                              <WorkOrderNumber>
                                {this.props
                                  .data &&
                                  this.props
                                    .data
                                    .liningsList && (
                                    <>
                                      {this.props.data.liningsList.map(
                                        (
                                          item: any
                                        ) => {
                                          if (
                                            item.draperyOrderLiningsFabric &&
                                            item
                                              .draperyOrderLiningsFabric
                                              .name ===
                                            "LINING FABRIC"
                                          ) {
                                            liningMaterialYardage =
                                              item
                                                .draperyOrderLiningsFabric
                                                .yardsNeeded;
                                            return liningMaterialYardage;
                                          }
                                        }
                                      )}
                                    </>
                                  )}
                              </WorkOrderNumber>
                            ) : null}
                          {this.props.data &&
                            this.props.data
                              .liningsList &&
                            this.props.data
                              .liningsList
                              .length > 0 &&
                            this.props.data
                              .liningsList[1] &&
                            this.props.data
                              .liningsList[1]
                              .active && (
                              <WorkOrderNumber>
                                <>
                                  {this.props.data.liningsList.map(
                                    (
                                      item: any
                                    ) => {
                                      if (
                                        item.draperyOrderLiningsFabric &&
                                        item
                                          .draperyOrderLiningsFabric
                                          .name ===
                                        "INTERLINING FABRIC"
                                      ) {
                                        interliningMaterialYardage =
                                          item
                                            .draperyOrderLiningsFabric
                                            .yardsNeeded;
                                        return interliningMaterialYardage;
                                      }
                                    }
                                  )}
                                </>
                              </WorkOrderNumber>
                            )}
                          {this.props.data
                            .othersEmbellishments[0]
                            .name &&
                            this.props.data.othersEmbellishments.map(
                              (content: any) => {
                                return (
                                  <WorkOrderNumber>
                                    {content &&
                                      content.draperyOrderFabric &&
                                      content
                                        .draperyOrderFabric
                                        .yardsNeeded}
                                  </WorkOrderNumber>
                                );
                              }
                            )}
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Unit Price ($)
                      </SummaryTableCell>

                      {workOrderEditMode ? null : (
                        <SummaryTableCell text="fabrics">
                          <WorkOrderNumber>
                            {this.props.data &&
                              this.props.data
                                .mainFabric
                                .orderOrigin ===
                              2 ? (
                              <WorkOrderNumber>
                                COM
                              </WorkOrderNumber>
                            ) : (
                              <WorkOrderNumber>
                                {
                                  this.state
                                    .prices
                                    .mainFabricUnitPrice
                                }
                              </WorkOrderNumber>
                            )}
                          </WorkOrderNumber>
                          {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                            (
                              item: any,
                              index: any
                            ) => {
                              const bandingUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .bandingUnitPrice,
                                ];
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .bandingFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .bandingFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  bandingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <>
                                  {bandingUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        bandingUnitPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const cordingUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .cordingUnitPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    0
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .premadeCordingFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeCordingFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <>
                                  {cordingUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        cordingUnitPrice[
                                          index
                                        ]
                                          .price
                                      }{" "}
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const flangeUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .flangeUnitPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    0
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .premadeFlangeFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeFlangeFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  flangeUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <>
                                  {flangeUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        flangeUnitPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const trimUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .trimUnitPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    0
                                );
                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props.data
                              //     .workOrderPdfContentSummery
                              //     .premadeTrimFabricContents
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this.props
                              //       .data
                              //       .workOrderPdfContentSummery
                              //       .premadeTrimFabricContents[
                              //       i
                              //     ]
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //     trimUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                              return (
                                <>
                                  {trimUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        trimUnitPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const cordingUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .cordingUnitPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    1
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .weltCordingFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .weltCordingFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }

                              return (
                                <>
                                  {cordingUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        cordingUnitPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const flangeUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .flangeUnitPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    1
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .fabricFlangeFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .fabricFlangeFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  flangeUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <>
                                  {flangeUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        flangeUnitPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const trimUnitPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .trimUnitPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    1
                                );
                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props.data
                              //     .workOrderPdfContentSummery
                              //     .fabricTrimFabricContents
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this.props
                              //       .data
                              //       .workOrderPdfContentSummery
                              //       .fabricTrimFabricContents[
                              //       i
                              //     ]
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //     trimUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                              return (
                                <>
                                  {trimUnitPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        trimUnitPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </>
                              );
                            }
                          )}
                          {this.props.data &&
                            this.props.data
                              .liningsList &&
                            this.props.data.liningsList.map(
                              (item: any) => {
                                const newItem =
                                  item &&
                                  item.draperyOrderLiningsFabric &&
                                  item.draperyOrderLiningsFabric;

                                if (newItem) {
                                  return (
                                    <>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .liningsPatternItems &&
                                        item
                                          .draperyOrderLiningsFabric
                                          .orderOrigin ===
                                        2 ? (
                                        <WorkOrderNumber>
                                          COM
                                        </WorkOrderNumber>
                                      ) : (
                                        <WorkOrderNumber>
                                          {item.name.toLowerCase() ===
                                            "lining"
                                            ? this
                                              .state
                                              .prices
                                              .liningsFabricUnitPrice
                                            : this
                                              .state
                                              .prices
                                              .interliningsFabricUnitPrice}
                                        </WorkOrderNumber>
                                      )}
                                    </>
                                  );
                                }
                              }
                            )}
                          {this.props.data
                            .othersEmbellishments[0]
                            .name &&
                            this.props.data.othersEmbellishments.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const othersEmbellishmentsUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .othersEmbellishmentsUnitPrice,
                                  ];
                                // for (
                                //   let i = 0;
                                //   i <
                                //   this.props
                                //     .data
                                //     .othersEmbellishments
                                //     .length;
                                //   i++
                                // ) {
                                //   if (
                                //     this
                                //       .props
                                //       .data
                                //       .othersEmbellishments[
                                //       i
                                //     ]
                                //       .orderOrigin ===
                                //     2
                                //   ) {
                                //     othersEmbellishmentsUnitPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //   }
                                // }
                                return (
                                  <>
                                    {othersEmbellishmentsUnitPrice[
                                      index
                                    ] && item.draperyOrderFabric.orderOrigin && item.draperyOrderFabric.orderOrigin === 1 ? (
                                      <WorkOrderNumber>
                                        {
                                          othersEmbellishmentsUnitPrice[
                                            index
                                          ]
                                            .price
                                        }
                                      </WorkOrderNumber>
                                    ) : (
                                      <WorkOrderNumber>
                                        COM
                                      </WorkOrderNumber>
                                    )}
                                  </>
                                );
                              }
                            )}
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>{" "}
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        S+H ($)
                      </SummaryTableCell>

                      {workOrderEditMode ? null : (
                        <SummaryTableCell text="fabrics">
                          {this.props.data &&
                            this.props.data
                              .fabricsPatternItems &&
                            this.props.data.mainFabric
                              .orderOrigin === 2 ? (
                            <WorkOrderNumber>
                              COM
                            </WorkOrderNumber>
                          ) : (
                            <WorkOrderNumber>
                              {
                                this.state
                                  .prices
                                  .mainFabricShippingPrice
                              }
                            </WorkOrderNumber>
                          )}
                          {this.props.data.workOrderPdfContentSummery.bandingFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const bandingShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .bandingShippingPrice,
                                ];
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .bandingFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .bandingFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  bandingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }

                              return (
                                <div>
                                  {bandingShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        bandingShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeCordingFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const cordingShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .cordingShippingPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    0
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .premadeCordingFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeCordingFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <div>
                                  {cordingShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        cordingShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}

                          {this.props.data.workOrderPdfContentSummery.premadeFlangeFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const flangeShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .flangeShippingPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    0
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .premadeFlangeFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeFlangeFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  flangeShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <div>
                                  {flangeShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        flangeShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.premadeTrimFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const trimShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .trimShippingPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    0
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .premadeTrimFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeTrimFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  trimShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <div>
                                  {trimShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        trimShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.weltCordingFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const cordingShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .cordingShippingPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    1
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .weltCordingFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .weltCordingFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <div>
                                  {cordingShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        cordingShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricFlangeFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const flangeShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .flangeShippingPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    1
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .fabricFlangeFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .fabricFlangeFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  flangeShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <div>
                                  {flangeShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        flangeShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}
                          {this.props.data.workOrderPdfContentSummery.fabricTrimFabricContents.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const trimShippingPrice =
                                [
                                  ...this
                                    .state
                                    .prices
                                    .trimShippingPrice,
                                ].filter(
                                  (item) =>
                                    item.subType ===
                                    1
                                );
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .workOrderPdfContentSummery
                                  .fabricTrimFabricContents
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .fabricTrimFabricContents[
                                    i
                                  ]
                                    .orderOrigin ===
                                  2
                                ) {
                                  trimShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return (
                                <div>
                                  {trimShippingPrice[
                                    index
                                  ] ? (
                                    <WorkOrderNumber>
                                      {
                                        trimShippingPrice[
                                          index
                                        ]
                                          .price
                                      }
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  )}
                                </div>
                              );
                            }
                          )}
                          {this.props.data.liningsList.map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.draperyOrderLiningsFabric &&
                                item.draperyOrderLiningsFabric;
                              if (newItem) {
                                return (
                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .liningsPatternItems &&
                                      item
                                        .draperyOrderLiningsFabric
                                        .orderOrigin ===
                                      2 ? (
                                      <WorkOrderNumber>
                                        COM
                                      </WorkOrderNumber>
                                    ) : (
                                      <WorkOrderNumber>
                                        {item.name
                                          .toLowerCase()
                                          .startsWith(
                                            "lining"
                                          )
                                          ? this
                                            .state
                                            .prices
                                            .liningsFabricShippingPrice
                                          : this
                                            .state
                                            .prices
                                            .interliningsFabricShippingPrice}
                                      </WorkOrderNumber>
                                    )}
                                  </div>
                                );
                              }
                            }
                          )}
                          {this.props.data
                            .othersEmbellishments[0]
                            .name &&
                            this.props.data.othersEmbellishments.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const othersEmbellishmentsShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .othersEmbellishmentsShippingPrice,
                                  ];
                                for (
                                  let i = 0;
                                  i <
                                  this.props
                                    .data
                                    .othersEmbellishments
                                    .length;
                                  i++
                                ) {
                                  if (
                                    this
                                      .props
                                      .data
                                      .othersEmbellishments[
                                      i
                                    ]
                                      .orderOrigin ===
                                    2
                                  ) {
                                    othersEmbellishmentsShippingPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                  }
                                }
                                return (
                                  <div>
                                    {othersEmbellishmentsShippingPrice[
                                      index
                                    ] ? (
                                      <WorkOrderNumber>
                                        {
                                          othersEmbellishmentsShippingPrice[
                                            index
                                          ]
                                            .price
                                        }
                                      </WorkOrderNumber>
                                    ) : (
                                      <WorkOrderNumber>
                                        COM
                                      </WorkOrderNumber>
                                    )}
                                  </div>
                                );
                              }
                            )}
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>{" "}
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Total Price ($)
                      </SummaryTableCell>

                      {workOrderEditMode ? null : (
                        <SummaryTableCell
                          text="fabrics"
                          last="last"
                        >
                          <WorkOrderNumber>
                            {this.props.data &&
                              this.props.data
                                .fabricsPatternItems &&
                              this.props.data
                                .mainFabric
                                .orderOrigin ===
                              1 ? (
                              (
                                parseFloat(
                                  this.state
                                    .prices
                                    .mainFabricUnitPrice
                                ) *
                                (this.props
                                  .data
                                  .workOrderPdfContentSummery
                                  .mainFabricContent
                                  .yardsNeeded ||
                                  1) +
                                parseFloat(
                                  this.state
                                    .prices
                                    .mainFabricShippingPrice
                                )
                              ).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            ) : (
                              <WorkOrderNumber>
                                COM
                              </WorkOrderNumber>
                            )}
                          </WorkOrderNumber>
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .bandingFabricContents &&
                            this.props.data.workOrderPdfContent.bandingFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const bandingShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .bandingShippingPrice,
                                  ];
                                const bandingUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .bandingUnitPrice,
                                  ];
                                for (
                                  let i = 0;
                                  i <
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .bandingFabricContents
                                    .length;
                                  i++
                                ) {
                                  if (
                                    this
                                      .props
                                      .data
                                      .workOrderPdfContentSummery
                                      .bandingFabricContents[
                                      i
                                    ]
                                      .orderOrigin ===
                                    2
                                  ) {
                                    bandingShippingPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                    bandingUnitPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                  }
                                }

                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        bandingUnitPrice[
                                          index
                                        ] &&
                                          bandingShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            parseFloat(
                                              bandingUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) *
                                            (item.yardsNeeded ||
                                              1) +
                                            parseFloat(
                                              bandingShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .premadeCordingFabricContents &&
                            this.props.data.workOrderPdfContent.premadeCordingFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const cordingUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .cordingUnitPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      0
                                  );
                                const cordingShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .cordingShippingPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      0
                                  );
                                for (
                                  let i = 0;
                                  i <
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeCordingFabricContents
                                    .length;
                                  i++
                                ) {
                                  if (
                                    this
                                      .props
                                      .data
                                      .workOrderPdfContentSummery
                                      .premadeCordingFabricContents[
                                      i
                                    ]
                                      .orderOrigin ===
                                    2
                                  ) {
                                    cordingUnitPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                    cordingShippingPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                  }
                                }
                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        cordingUnitPrice[
                                          index
                                        ] &&
                                          cordingShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            parseFloat(
                                              cordingUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) *
                                            (item.yardsNeeded ||
                                              1) +
                                            parseFloat(
                                              cordingShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .premadeFlangeFabricContents &&
                            this.props.data.workOrderPdfContent.premadeFlangeFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const flangeUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .flangeUnitPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      0
                                  );
                                const flangeShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .flangeShippingPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      0
                                  );
                                for (
                                  let i = 0;
                                  i <
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .premadeFlangeFabricContents
                                    .length;
                                  i++
                                ) {
                                  if (
                                    this
                                      .props
                                      .data
                                      .workOrderPdfContentSummery
                                      .premadeFlangeFabricContents[
                                      i
                                    ]
                                      .orderOrigin ===
                                    2
                                  ) {
                                    flangeUnitPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                    flangeShippingPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                  }
                                }

                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        flangeUnitPrice[
                                          index
                                        ] &&
                                          flangeShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            (item.yardsNeeded ||
                                              1) *
                                            parseFloat(
                                              flangeUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) +
                                            parseFloat(
                                              flangeShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .premadeTrimFabricContents &&
                            this.props.data.workOrderPdfContent.premadeTrimFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const trimUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .trimUnitPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      0
                                  );
                                const trimShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .trimShippingPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      0
                                  );
                                // for (
                                //   let i = 0;
                                //   i <
                                //   this.props
                                //     .data
                                //     .workOrderPdfContentSummery
                                //     .premadeTrimFabricContents
                                //     .length;
                                //   i++
                                // ) {
                                //   if (
                                //     this
                                //       .props
                                //       .data
                                //       .workOrderPdfContentSummery
                                //       .premadeTrimFabricContents[
                                //       i
                                //     ]
                                //       .orderOrigin ===
                                //     2
                                //   ) {
                                //     trimUnitPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //     trimShippingPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //   }
                                // }
                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        trimUnitPrice[
                                          index
                                        ] &&
                                          trimShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            (item.yardsNeeded ||
                                              1) *
                                            parseFloat(
                                              trimUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) +
                                            parseFloat(
                                              trimShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .weltCordingFabricContents &&
                            this.props.data.workOrderPdfContent.weltCordingFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const cordingUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .cordingUnitPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      1
                                  );
                                const cordingShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .cordingShippingPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      1
                                  );
                                for (
                                  let i = 0;
                                  i <
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .weltCordingFabricContents
                                    .length;
                                  i++
                                ) {
                                  if (
                                    this
                                      .props
                                      .data
                                      .workOrderPdfContentSummery
                                      .weltCordingFabricContents[
                                      i
                                    ]
                                      .orderOrigin ===
                                    2
                                  ) {
                                    cordingUnitPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                    cordingShippingPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                  }
                                }
                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        cordingUnitPrice[
                                          index
                                        ] &&
                                          cordingShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            (item.yardsNeeded ||
                                              1) *
                                            parseFloat(
                                              cordingUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) +
                                            parseFloat(
                                              cordingShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .fabricFlangeFabricContents &&
                            this.props.data.workOrderPdfContent.fabricFlangeFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const flangeUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .flangeUnitPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      1
                                  );
                                const flangeShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .flangeShippingPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      1
                                  );
                                for (
                                  let i = 0;
                                  i <
                                  this.props
                                    .data
                                    .workOrderPdfContentSummery
                                    .fabricFlangeFabricContents
                                    .length;
                                  i++
                                ) {
                                  if (
                                    this
                                      .props
                                      .data
                                      .workOrderPdfContentSummery
                                      .fabricFlangeFabricContents[
                                      i
                                    ]
                                      .orderOrigin ===
                                    2
                                  ) {
                                    flangeUnitPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                    flangeShippingPrice.splice(
                                      i,
                                      0,
                                      undefined
                                    );
                                  }
                                }
                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        flangeUnitPrice[
                                          index
                                        ] &&
                                          flangeShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            (item.yardsNeeded ||
                                              1) *
                                            parseFloat(
                                              flangeUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) +
                                            parseFloat(
                                              flangeShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .workOrderPdfContent &&
                            this.props.data
                              .workOrderPdfContent
                              .fabricTrimFabricContents &&
                            this.props.data.workOrderPdfContent.fabricTrimFabricContents.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const trimUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .trimUnitPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      1
                                  );
                                const trimShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .trimShippingPrice,
                                  ].filter(
                                    (
                                      item
                                    ) =>
                                      item.subType ===
                                      1
                                  );
                                // for (
                                //   let i = 0;
                                //   i <
                                //   this.props
                                //     .data
                                //     .workOrderPdfContentSummery
                                //     .fabricTrimFabricContents
                                //     .length;
                                //   i++
                                // ) {
                                //   if (
                                //     this
                                //       .props
                                //       .data
                                //       .workOrderPdfContentSummery
                                //       .fabricTrimFabricContents[
                                //       i
                                //     ]
                                //       .orderOrigin ===
                                //     2
                                //   ) {
                                //     trimUnitPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //     trimShippingPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //   }
                                // }
                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        trimUnitPrice[
                                          index
                                        ] &&
                                          trimShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            (item.yardsNeeded ||
                                              1) *
                                            parseFloat(
                                              trimUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) +
                                            parseFloat(
                                              trimShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data.liningsList.map(
                              (item: any) => {
                                return (
                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      item.draperyOrderLiningsFabric &&
                                      item
                                        .draperyOrderLiningsFabric
                                        .orderOrigin ===
                                      1 ? (
                                      item.name ===
                                        "Lining" ? (
                                        <WorkOrderNumber>
                                          {(
                                            (liningMaterialYardage ||
                                              1) *
                                            parseFloat(
                                              this
                                                .state
                                                .prices
                                                .liningsFabricUnitPrice
                                            ) +
                                            parseFloat(
                                              this
                                                .state
                                                .prices
                                                .liningsFabricShippingPrice
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          <p className="text-sm text-slate-400">
                                            Included
                                            with
                                            fabrication
                                            price
                                          </p>
                                        </WorkOrderNumber>
                                      ) : (
                                        <WorkOrderNumber>
                                          {(
                                            (interliningMaterialYardage ||
                                              1) *
                                            parseFloat(
                                              this
                                                .state
                                                .prices
                                                .interliningsFabricUnitPrice
                                            ) +
                                            parseFloat(
                                              this
                                                .state
                                                .prices
                                                .interliningsFabricShippingPrice
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                          <p className="text-sm text-slate-400">
                                            Included
                                            with
                                            fabrication
                                            price
                                          </p>
                                        </WorkOrderNumber>
                                      )
                                    ) : this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      item.draperyOrderLiningsFabric &&
                                      item
                                        .draperyOrderLiningsFabric
                                        .orderOrigin ===
                                      2 ? (
                                      <WorkOrderNumber>
                                        COM
                                      </WorkOrderNumber>
                                    ) : null}
                                  </div>
                                );
                              }
                            )}
                          {this.props.data &&
                            this.props.data
                              .othersEmbellishments &&
                            this.props.data
                              .othersEmbellishments[0]
                              .name &&
                            this.props.data.othersEmbellishments.map(
                              (
                                item: any,
                                index: number
                              ) => {
                                const othersEmbellishmentsUnitPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .othersEmbellishmentsUnitPrice,
                                  ];
                                const othersEmbellishmentsShippingPrice =
                                  [
                                    ...this
                                      .state
                                      .prices
                                      .othersEmbellishmentsShippingPrice,
                                  ];
                                // for (
                                //   let i = 0;
                                //   i <
                                //   this.props
                                //     .data
                                //     .othersEmbellishments
                                //     .length;
                                //   i++
                                // ) {
                                //   if (
                                //     this
                                //       .props
                                //       .data
                                //       .othersEmbellishments[
                                //       i
                                //     ]
                                //       .orderOrigin ===
                                //     2
                                //   ) {
                                //     othersEmbellishmentsUnitPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //     othersEmbellishmentsShippingPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //   }
                                // }
                                return (
                                  <div>
                                    <WorkOrderNumber>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems ? (
                                        othersEmbellishmentsUnitPrice[
                                          index
                                        ] &&
                                          othersEmbellishmentsShippingPrice[
                                          index
                                          ] ? (
                                          (
                                            (item &&
                                              item.draperyOrderFabric &&
                                              item
                                                .draperyOrderFabric
                                                .yardsNeeded ===
                                              0
                                              ? 0
                                              : (item &&
                                                item.draperyOrderFabric &&
                                                item
                                                  .draperyOrderFabric
                                                  .yardsNeeded) ||
                                              1) *
                                            parseFloat(
                                              othersEmbellishmentsUnitPrice[
                                                index
                                              ]
                                                .price
                                            ) +
                                            parseFloat(
                                              othersEmbellishmentsShippingPrice[
                                                index
                                              ]
                                                .price
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : (
                                          "COM"
                                        )
                                      ) : (
                                        <WorkOrderNumber>
                                          0.00
                                        </WorkOrderNumber>
                                      )}
                                    </WorkOrderNumber>
                                  </div>
                                );
                              }
                            )}
                        </SummaryTableCell>
                      )}
                    </SummaryTableCol>
                  </SummaryTable>

                  {workOrderEditMode &&
                    (loadingUpdate ? (
                      <div className="work_order_edit_mode_alert_container">
                      </div>
                    ) : (
                      <div className="work_order_edit_mode_alert_container">
                        <RedWarning />
                        <p className="work_order_edit_mode_alert_message">
                          This field will be
                          re-calculated after update
                        </p>
                      </div>
                    ))}
                </HContainer>
              ) : (this.props.data.orderType === "roman-shade-order") ? (
                <HContainer text="fabrics" uiColumnDirection>
                  <SummaryTable>
                    <SummaryTableCol
                      text="fabrics"
                      color="header"
                    >
                      <SummaryTableCell
                        className="fabricHeader"
                        text="title"
                        style={{
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Fabrics
                      </SummaryTableCell>
                      <SummaryTableCell text="fabrics">
                        <WorkOrderNumber className="left">
                          <div>
                            <p>
                              {this.props.data
                                .mainFabric &&
                                this.props
                                  .data
                                  .mainFabric
                                  .name}
                            </p>
                            <p>
                              {this.props.data
                                .mainFabric &&
                                (this.props
                                  .data
                                  .mainFabric
                                  .supplierName
                                  ? this
                                    .props
                                    .data
                                    .mainFabric
                                    .supplierName
                                  : this
                                    .props
                                    .data
                                    .mainFabric
                                    .customSupplier
                                    ? this
                                      .props
                                      .data
                                      .mainFabric
                                      .customSupplier
                                    : this
                                      .props
                                      .data
                                      .mainFabric
                                      .orderOrigin ===
                                      1
                                      ? "Unspecified"
                                      : this
                                        .props
                                        .data
                                        .mainFabric
                                        .orderOrigin ===
                                        2
                                        ? "COM"
                                        : "Unspecified")}
                            </p>
                          </div>
                          <p>
                            {(this.props
                              .data
                              .mainFabric &&
                              this.props
                                .data
                                .mainFabric
                                .pattern &&
                              this.props
                                .data
                                .mainFabric
                                .pattern) ||
                              "Unspecified"}
                          </p>

                          <div>
                            {this.props
                              .data &&
                              this.props
                                .data
                                .fabricsPatternItems &&
                              this.props.data.fabricsPatternItems
                                .filter(
                                  (
                                    item: any
                                  ) =>
                                    item.name ===
                                    this
                                      .props
                                      .data
                                      .mainFabric
                                      .pattern
                                )
                                .map(
                                  (
                                    item: any
                                  ) => {
                                    return (
                                      <div
                                        style={{
                                          color: "#545454",
                                        }}
                                      >
                                        {
                                          item.itemNumber
                                        }
                                      </div>
                                    );
                                  }
                                )}
                          </div>
                          <WorkorderMeasureWrapper>
                            {this.props
                              .data &&
                              this.props
                                .data
                                .fabricsPatternItems &&
                              Object.entries(
                                this
                                  .props
                                  .data
                                  .mainFabric
                                  .values
                              ).sort((a, b): any => {
                                const [keyA] = a
                                const [keyB] = b

                                if (keyA === 'horizontalRepeat') return 1
                                if (keyB === 'horizontalRepeat') return -1
                                if (keyA === 'fabricWidth') return -1
                                if (keyB === 'fabricWidth') return 1
                                return 0
                              }).map(
                                (
                                  [key, value]
                                ) => {
                                  if (value === null || value === '' || value === undefined) {
                                    return
                                  }
                                  const unit = key === 'horizontalRepeat' ? 2 : key === 'fabricWidth' ? 0 : 1
                                  return (
                                    <WorkorderMeasure
                                      className="last-separator"
                                      style={{
                                        color: "#545454",
                                      }}
                                    >
                                      <span>
                                        {" "}
                                        {
                                          property[
                                          unit
                                          ]
                                        }
                                      </span>
                                      <p>
                                        {
                                          value
                                        }

                                        "
                                        <span>
                                          /
                                        </span>
                                      </p>
                                    </WorkorderMeasure>
                                  );
                                }
                              )}
                          </WorkorderMeasureWrapper>
                        </WorkOrderNumber>

                        {this.props.data.bandings.map(
                          (
                            item: any,
                            index: number
                          ) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;

                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>

                                  <WorkorderMeasureWrapper>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems && this
                                          .props
                                          .data
                                          .bandings[
                                          index
                                        ]
                                          .draperyOrderFabric.values &&
                                      Object.entries(
                                        this
                                          .props
                                          .data
                                          .bandings[
                                          index
                                        ]
                                          .draperyOrderFabric.values
                                      )
                                        .filter(
                                          ([
                                            key,
                                            value,
                                          ]) =>
                                            key !==
                                            "trimWidth"
                                        ).sort((a, b): any => {
                                          const [keyA] = a
                                          const [keyB] = b

                                          if (keyA === 'horizontalRepeat') return 1
                                          if (keyB === 'horizontalRepeat') return -1
                                          if (keyA === 'fabricWidth') return -1
                                          if (keyB === 'fabricWidth') return 1
                                          return 0
                                        })
                                        .map(
                                          (
                                            [
                                              key,
                                              value,
                                            ],
                                            index
                                          ) => {
                                            if (value === null || value === '' || value === undefined) {
                                              return
                                            }
                                            const unit = key === 'horizontalRepeat' ? 2 : key === 'fabricWidth' ? 0 : 1
                                            return (
                                              <WorkorderMeasure
                                                className="last-separator"
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                <span>
                                                  {" "}
                                                  {
                                                    property[
                                                    unit
                                                    ]
                                                  }
                                                </span>
                                                <p>
                                                  {
                                                    value
                                                  }

                                                  "
                                                  <span>
                                                    /
                                                  </span>
                                                </p>
                                              </WorkorderMeasure>
                                            );
                                          }
                                        )}
                                  </WorkorderMeasureWrapper>
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 0).map(
                          (item: any) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;
                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 0).map(
                          (item: any) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;
                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 0).map(
                          (item: any) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;
                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>
                                  {item.draperyOrderFabric.values.trimWidth && (
                                    <div>
                                      <span>
                                        W:
                                      </span>
                                      {
                                        item.draperyOrderFabric.values.trimWidth
                                      }

                                      "
                                    </div>
                                  )}
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 1).map(
                          (item: any) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;
                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>
                                  {
                                    item.draperyOrderFabric.values.fabricWidth !== '' &&
                                    item.draperyOrderFabric.values.fabricWidth !== undefined &&
                                    item.draperyOrderFabric.values.fabricWidth !== null && (
                                      <div>
                                        <span>
                                          W:
                                        </span>
                                        <span style={{ marginLeft: '5px' }}>
                                          {
                                            item.draperyOrderFabric.values.fabricWidth
                                          }
                                          "
                                        </span>
                                      </div>
                                    )
                                  }
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 1).map(
                          (item: any) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;
                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>
                                  {
                                    item.draperyOrderFabric.values.fabricWidth !== '' &&
                                    item.draperyOrderFabric.values.fabricWidth !== undefined &&
                                    item.draperyOrderFabric.values.fabricWidth !== null && (
                                      <div>
                                        <span>
                                          W:
                                        </span>
                                        <span style={{ marginLeft: '5px' }}>
                                          {
                                            item.draperyOrderFabric.values.fabricWidth
                                          }
                                          "
                                        </span>
                                      </div>
                                    )
                                  }
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: number
                          ) => {
                            const newItem =
                              item && item.draperyOrderFabric &&
                              item.draperyOrderFabric.pattern;
                            return item.draperyOrderFabric ? (
                              <WorkOrderNumber className="left">
                                <div>
                                  <p>
                                    {
                                      item.draperyOrderFabric.name
                                    }
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.supplierName
                                      ? item.draperyOrderFabric.supplierName
                                      : item.draperyOrderFabric.orderOrigin ===
                                        1 || item.draperyOrderFabric.orderOrigin === -1
                                        ? "Unspecified"
                                        : item.draperyOrderFabric.orderOrigin ===
                                          2
                                          ? "COM"
                                          : item.draperyOrderFabric.pattern.split(
                                            "/"
                                          )[0] ||
                                          ""}
                                  </p>
                                  <p>
                                    {item.draperyOrderFabric.pattern ||
                                      "Unspecified"}
                                  </p>

                                  <div>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems &&
                                      this.props.data.fabricsPatternItems
                                        .filter(
                                          (
                                            item: any,
                                            index: number
                                          ) => {
                                            return (
                                              item.name ===
                                              newItem
                                            );
                                          }
                                        )
                                        .map(
                                          (
                                            item: any
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                {
                                                  item.itemNumber
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                  </div>
                                  <WorkorderMeasureWrapper>
                                    {this
                                      .props
                                      .data &&
                                      this
                                        .props
                                        .data
                                        .fabricsPatternItems && this
                                        .props
                                        .data
                                        .trims.filter((item: any) => item.subType === 1)
                                        [
                                        index
                                    ].draperyOrderFabric && 
                                        this
                                        .props
                                        .data
                                        .trims.filter((item: any) => item.subType === 1)
                                        [
                                        index
                                    ].draperyOrderFabric.values &&
                                      Object.entries(
                                        this
                                          .props
                                          .data
                                          .trims.filter((item: any) => item.subType === 1)
                                        [
                                          index
                                        ].draperyOrderFabric.values
                                      ).sort((a, b): any => {
                                        const [keyA] = a
                                        const [keyB] = b

                                        if (keyA === 'horizontalRepeat') return 1
                                        if (keyB === 'horizontalRepeat') return -1
                                        if (keyA === 'fabricWidth') return -1
                                        if (keyB === 'fabricWidth') return 1
                                        return 0
                                      })
                                        .filter(
                                          ([
                                            key,
                                            value,
                                          ]) =>
                                            key !==
                                            "trimWidth"
                                        )
                                        .map(
                                          (
                                            [
                                              key,
                                              value,
                                            ],
                                            index
                                          ) => {
                                            if(value === null || value === '' || value === undefined){
                                               return
                                            }
                                            return (
                                              <WorkorderMeasure
                                                className="last-separator"
                                                style={{
                                                  color: "#545454",
                                                }}
                                              >
                                                <span>
                                                  {" "}
                                                  {
                                                    property[
                                                    index
                                                    ]
                                                  }
                                                </span>
                                                <p>
                                                  {
                                                    value
                                                  }

                                                  "
                                                  <span>
                                                    /
                                                  </span>
                                                </p>
                                              </WorkorderMeasure>
                                            );
                                          }
                                        )}
                                  </WorkorderMeasureWrapper>
                                </div>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data &&
                          this.props.data
                            .liningsList &&
                          this.props.data.liningsList.filter((item: any, index: number) => {
                            if (index === 0) {
                              return item.active || forBackValance
                            } else {
                              return item.active
                            }
                          }).map(
                            (item: any) => {
                              const newItem =
                                item &&
                                  item.draperyOrderLiningsFabric &&
                                  item
                                    .draperyOrderLiningsFabric
                                    .pattern &&
                                  item
                                    .draperyOrderLiningsFabric
                                    .orderOrigin ===
                                  1
                                  ? item
                                    .draperyOrderLiningsFabric
                                    .pattern
                                  : item.draperyOrderLiningsFabric;
                              if (newItem) {
                                return item.draperyOrderLiningsFabric ? (
                                  <WorkOrderNumber className="left">
                                    <div>
                                      <p>
                                        {
                                          item
                                            .draperyOrderLiningsFabric
                                            .name
                                        }
                                      </p>
                                      <p>
                                        {item
                                          .draperyOrderLiningsFabric
                                          .supplierName
                                          ? item
                                            .draperyOrderLiningsFabric
                                            .supplierName
                                          : item
                                            .draperyOrderLiningsFabric
                                            .customSupplier
                                            ? item
                                              .draperyOrderLiningsFabric
                                              .customSupplier
                                            : item
                                              .draperyOrderLiningsFabric
                                              .orderOrigin ===
                                              1
                                              ? "Unspecified"
                                              : item
                                                .draperyOrderLiningsFabric
                                                .orderOrigin ===
                                                2
                                                ? "COM"
                                                : "Unspecified"}
                                      </p>
                                      <p>
                                        {item
                                          .draperyOrderLiningsFabric
                                          .pattern ||
                                          "Unspecified"}
                                      </p>

                                      <div>
                                        {this
                                          .props
                                          .data &&
                                          this
                                            .props
                                            .data
                                            .liningsPatternItems &&
                                          this.props.data.liningsPatternItems
                                            .filter(
                                              (
                                                item: any,
                                                index: number
                                              ) => {
                                                return (
                                                  item.name ===
                                                  newItem
                                                );
                                              }
                                            )
                                            .map(
                                              (
                                                item: any
                                              ) => {
                                                return (
                                                  <div
                                                    style={{
                                                      color: "#545454",
                                                    }}
                                                  >
                                                    {
                                                      item.itemNumber
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                      </div>
                                      {item && item.draperyOrderLiningsFabric && item.draperyOrderLiningsFabric.values && item.draperyOrderLiningsFabric.values.fabricWidth !== null && item.draperyOrderLiningsFabric.values.fabricWidth !== undefined && item.draperyOrderLiningsFabric.values.fabricWidth !== '' && (
                                        <div>
                                          <span className="mr-2">
                                            W:
                                          </span>
                                          {item
                                            .draperyOrderLiningsFabric
                                            .values
                                            .fabricWidth}

                                          "
                                        </div>
                                      )}
                                    </div>
                                  </WorkOrderNumber>
                                ) : null;
                              }
                            }
                          )}
                        {this.props.data
                          .othersEmbellishments[0] && this.props.data.othersEmbellishments[0].draperyOrderFabric &&
                          this.props.data.othersEmbellishments.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              const newItem =
                                item &&
                                item.draperyOrderFabric &&
                                item
                                  .draperyOrderFabric
                                  .pattern;
                              return item.draperyOrderFabric ? (
                                <WorkOrderNumber className="left">
                                  <div>
                                    <p>
                                      {item &&
                                        item.name ?
                                        `${item.name.toUpperCase()} FABRIC` :
                                          `UNSPECIFIED FABRIC`}
                                    </p>
                                    <p>
                                      {item &&
                                        item.draperyOrderFabric &&
                                        item
                                          .draperyOrderFabric
                                          .supplierName
                                        ? item
                                          .draperyOrderFabric
                                          .supplierName
                                        : item &&
                                          item.draperyOrderFabric &&
                                          item
                                            .draperyOrderFabric
                                            .customSupplier
                                          ? item &&
                                          item.draperyOrderFabric &&
                                          item
                                            .draperyOrderFabric
                                            .customSupplier
                                          : item &&
                                            item.draperyOrderFabric &&
                                            item
                                              .draperyOrderFabric
                                              .orderOrigin ===
                                            1
                                            ? "Unspecified"
                                            : item &&
                                              item.draperyOrderFabric &&
                                              item
                                                .draperyOrderFabric
                                                .orderOrigin ===
                                              2
                                              ? "COM"
                                              : "Unspecified"}
                                    </p>
                                    <p>
                                      {item &&
                                        item.draperyOrderFabric &&
                                        item
                                          .draperyOrderFabric
                                          .pattern
                                        ? item
                                          .draperyOrderFabric
                                          .pattern
                                        : "Unspecified"}
                                    </p>
                                    <div>
                                      {this
                                        .props
                                        .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this.props.data.fabricsPatternItems
                                          .filter(
                                            (
                                              item: any,
                                              index: number
                                            ) => {
                                              return (
                                                item.name ===
                                                newItem
                                              );
                                            }
                                          )
                                          .map(
                                            (
                                              item: any
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  {
                                                    item.itemNumber
                                                  }
                                                </div>
                                              );
                                            }
                                          )}
                                    </div>
                                    <WorkorderMeasureWrapper>
                                      {this
                                        .props &&
                                        this
                                          .props
                                          .data &&
                                        this
                                          .props
                                          .data
                                          .fabricsPatternItems &&
                                        this
                                          .props
                                          .data
                                          .othersEmbellishments &&
                                        this
                                          .props
                                          .data
                                          .othersEmbellishments[
                                        index
                                        ] &&
                                        this
                                          .props
                                          .data
                                          .othersEmbellishments[
                                          index
                                        ]
                                          .draperyOrderFabric &&
                                        this
                                          .props
                                          .data
                                          .othersEmbellishments[
                                          index
                                        ]
                                          .draperyOrderFabric
                                          .values &&
                                        Object.entries(
                                          this
                                            .props
                                            .data
                                            .othersEmbellishments[
                                            index
                                          ]
                                            .draperyOrderFabric
                                            .values
                                        )
                                          .sort((a, b): any => {
                                            const [keyA] = a
                                            const [keyB] = b

                                            if (keyA === 'horizontalRepeat') return 1
                                            if (keyB === 'horizontalRepeat') return -1
                                            if (keyA === 'fabricWidth') return -1
                                            if (keyB === 'fabricWidth') return 1
                                            return 0
                                          })
                                          .filter(
                                            ([
                                              key,
                                              value,
                                            ]) =>
                                              key !==
                                              "trimWidth"
                                          )
                                          .map(
                                            (
                                              [
                                                key,
                                                value,
                                              ],
                                              index
                                            ) => {
                                              if (value === null || value === '' || value === undefined) {
                                                return
                                              }
                                              return (
                                                <WorkorderMeasure
                                                  className="last-separator"
                                                  style={{
                                                    color: "#545454",
                                                  }}
                                                >
                                                  <span>
                                                    {" "}
                                                    {
                                                      property[
                                                      index
                                                      ]
                                                    }
                                                  </span>
                                                  <p>
                                                    {
                                                      value
                                                    }

                                                    "
                                                    <span>
                                                      /
                                                    </span>
                                                  </p>
                                                </WorkorderMeasure>
                                              );
                                            }
                                          )}
                                    </WorkorderMeasureWrapper>
                                  </div>
                                </WorkOrderNumber>
                              ) : null;
                            }
                          )}
                      </SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        // text="fabrics"
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Yardage
                      </SummaryTableCell>
                      <SummaryTableCell text="fabrics">
                        <WorkOrderNumber>
                          {
                            <CalculatorFieldInput uiWidth={180} style={{ margin: '0 auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                              <CalculatorYardageInput
                                id="mainFabricYardage"
                                name="mainFabricYardage"
                                type="text"
                                value={
                                  this.state.mainFabricYardage || ""
                                }
                                onChange={
                                  this.setYardage
                                }
                                disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                              />
                            </CalculatorFieldInput>
                          }
                        </WorkOrderNumber>
                      </SummaryTableCell>
                      <SummaryTableCell style={{borderRight: '1px solid #dadada'}}>
                        {this.props.data.bandings.map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`bandingsYardage.${index}`}
                                    name={`bandingsYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.bandingsYardage[index]  || ""
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 0).map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`cordingsPreMadeYardage.${index}`}
                                    name={`cordingsPreMadeYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.cordingsPreMadeYardage[index]  || ''
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 0).map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`flangesPreMadeYardage.${index}`}
                                    name={`flangesPreMadeYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.flangesPreMadeYardage[index] || ''
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 0).map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`trimsPreMadeYardage.${index}`}
                                    name={`trimsPreMadeYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.trimsPreMadeYardage[index] || ''
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 1).map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`cordingsFabricYardage.${index}`}
                                    name={`cordingsFabricYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.cordingsFabricYardage[index] || ''
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 1).map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`flangesFabricYardage.${index}`}
                                    name={`flangesFabricYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.flangesFabricYardage[index] || ''
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 1).map(
                          (content: any, index: any) => {
                            return content.draperyOrderFabric ? (
                              <WorkOrderNumber>
                                <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                  <CalculatorYardageInput
                                    id={`trimsFabricYardage.${index}`}
                                    name={`trimsFabricYardage.${index}`}
                                    type="number"
                                    value={
                                      this.state.trimsFabricYardage[index] || ''
                                    }
                                    onChange={
                                      this.setYardage
                                    }
                                    disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                  />
                                </CalculatorFieldInput>
                              </WorkOrderNumber>
                            ) : null;
                          }
                        )}

                        {this.props.data &&
                          this.props.data
                            .liningsList &&
                          this.props.data
                            .liningsList
                            .length > 0 &&
                          this.props.data
                            .liningsList[0] &&
                            (this.props.data
                              .liningsList[0]
                              .active || forBackValance) ? (
                            <WorkOrderNumber>
                              {this.props
                                .data &&
                                this.props
                                  .data
                                  .liningsList && (
                                  <>
                                    {this.props.data.liningsList.map(
                                      (
                                        item: any, index: any
                                      ) => {
                                        if (
                                          item.draperyOrderLiningsFabric &&
                                          item
                                            .draperyOrderLiningsFabric
                                            .name ===
                                          "LINING FABRIC"
                                        ) {

                                          return <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                            <CalculatorYardageInput
                                              id={`liningsYardage.${index}`}
                                              name={`liningsYardage.${index}`}
                                              type="number"
                                              value={
                                                this.state.liningsYardage[index] || ''
                                              }
                                              onChange={
                                                this.setYardage
                                              }
                                              disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                              uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                            />
                                          </CalculatorFieldInput>;
                                        }
                                      }
                                    )}
                                  </>
                                )}
                            </WorkOrderNumber>
                          ) : null}

                        {this.props.data &&
                          this.props.data
                            .liningsList &&
                          this.props.data
                            .liningsList
                            .length > 0 &&
                          this.props.data
                            .liningsList[1] &&
                          this.props.data
                            .liningsList[1]
                            .active && (
                            <WorkOrderNumber>
                              <>
                                {this.props.data.liningsList.map(
                                  (
                                    item: any, index: any
                                  ) => {
                                    if (
                                      item.draperyOrderLiningsFabric &&
                                      item
                                        .draperyOrderLiningsFabric
                                        .name ===
                                      "INTERLINING FABRIC"
                                    ) {
                                      interliningMaterialYardage =
                                        item
                                          .draperyOrderLiningsFabric
                                          .yardsNeeded;
                                      return <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                        <CalculatorYardageInput
                                          id={`liningsYardage.${index}`}
                                          name={`liningsYardage.${index}`}
                                          type="number"
                                          value={
                                            this.state.liningsYardage[index] || ''
                                          }
                                          onChange={
                                            this.setYardage
                                            // this.props
                                            //     .handleInputChangeForString
                                          }
                                          disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                          uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                        />
                                      </CalculatorFieldInput>;
                                    }
                                  }
                                )}
                              </>
                            </WorkOrderNumber>
                          )}
                        {this.props.data
                          .othersEmbellishments[0] && this.props.data.othersEmbellishments[0].draperyOrderFabric &&
                          this.props.data.othersEmbellishments.map(
                            (content: any, index: any) => {
                              return (
                                <WorkOrderNumber>
                                  <CalculatorFieldInput uiWidth={180} style={{ margin: '-24px auto 0px auto', border: '1px solid #b3b3b3', borderRadius: "0.5rem" }}>
                                    <CalculatorYardageInput
                                      id={`otherEmbellishmentsYardage.${index}`}
                                      name={`otherEmbellishmentsYardage.${index}`}
                                      type="number"
                                      value={
                                        this.state.otherEmbellishmentsYardage[index] || ''
                                      }
                                      onChange={
                                        this.setYardage
                                      }
                                      disabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                      uiDisabled={!(workOrderEditMode || isCreateMode) && this.props.workOrder.id !== 0}
                                    />
                                  </CalculatorFieldInput>
                                </WorkOrderNumber>
                              );
                            }
                          )}
                      </SummaryTableCell>

                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Unit Price ($)
                      </SummaryTableCell>

                      <SummaryTableCell text="fabrics">
                        {this.props.data &&
                          this.props.data
                            .mainFabric
                            .orderOrigin ===
                          2 ? (
                          <WorkOrderNumber>
                            COM
                          </WorkOrderNumber>
                        ) : (

                          <WorkOrderNumber>
                            {
                              this.props.data.fabricsPatternItems && this.props.data.mainFabric.pattern ? this.getPricingSectionPrice(this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === this.props.data
                                .mainFabric.pattern) || {}) : ""
                            }

                          </WorkOrderNumber>
                        )}
                        {this.props.data.bandings.map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let bandingUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern && this.props.data.fabricsPatternItems) {
                              const bandingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              bandingUnitPrice[index] = this.getPricingSectionPrice(bandingItem || {});
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .bandings
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .bandings[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  bandingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {bandingUnitPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      bandingUnitPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber>{""}</WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 0).map(
                          (
                            item: any,
                            index: number
                          ) => {
                            let cordingUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const cordingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              cordingUnitPrice[index] = this.getPricingSectionPrice(cordingItem || {})
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .cordings.filter((item: any) => item.subType === 0)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .cordings.filter((item: any) => item.subType === 0)[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {cordingUnitPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      cordingUnitPrice[
                                      index
                                      ]

                                    }{" "}
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber>{""}</WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 0).map(
                          (
                            item: any,
                            index: number
                          ) => {

                            let flangeUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const flangeItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              flangeUnitPrice[index] = this.getPricingSectionPrice(flangeItem || {});
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .flanges.filter((item: any) => item.subType === 0)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .flanges.filter((item: any) => item.subType === 0)[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  flangeUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }

                            return item.draperyOrderFabric ? (
                              <>
                                {flangeUnitPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      flangeUnitPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";

                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 0).map(
                          (
                            item: any,
                            index: number
                          ) => {
                            let trimUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const trimItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              trimUnitPrice[index] = this.getPricingSectionPrice(trimItem || {});
                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props.data
                              //     .trims.filter((item: any) => item.subType === 0)
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this.props.data
                              //       .trims.filter((item: any) => item.subType === 0)[
                              //       i
                              //     ].draperyOrderFabric
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //     trimUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {trimUnitPrice[
                                  index
                                ] && item.draperyOrderFabric.orderOrigin && item.draperyOrderFabric.orderOrigin === 1 ? (
                                  <WorkOrderNumber>
                                    {
                                      trimUnitPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber>{""}</WorkOrderNumber>}
                              </>
                            ) : "";

                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: number
                          ) => {
                            let cordingUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const cordingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              cordingUnitPrice[index] = this.getPricingSectionPrice(cordingItem || {});
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .cordings.filter((item: any) => item.subType === 1)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props.data
                                    .cordings.filter((item: any) => item.subType === 1)[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {cordingUnitPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      cordingUnitPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber>{""}</WorkOrderNumber>}
                              </>
                            ) : "";

                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: number
                          ) => {
                            let flangeUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const flangeItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              flangeUnitPrice[index] = this.getPricingSectionPrice(flangeItem || {});
                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .flanges.filter((item: any) => item.subType === 1)
                                  .length;
                                i++
                              ) {
                                if (this.props.data
                                  .flanges.filter((item: any) => item.subType === 1)[
                                  i
                                ].draperyOrderFabric
                                  .orderOrigin ===
                                  2
                                ) {
                                  flangeUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {flangeUnitPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      flangeUnitPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";

                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: number
                          ) => {
                            let trimUnitPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const trimItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              trimUnitPrice[index] = this.getPricingSectionPrice(trimItem || {});
                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props.data
                              //     .trims.filter((item: any) => item.subType === 1)
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this.props.data
                              //       .trims.filter((item: any) => item.subType === 1)[
                              //       i
                              //     ].draperyOrderFabric
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //     trimUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {trimUnitPrice[
                                  index
                                ] && item.draperyOrderFabric.orderOrigin && item.draperyOrderFabric.orderOrigin === 1 ? (
                                  <WorkOrderNumber>
                                    {
                                      trimUnitPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";

                          }
                        )}
                        {this.props.data &&
                          this.props.data
                            .liningsList &&
                          this.props.data.liningsList.filter((item: any, index: number) => {
                            if (index === 0) {
                              return item.active || forBackValance
                            } else {
                              return item.active
                            }
                          }).map(
                            (item: any) => {
                              const newItem =
                                item &&
                                item.draperyOrderLiningsFabric &&
                                item.draperyOrderLiningsFabric;
                              let liningUnitPrice;
                              if (item.draperyOrderLiningsFabric && item.draperyOrderLiningsFabric.pattern) {
                                const liningItem = this.props.data.liningsPatternItems && this.props.data.liningsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderLiningsFabric.pattern);
                                liningUnitPrice = this.getPricingSectionPrice(liningItem || {})
                              }
                              for (
                                let i = 0;
                                i <
                                this.props
                                  .data
                                  .liningsList.filter((item: any, index: number) => {
                                    if (index === 0) {
                                        return item.active || forBackValance
                                    } else {
                                        return item.active
                                    }
                                })
                                  .length;
                                i++
                              ) {
                                // if (
                                //   this.props
                                //     .data
                                //     .liningsList.filter((item: any, index: number) => {
                                //       if (index === 0) {
                                //           return item.active || forBackValance
                                //       } else {
                                //           return item.active
                                //       }
                                //   })[
                                //     i
                                //   ].draperyOrderLiningsFabric
                                //     .orderOrigin ===
                                //   2
                                // ) {
                                //   liningUnitPrice = undefined;
                                // }
                              return newItem ? (
                                <>
                                  {liningUnitPrice && item.draperyOrderLiningsFabric.orderOrigin !== 2 ? (
                                    <WorkOrderNumber>
                                      {
                                        liningUnitPrice
                                      }
                                    </WorkOrderNumber>
                                  ) : item.draperyOrderLiningsFabric.orderOrigin === 2 ? (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  ) : <WorkOrderNumber></WorkOrderNumber>}
                                </>
                              ) : "";
                              }
                            }
                          )}
                        {
                          this.props.data.othersEmbellishments.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let othersEmbellishmentsUnitPrice: any = [];
                              if (item.draperyOrderFabric && item.draperyOrderFabric) {
                                const otherEmbellishmentItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                                othersEmbellishmentsUnitPrice[index] = this.getPricingSectionPrice(otherEmbellishmentItem || {})
                                // for (
                                //   let i = 0;
                                //   i <
                                //   this.props
                                //     .data
                                //     .othersEmbellishments
                                //     .length;
                                //   i++
                                // ) {
                                //   if (
                                //     this
                                //       .props
                                //       .data
                                //       .othersEmbellishments[
                                //       i
                                //     ].draperyOrderFabric
                                //       .orderOrigin ===
                                //     2
                                //   ) {
                                //     othersEmbellishmentsUnitPrice.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //   }
                                // }
                              }
                              return item.draperyOrderFabric ? (
                                <>
                                  {othersEmbellishmentsUnitPrice[
                                    index
                                  ] && item.draperyOrderFabric.orderOrigin && item.draperyOrderFabric.orderOrigin === 1 ? (
                                    <WorkOrderNumber>
                                      {
                                        othersEmbellishmentsUnitPrice[
                                        index
                                        ]
                                      }
                                    </WorkOrderNumber>
                                  ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  ) : <WorkOrderNumber></WorkOrderNumber>}
                                </>
                              ) : "";

                            }
                          )}

                      </SummaryTableCell>

                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        S+H ($)
                      </SummaryTableCell>

                      <SummaryTableCell text="fabrics">
                        {this.props.data &&
                          this.props.data
                            .mainFabric
                            .orderOrigin ===
                          2 ? (
                          <WorkOrderNumber>
                            COM
                          </WorkOrderNumber>
                        ) : (
                          <WorkOrderNumber>
                            {

                                shippingPriceMainFabric
                            }
                          </WorkOrderNumber>
                        )}
                        {this.props.data.bandings.map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let bandingShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern && this.props.data.fabricsPatternItems) {
                              const bandingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              bandingShippingPrice[index] = bandingItem ? bandingItem.shippingCost || "0.00" : ""

                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .bandings
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .bandings[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  bandingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }

                            return item.draperyOrderFabric ? (
                              <>
                                {bandingShippingPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      bandingShippingPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 0).map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let cordingShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const cordingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              cordingShippingPrice[index] = cordingItem ? cordingItem.shippingCost || "0.00" : "";

                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .cordings.filter((item: any) => item.subType === 0)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .cordings.filter((item: any) => item.subType === 0)[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  cordingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {cordingShippingPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      cordingShippingPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 0).map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let flangeShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const flangeItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              flangeShippingPrice[index] = flangeItem ? flangeItem.shippingCost || "0.00" : ""

                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .flanges.filter((item: any) => item.subType === 0)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .flanges.filter((item: any) => item.subType === 0)[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  flangeShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {flangeShippingPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      flangeShippingPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 0).map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let trimShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const trimItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              trimShippingPrice[index] = trimItem ? trimItem.shippingCost || "0.00" : "";

                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props.data
                              //     .trims.filter((item: any) => item.subType === 0)
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this.props
                              //       .data
                              //       .trims.filter((item: any) => item.subType === 0)[
                              //       i
                              //     ].
                              //       draperyOrderFabric.orderOrigin ===
                              //     2
                              //   ) {
                              //       shippingPriceTrim.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {shippingPriceTrim[
                                  index
                                ] && item.draperyOrderFabric.orderOrigin && item.draperyOrderFabric.orderOrigin === 1 ? (
                                  <WorkOrderNumber>
                                    {
                                        shippingPriceTrim[
                                      index
                                      ].price

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.cordings.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let cordingShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const cordingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              cordingShippingPrice[index] = cordingItem ? cordingItem.shippingCost || "0.00" : "";

                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .cordings.filter((item: any) => item.subType === 1)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .cordings.filter((item: any) => item.subType === 1)[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  cordingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {cordingShippingPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      cordingShippingPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.flanges.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let flangeShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const flangeItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              flangeShippingPrice[index] = flangeItem ? flangeItem.shippingCost || "0.00" : "";

                              for (
                                let i = 0;
                                i <
                                this.props.data
                                  .flanges.filter((item: any) => item.subType === 1)
                                  .length;
                                i++
                              ) {
                                if (
                                  this.props
                                    .data
                                    .flanges.filter((item: any) => item.subType === 1)[
                                    i
                                  ].
                                    draperyOrderFabric.orderOrigin ===
                                  2
                                ) {
                                  flangeShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {flangeShippingPrice[
                                  index
                                ] ? (
                                  <WorkOrderNumber>
                                    {
                                      flangeShippingPrice[
                                      index
                                      ]

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data.trims.filter((item: any) => item.subType === 1).map(
                          (
                            item: any,
                            index: any
                          ) => {
                            let trimShippingPrice: any = [];
                            if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                              const trimItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                              trimShippingPrice[index] = trimItem ? trimItem.shippingCost || "0.00" : "";

                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props.data
                              //     .trims.filter((item: any) => item.subType === 1)
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this.props
                              //       .data
                              //       .trims.filter((item: any) => item.subType === 1)[
                              //       i
                              //     ].
                              //       draperyOrderFabric.orderOrigin ===
                              //     2
                              //   ) {
                              //       shippingPriceTrim.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                            }
                            return item.draperyOrderFabric ? (
                              <>
                                {shippingPriceTrim[
                                  index
                                ] && item.draperyOrderFabric.orderOrigin && item.draperyOrderFabric.orderOrigin === 1 ? (
                                  <WorkOrderNumber>
                                    {
                                      shippingPriceTrim[
                                      index
                                      ].price

                                    }
                                  </WorkOrderNumber>
                                ) : item.draperyOrderFabric.orderOrigin === 2 ? (
                                  <WorkOrderNumber>
                                    COM
                                  </WorkOrderNumber>
                                ) : <WorkOrderNumber></WorkOrderNumber>}
                              </>
                            ) : "";
                          }
                        )}
                        {this.props.data &&
                          this.props.data
                            .liningsList &&
                          this.props.data.liningsList.filter((item: any, index: number) => {
                            if (index === 0) {
                              return item.active || forBackValance
                            } else {
                              return item.active
                            }
                          }).map(
                            (item: any) => {
                              const newItem =
                                item &&
                            item.draperyOrderLiningsFabric &&
                            item.draperyOrderLiningsFabric;

                              return newItem ? (
                                <>
                                  {this
                                    .props
                                    .data &&
                                    this
                                      .props
                                      .data
                                      .liningsPatternItems &&
                                    item
                                      .draperyOrderLiningsFabric
                                      .orderOrigin ===
                                    2 ? (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  ) : (
                                    <WorkOrderNumber>
                                      {item.name === "Lining" ? shippingPriceLiningsFabric : shippingPriceInterliningsFabric}
                                    </WorkOrderNumber>
                                  )}
                                </>
                              ) : "";
                            }
                          )}
                        {this.props.data
                          .othersEmbellishments[0] && this.props.data.othersEmbellishments[0].draperyOrderFabric &&
                          this.props.data.othersEmbellishments.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let othersEmbellishmentsUnitPrice: any = [];
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                const otherEmbellishmentItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                                othersEmbellishmentsUnitPrice[index] = otherEmbellishmentItem ? otherEmbellishmentItem.shippingCost || "0.00" : "";
                                // for (
                                //   let i = 0;
                                //   i <
                                //   this.props
                                //     .data
                                //     .othersEmbellishments
                                //     .length;
                                //   i++
                                // ) {
                                //   if (
                                //     this
                                //       .props
                                //       .data
                                //       .othersEmbellishments[
                                //       i
                                //     ].draperyOrderFabric &&
                                //     this
                                //       .props
                                //       .data
                                //       .othersEmbellishments[
                                //       i
                                //     ].draperyOrderFabric
                                //       .orderOrigin ===
                                //     2
                                //   ) {
                                //       shippingOthersEmbellishments.splice(
                                //       i,
                                //       0,
                                //       undefined
                                //     );
                                //   }
                                // }
                              }
                              return (
                                <>
                                  {shippingOthersEmbellishments[
                                    index
                                  ] && item.draperyOrderFabric && item.draperyOrderFabric.orderOrigin === 1 ? (
                                    <WorkOrderNumber>
                                      {
                                        shippingOthersEmbellishments[
                                        index
                                        ].price

                                      }
                                    </WorkOrderNumber>
                                  ) : item.draperyOrderFabric && item.draperyOrderFabric.orderOrigin === 2 ? (
                                    <WorkOrderNumber>
                                      COM
                                    </WorkOrderNumber>
                                  ) :
                                    <WorkOrderNumber></WorkOrderNumber>}
                                </>
                              );

                            }
                          )}
                      </SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Total Price ($)
                      </SummaryTableCell>
                      <SummaryTableCell
                        text="fabrics"
                        last="last"
                      >
                        <WorkOrderNumber>
                          {this.props.data &&
                            this.props.data
                              .fabricsPatternItems && !["", null, undefined].includes(this.state.mainFabricYardage) ?
                            this.props.data
                              .mainFabric
                              .orderOrigin ===
                              1 && this.props.data.mainFabric.pattern && (this.props.data.mainFabric.patternId || this.props.data
                                .fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === this.props.data
                                  .mainFabric.pattern)) ? (
                              (
                                parseFloat(
                                  this.props.data.fabricsPatternItems && this.props.data.mainFabric.pattern ?
                                    this.getPricingSectionPrice(this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === this.props.data
                                      .mainFabric.pattern) || {}) || "0.00" : "0.00"
                                ) *
                                (this.state.mainFabricYardage !== "0" && this.state.mainFabricYardage !== null ? +this.state.mainFabricYardage : 1)
                                +
                                +shippingPriceMainFabric
                              ).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            ) : this.props.data
                              .mainFabric
                              .orderOrigin
                              !== 2 ? "" : (
                              "COM"

                            ) : this.props.data.mainFabric.orderOrigin !== 2 ? "TBD" :
                              "COM"
                          }
                        </WorkOrderNumber>

                        {this.props.data &&
                          this.props.data
                            .bandings
                          &&
                          this.props.data.bandings.map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let bandingShippingPrice: any = [];
                              let bandingUnitPrice: any = [];
                              let bandingItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                bandingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                                bandingUnitPrice[index] = this.getPricingSectionPrice(bandingItem || {});
                                bandingShippingPrice[index] = this.props.data.fabricsPatternItems
                                  && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern) ? this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern).shippingCost || "0.00" : "0.00"
                              };


                              for (
                                let i = 0;
                                i <
                                this.props
                                  .data
                                  .bandings
                                  .length;
                                i++
                              ) {
                                if (
                                  this
                                    .props
                                    .data
                                    .bandings[
                                    i
                                  ].draperyOrderFabric && this
                                    .props
                                    .data
                                    .bandings[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  bandingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                  bandingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.bandingsYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && bandingItem ? (
                                        (
                                          parseFloat(
                                            bandingUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.bandingsYardage[index] !== "0" ? +this.state.bandingsYardage[index] :
                                            1) +
                                          parseFloat(
                                            bandingShippingPrice[
                                            index
                                            ]

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}

                        {this.props.data &&
                          this.props.data
                            .cordings
                          &&
                          this.props.data.cordings.filter((item: any) => item.subType === 0).map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let cordingShippingPrice: any = [];
                              let cordingUnitPrice: any = [];
                              let cordingItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                cordingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);

                                cordingUnitPrice[index] = this.getPricingSectionPrice(cordingItem || {});
                                cordingShippingPrice[index] = this.props.data.fabricsPatternItems
                                  && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern) ? this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern).shippingCost || "0.00" : "0.00"
                              };


                              for (
                                let i = 0;
                                i <
                                this.props
                                  .data
                                  .cordings.filter((item: any) => item.subType === 0)
                                  .length;
                                i++
                              ) {
                                if (
                                  this
                                    .props
                                    .data
                                    .cordings.filter((item: any) => item.subType === 0)[
                                    i
                                  ].draperyOrderFabric && this
                                    .props
                                    .data
                                    .cordings.filter((item: any) => item.subType === 0)[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                  cordingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.cordingsPreMadeYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && cordingItem ? (
                                        (
                                          parseFloat(
                                            cordingUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.cordingsPreMadeYardage[index] !== "0" ? +this.state.cordingsPreMadeYardage[index] :
                                            1) +
                                          parseFloat(
                                            cordingShippingPrice[
                                            index
                                            ]

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    } </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                        {this.props.data &&
                          this.props.data
                            .flanges
                          &&
                          this.props.data.flanges.filter((item: any) => item.subType === 0).map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let flangeShippingPrice: any = [];
                              let flangeUnitPrice: any = [];
                              let flangeItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                flangeItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);

                                flangeUnitPrice[index] = this.getPricingSectionPrice(flangeItem || {});
                                flangeShippingPrice[index] = this.props.data.fabricsPatternItems
                                  && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern) ? this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern).shippingCost || "0.00" : "0.00"
                              };


                              for (
                                let i = 0;
                                i <
                                this.props
                                  .data
                                  .flanges.filter((item: any) => item.subType === 0)
                                  .length;
                                i++
                              ) {
                                if (
                                  this
                                    .props
                                    .data
                                    .flanges[
                                  i
                                  ] &&
                                  this
                                    .props
                                    .data
                                    .flanges[
                                    i
                                  ].draperyOrderFabric && this
                                    .props
                                    .data
                                    .flanges.filter((item: any) => item.subType === 0)[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  flangeShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                  flangeUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }
                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.flangesPreMadeYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && flangeItem ? (
                                        (
                                          parseFloat(
                                            flangeUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.flangesPreMadeYardage[index] !== "0" ? +this.state.flangesPreMadeYardage[index] :
                                            1) +
                                          parseFloat(
                                            flangeShippingPrice[
                                            index
                                            ]

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                        {this.props.data &&
                          this.props.data
                            .trims
                          &&
                          this.props.data.trims.filter((item: any) => item.subType === 0).map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let trimShippingPrice: any = [];
                              let trimUnitPrice: any = [];
                              let trimItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                trimItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);

                                trimUnitPrice[index] = this.getPricingSectionPrice(trimItem || {});
                                trimShippingPrice[index] = this.props.data.fabricsPatternItems
                                  && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern) ? this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern).shippingCost || "0.00" : "0.00";
                              }

                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props
                              //     .data
                              //     .trims.filter((item: any) => item.subType === 0)
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this
                              //       .props
                              //       .data
                              //       .flanges[
                              //     i
                              //     ] &&
                              //     this
                              //       .props
                              //       .data
                              //       .trims[
                              //       i
                              //     ].draperyOrderFabric && this
                              //       .props
                              //       .data
                              //       .trims.filter((item: any) => item.subType === 0)[
                              //       i
                              //     ].draperyOrderFabric
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //       shippingPriceTrim.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //     trimUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }

                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.trimsPreMadeYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && trimItem ? (
                                        (
                                          parseFloat(
                                            trimUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.trimsPreMadeYardage[index] !== "0" ? +this.state.trimsPreMadeYardage[index] :
                                            1) +
                                          parseFloat(
                                            shippingPriceTrim.filter((item: any) => item.subType === 0)[
                                            index
                                            ].price

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                        {this.props.data &&
                          this.props.data
                            .cordings
                          &&
                          this.props.data.cordings.filter((item: any) => item.subType === 1).map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let cordingShippingPrice: any = [];
                              let cordingUnitPrice: any = [];
                              let cordingItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                cordingItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);

                                cordingUnitPrice[index] = this.getPricingSectionPrice(cordingItem || {});
                                cordingShippingPrice[index] = this.props.data.fabricsPatternItems
                                  && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern) ? this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern).shippingCost || "0.00" : "0.00"
                              };


                              for (
                                let i = 0;
                                i <
                                this.props
                                  .data
                                  .cordings.filter((item: any) => item.subType === 1)
                                  .length;
                                i++
                              ) {
                                if (
                                  this
                                    .props
                                    .data
                                    .cordings.filter((item: any) => item.subType === 1)[
                                    i
                                  ].draperyOrderFabric && this
                                    .props
                                    .data
                                    .cordings[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  cordingShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                  cordingUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }

                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.cordingsFabricYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && cordingItem ? (
                                        (
                                          parseFloat(
                                            cordingUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.cordingsFabricYardage[index] !== "0" ? +this.state.cordingsFabricYardage[index] :
                                            1) +
                                          parseFloat(
                                            cordingShippingPrice[
                                            index
                                            ]

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                        {this.props.data &&
                          this.props.data
                            .flanges
                          &&
                          this.props.data.flanges.filter((item: any) => item.subType === 1).map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let flangeShippingPrice: any = [];
                              let flangeUnitPrice: any = [];
                              let flangeItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                flangeItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);

                                flangeUnitPrice[index] = this.getPricingSectionPrice(flangeItem || {});
                                flangeShippingPrice[index] = this.props.data.fabricsPatternItems
                                  && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern) ? this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern).shippingCost || "0.00" : "0.00";
                              }

                              for (
                                let i = 0;
                                i <
                                this.props
                                  .data
                                  .flanges.filter((item: any) => item.subType === 1)
                                  .length;
                                i++
                              ) {
                                if (
                                  this
                                    .props
                                    .data
                                    .flanges[
                                    i
                                  ].draperyOrderFabric && this
                                    .props
                                    .data
                                    .flanges.filter((item: any) => item.subType === 1)[
                                    i
                                  ].draperyOrderFabric
                                    .orderOrigin ===
                                  2
                                ) {
                                  flangeShippingPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                  flangeUnitPrice.splice(
                                    i,
                                    0,
                                    undefined
                                  );
                                }
                              }

                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.flangesFabricYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && flangeItem ? (
                                        (
                                          parseFloat(
                                            flangeUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.flangesFabricYardage[index] !== "0" ? +this.state.flangesFabricYardage[index] :
                                            1) +
                                          parseFloat(
                                            flangeShippingPrice[
                                            index
                                            ]

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                        {this.props.data &&
                          this.props.data
                            .trims
                          &&
                          this.props.data.trims.filter((item: any) => item.subType === 1).map(
                            (
                              item: any,
                              index: number
                            ) => {
                              let trimUnitPrice: any = [];
                              let trimItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                trimItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                                trimUnitPrice[index] = this.getPricingSectionPrice(trimItem || {});
                              }

                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props
                              //     .data
                              //     .trims.filter((item: any) => item.subType === 1)
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this
                              //       .props
                              //       .data
                              //       .trims.filter((item: any) => item.subType === 1)[
                              //       i
                              //     ].draperyOrderFabric && this
                              //       .props
                              //       .data
                              //       .trims.filter((item: any) => item.subType === 1)[
                              //       i
                              //     ].draperyOrderFabric
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //       shippingPriceTrim.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //     trimUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }

                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.trimsFabricYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && trimItem ? (
                                        (
                                          parseFloat(
                                            trimUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.trimsFabricYardage[index] !== "0" ? +this.state.trimsFabricYardage[index] :
                                            1) +
                                          parseFloat(
                                            shippingPriceTrim[
                                            index
                                            ].price

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                        {this.props.data &&
                          this.props.data.liningsList.filter((item: any, index: number) => {
                            if (index === 0) {
                              return item.active || forBackValance
                            } else {
                              return item.active
                            }
                          }).map(
                            (item: any, index: any) => {
                              item.draperyOrderLiningsFabric &&
                                item.draperyOrderLiningsFabric;
                              let liningUnitPrice: any;
                              let liningItem: any;
                              if (item.draperyOrderLiningsFabric && item.draperyOrderLiningsFabric.pattern) {
                                liningItem = this.props.data.liningsPatternItems && this.props.data.liningsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderLiningsFabric.pattern);
                                liningUnitPrice = this.getPricingSectionPrice(liningItem || {});
                              }

                              return item.draperyOrderLiningsFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {item.name === "Lining" ? this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.liningsYardage[index]) ?
                                      item.draperyOrderLiningsFabric
                                        .orderOrigin ===
                                        1 && liningItem ? (
                                        (
                                          parseFloat(
                                            liningUnitPrice
                                          ) *
                                          (this.state.liningsYardage[0] !== "0" ? +this.state.liningsYardage[index] :
                                            1) +
                                          parseFloat(
                                            shippingPriceLiningsFabric

                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderLiningsFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderLiningsFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM" :
                                      this.props.data &&
                                        this.props.data
                                          .fabricsPatternItems && !["", null, undefined].includes(this.state.liningsYardage[index]) ?
                                        item.draperyOrderLiningsFabric
                                          .orderOrigin ===
                                          1 && liningItem ? (
                                          (
                                            parseFloat(
                                              liningUnitPrice
                                            ) *
                                            (this.state.liningsYardage[index] !== "0" ? +this.state.liningsYardage[index] :
                                              1) +
                                            parseFloat(
                                              shippingPriceInterliningsFabric
                                            )
                                          ).toLocaleString(
                                            "en-US",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )
                                        ) : item.draperyOrderLiningsFabric.orderOrigin !== 2 ? "" : (
                                          "COM"

                                        ) : item.draperyOrderLiningsFabric.orderOrigin !== 2 ? "TBD" :
                                          "COM"
                                    }

                                  </WorkOrderNumber>
                                </div>
                              ) : null
                            }
                          )}
                        {this.props.data &&
                          this.props.data
                            .othersEmbellishments &&
                          this.props.data
                            .othersEmbellishments[0] &&
                          this.props.data.othersEmbellishments.map(
                            (
                              item: any,
                              index: number
                            ) => {

                              let otherEmbellishmentUnitPrice: any = [];
                              let otherEmbellishmentShippingPrice: any = [];
                              let otherEmbellishmentItem: any;
                              if (item.draperyOrderFabric && item.draperyOrderFabric.pattern) {
                                // alert()
                                otherEmbellishmentItem = this.props.data.fabricsPatternItems && this.props.data.fabricsPatternItems.find((fabricPattern: any) => fabricPattern.name === item.draperyOrderFabric.pattern);
                                otherEmbellishmentUnitPrice[index] = this.getPricingSectionPrice(otherEmbellishmentItem || {});
                                otherEmbellishmentShippingPrice[index] = otherEmbellishmentItem ? otherEmbellishmentItem.shippingCost || "0.00" : "";
                              }
                              // const othersEmbellishmentsUnitPrice =
                              //     [
                              //         ...this
                              //             .state
                              //             .prices
                              //             .othersEmbellishmentsUnitPrice,
                              //     ];
                              // const othersEmbellishmentsShippingPrice =
                              //     [
                              //         ...this
                              //             .state
                              //             .prices
                              //             .othersEmbellishmentsShippingPrice,
                              //     ];
                              // for (
                              //   let i = 0;
                              //   i <
                              //   this.props
                              //     .data
                              //     .othersEmbellishments
                              //     .length;
                              //   i++
                              // ) {
                              //   if (
                              //     this
                              //       .props
                              //       .data
                              //       .othersEmbellishments[
                              //       i
                              //     ]
                              //       .orderOrigin ===
                              //     2
                              //   ) {
                              //     otherEmbellishmentUnitPrice.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //     shippingOthersEmbellishments.splice(
                              //       i,
                              //       0,
                              //       undefined
                              //     );
                              //   }
                              // }
                              return item.draperyOrderFabric ? (
                                <div>
                                  <WorkOrderNumber>
                                    {this.props.data &&
                                      this.props.data
                                        .fabricsPatternItems && !["", null, undefined].includes(this.state.otherEmbellishmentsYardage[index]) ?
                                      item.draperyOrderFabric
                                        .orderOrigin ===
                                        1 && otherEmbellishmentItem ? (
                                        (
                                          parseFloat(
                                            otherEmbellishmentUnitPrice[
                                            index
                                            ]
                                          ) *
                                          (this.state.otherEmbellishmentsYardage[index] !== "0" ? +this.state.otherEmbellishmentsYardage[index] :
                                            1) +
                                          parseFloat(
                                            shippingOthersEmbellishments[
                                            index
                                            ].price
                                          )
                                        ).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "" : (
                                        "COM"

                                      ) : item.draperyOrderFabric.orderOrigin !== 2 ? "TBD" :
                                        "COM"
                                    }
                                  </WorkOrderNumber>
                                </div>
                              ) : null;
                            }
                          )}
                      </SummaryTableCell>



                    </SummaryTableCol>
                  </SummaryTable>
                </HContainer>
              ) : (
                <HContainer text="fabrics">
                  <SummaryTable>
                    <SummaryTableCol
                      text="fabrics"
                      color="header"
                    >
                      <SummaryTableCell
                        className="fabricHeader"
                        text="title"
                        style={{
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Fabrics
                      </SummaryTableCell>
                      <SummaryTableCell></SummaryTableCell>
                      <SummaryTableCell></SummaryTableCell>
                      <SummaryTableCell></SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Yardage
                      </SummaryTableCell>
                    </SummaryTableCol>
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Unit Price ($)
                      </SummaryTableCell>
                    </SummaryTableCol>{" "}
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        className="fabricHeader"
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        S+H ($)
                      </SummaryTableCell>
                    </SummaryTableCol>{" "}
                    <SummaryTableCol text="not-fabrics">
                      <SummaryTableCell
                        position="center"
                        style={{
                          whiteSpace: "nowrap",
                          fontSize:
                            window.innerWidth < 992
                              ? "10px"
                              : "inherit",
                        }}
                      >
                        Total Price ($)
                      </SummaryTableCell>
                    </SummaryTableCol>
                  </SummaryTable>
                </HContainer>
              )}
            </SummaryInfoBlock>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
                uiTimeStatusChecked={
                  !!draperyOrderSummary.estimateJson.num
                }
              >
                ESTIMATE
              </DraperyHeader>
              <SummaryTable>
                <SummaryTableCol>
                  <SummaryTableCell>Number</SummaryTableCell>
                  <SummaryTableCell>
                    <CalculatorFieldInput uiWidth={100}>
                      <CalculatorInput
                        id="draperyOrderSummary.estimateJson.num"
                        name="draperyOrderSummary.estimateJson.num"
                        type="text"
                        value={
                          draperyOrderSummary
                            .estimateJson.num || ""
                        }
                        onChange={
                          this.props
                            .handleInputChangeForString
                        }
                        uiDisabled={
                          this.props.draperyOrder
                            .archived
                        }
                      />
                    </CalculatorFieldInput>
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Description
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <CalculatorFieldInput uiWidth={200}>
                      <CalculatorInput
                        id="draperyOrderSummary.estimateJson.description"
                        name="draperyOrderSummary.estimateJson.description"
                        type="text"
                        value={
                          draperyOrderSummary
                            .estimateJson
                            .description || ""
                        }
                        onChange={
                          this.props
                            .handleInputChangeForString
                        }
                        uiDisabled={
                          this.props.draperyOrder
                            .archived
                        }
                      />
                    </CalculatorFieldInput>
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Date Sent
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <DatePicker
                      selected={datesForPicker.dateSent}
                      onChange={(v: Date | null) =>
                        this.props.handleChangeDates(
                          v,
                          "draperyOrderSummary.estimateJson.dateSent"
                        )
                      }
                      locale={"en"}
                      isClearable={
                        !this.props.draperyOrder
                          .archived
                      }
                      placeholderText="MM/DD/YYYY"
                      className="custom_date_picker"
                      disabled={
                        this.props.draperyOrder.archived
                      }
                    />
                  </SummaryTableCell>
                </SummaryTableCol>
              </SummaryTable>
            </SummaryInfoBlock>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
                uiTimeStatusChecked={
                  !!draperyOrderSummary.invoiceJson.num
                }
              >
                INVOICE
              </DraperyHeader>
              <SummaryTable>
                <SummaryTableCol>
                  <SummaryTableCell>Number</SummaryTableCell>
                  <SummaryTableCell>
                    <CalculatorFieldInput uiWidth={100}>
                      <CalculatorInput
                        id="draperyOrderSummary.invoiceJson.num"
                        name="draperyOrderSummary.invoiceJson.num"
                        type="text"
                        value={
                          draperyOrderSummary
                            .invoiceJson.num || ""
                        }
                        onChange={
                          this.props
                            .handleInputChangeForString
                        }
                        uiDisabled={
                          this.props.draperyOrder
                            .archived
                        }
                      />
                    </CalculatorFieldInput>
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Description
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <CalculatorFieldInput uiWidth={200}>
                      <CalculatorInput
                        id="draperyOrderSummary.invoiceJson.description"
                        name="draperyOrderSummary.invoiceJson.description"
                        type="text"
                        value={
                          draperyOrderSummary
                            .invoiceJson
                            .description || ""
                        }
                        onChange={
                          this.props
                            .handleInputChangeForString
                        }
                        uiDisabled={
                          this.props.draperyOrder
                            .archived
                        }
                      />
                    </CalculatorFieldInput>
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>Paid</SummaryTableCell>
                  <SummaryTableCell>
                    <CalculatorFieldInput uiWidth={100}>
                      <SelectList
                        name="draperyOrderSummary.invoiceJson.paid"
                        selectList={paidList}
                        handleClickSelect={
                          this.props
                            .handleSelectListChange
                        }
                        valueSelect={
                          draperyOrderSummary
                            .invoiceJson.paid
                        }
                        flexDirection="row"
                        smallPadding
                        noClearBox
                        uiDropArrow
                        disabled={
                          this.props.draperyOrder
                            .archived
                        }
                      />
                    </CalculatorFieldInput>
                  </SummaryTableCell>
                </SummaryTableCol>
              </SummaryTable>
            </SummaryInfoBlock>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
                uiTimeStatusChecked={componentChecked}
              >
                COMPONENTS NEEDED
              </DraperyHeader>

              <SummaryComponentTable>
                <SummaryComponentTableRow>
                  <SummaryComponentTableCell uiFlex={1}>
                    Item
                  </SummaryComponentTableCell>
                  <SummaryComponentTableCell uiFlex={1}>
                    PO #
                  </SummaryComponentTableCell>
                  <SummaryComponentTableCell uiFlex={1.5}>
                    Ordered
                  </SummaryComponentTableCell>
                  <SummaryComponentTableCell uiFlex={1.5}>
                    ETA
                  </SummaryComponentTableCell>
                  <SummaryComponentTableCell uiFlex={1.5}>
                    Received
                  </SummaryComponentTableCell>
                  <SummaryComponentTableCell uiFlex={1}>
                    Notes
                  </SummaryComponentTableCell>
                </SummaryComponentTableRow>

                {this.renderComponentRow(
                  mainFabric.draperyOrderSummaryComponent,
                  `mainFabric`,
                  `mainFabric.draperyOrderSummaryComponent`
                )}
                {embellishmentsList[0].checked &&
                  bandings.length !== 0 &&
                  bandings.map((el, idx) => {
                    if (!el.draperyOrderFabric) {
                      return "";
                    }
                    return this.renderComponentRow(
                      el.draperyOrderFabric
                        .draperyOrderSummaryComponent,
                      `bandings.${idx}`,
                      `bandings.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                    );
                  })}
                {embellishmentsList[1].checked &&
                  cordings.length !== 0 &&
                  cordings.map((el, idx) => {
                    if (!el.draperyOrderFabric) {
                      return "";
                    }

                    return this.renderComponentRow(
                      el.draperyOrderFabric
                        .draperyOrderSummaryComponent,
                      `cordings.${idx}`,
                      `cordings.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                    );
                  })}
                {embellishmentsList[2].checked &&
                  flanges.length !== 0 &&
                  flanges.map((el, idx) => {
                    if (!el.draperyOrderFabric) {
                      return "";
                    }
                    return this.renderComponentRow(
                      el.draperyOrderFabric
                        .draperyOrderSummaryComponent,
                      `flanges.${idx}`,
                      `flanges.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                    );
                  })}
                {embellishmentsList[3].checked &&
                  trims.length !== 0 &&
                  trims.map((el, idx) => {
                    if (!el.draperyOrderFabric) {
                      return "";
                    }
                    return this.renderComponentRow(
                      el.draperyOrderFabric
                        .draperyOrderSummaryComponent,
                      `trims.${idx}`,
                      `trims.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                    );
                  })}
                {embellishmentsList[4].checked &&
                  othersEmbellishments.length !== 0 &&
                  othersEmbellishments.map((el, idx) => {
                    if (!el.draperyOrderFabric) {
                      return "";
                    }
                    return this.renderComponentRow(
                      el.draperyOrderFabric
                        .draperyOrderSummaryComponent,
                      `othersEmbellishments.${idx}`,
                      `othersEmbellishments.${idx}.draperyOrderFabric.draperyOrderSummaryComponent`
                    );
                  })}
                {this.renderComponentRow(headRail.draperyOrderSummaryComponent, 'headRail', 'headRail.draperyOrderSummaryComponent')}
                {draperyOrderSummaryComponents.map(
                  (component, id) =>
                    this.renderComponentRow(component, id)
                )}
              </SummaryComponentTable>
              <AddSummaryComponentBtn
                uiColor="primary"
                type="button"
                onClick={this.props.handleAddSummaryComponent}
                uiDisabled={this.props.draperyOrder.archived}
              >
                Add more
              </AddSummaryComponentBtn>
            </SummaryInfoBlock>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
                uiTimeStatusChecked={
                  !!draperyOrderSummary.productionJson
                    .finishedDate
                }
              >
                PRODUCTION
              </DraperyHeader>
              <SummaryTable>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Start Date
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <DatePicker
                      selected={datesForPicker.startDate}
                      onChange={(v: Date | null) =>
                        this.props.handleChangeDates(
                          v,
                          "draperyOrderSummary.productionJson.startDate"
                        )
                      }
                      locale={"en"}
                      isClearable={
                        !this.props.draperyOrder
                          .archived
                      }
                      placeholderText="MM/DD/YYYY"
                      className="custom_date_picker"
                      disabled={
                        this.props.draperyOrder.archived
                      }
                    />
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Due Date
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <DatePicker
                      selected={datesForPicker.dueDate}
                      onChange={(v: Date | null) =>
                        this.props.handleChangeDates(
                          v,
                          "draperyOrderSummary.productionJson.dueDate"
                        )
                      }
                      locale={"en"}
                      isClearable={
                        !this.props.draperyOrder
                          .archived
                      }
                      placeholderText="MM/DD/YYYY"
                      className="custom_date_picker"
                      disabled={
                        this.props.draperyOrder.archived
                      }
                    />
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Finished Date
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <DatePicker
                      selected={
                        datesForPicker.finishedDate
                      }
                      onChange={(v: Date | null) =>
                        this.props.handleChangeDates(
                          v,
                          "draperyOrderSummary.productionJson.finishedDate"
                        )
                      }
                      locale={"en"}
                      isClearable={
                        !this.props.draperyOrder
                          .archived
                      }
                      placeholderText="MM/DD/YYYY"
                      className="custom_date_picker"
                      disabled={
                        this.props.draperyOrder.archived
                      }
                    />
                  </SummaryTableCell>
                </SummaryTableCol>
              </SummaryTable>
            </SummaryInfoBlock>
            <SummaryInfoBlock>
              <DraperyHeader
                noMarginTop
                uiTimeStatus
                uiTimeStatusChecked={
                  !!draperyOrderSummary.installationJson
                    .dateOfInstall
                }
              >
                INSTALLATION
              </DraperyHeader>
              <SummaryTable>
                <SummaryTableCol>
                  <SummaryTableCell>Order #</SummaryTableCell>
                  <SummaryTableCell>
                    <CalculatorFieldInput uiWidth={100}>
                      <CalculatorInput
                        type="text"
                        id="draperyOrderSummary.installationJson.orderNum"
                        name="draperyOrderSummary.installationJson.orderNum"
                        value={
                          draperyOrderSummary
                            .installationJson
                            .orderNum || ""
                        }
                        onChange={
                          this.props
                            .handleInputChangeForString
                        }
                        uiDisabled={
                          this.props.draperyOrder
                            .archived
                        }
                      />
                    </CalculatorFieldInput>
                  </SummaryTableCell>
                </SummaryTableCol>
                <SummaryTableCol>
                  <SummaryTableCell>
                    Date of Install
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <DatePicker
                      selected={
                        datesForPicker.installDate
                      }
                      onChange={(v: Date | null) =>
                        this.props.handleChangeDates(
                          v,
                          "draperyOrderSummary.installationJson.dateOfInstall"
                        )
                      }
                      locale={"en"}
                      isClearable={
                        !this.props.draperyOrder
                          .archived
                      }
                      placeholderText="MM/DD/YYYY"
                      className="custom_date_picker"
                      disabled={
                        this.props.draperyOrder.archived
                      }
                    />
                  </SummaryTableCell>
                </SummaryTableCol>
              </SummaryTable>
            </SummaryInfoBlock>
          </SummaryContent>
        </SummaryContainer>
      </>
    );
  }

  public renderComponentRow(
    component: DraperyModule.DraperyOrderSummaryComponent,
    id: number | string,
    targetName?: string
  ) {
    const componentOrderedDate = !component.orderedDate
      ? null
      : moment(component.orderedDate).isValid()
        ? moment(component.orderedDate).toDate()
        : null;

    const componentETADate = !component.eta
      ? null
      : moment(component.eta).isValid()
        ? moment(component.eta).toDate()
        : null;

    const componentReceivedDate = !component.receivedDate
      ? null
      : moment(component.receivedDate).isValid()
        ? moment(component.receivedDate).toDate()
        : null;
    return (
      <SummaryComponentTableRow key={id}>
        <SummaryComponentTableCell uiFlex={1}>
          {targetName ? (
            (component.item ? component.item.toUpperCase() : '')
          ) : (
            <CalculatorFieldInput uiWidth={100}>
              <CalculatorInput
                type="text"
                id={`draperyOrderSummaryComponents.${id}.item`}
                name={`draperyOrderSummaryComponents.${id}.item`}
                value={component.item || ""}
                onChange={this.props.handleInputChangeForString}
                uiDisabled={this.props.draperyOrder.archived}
              />
            </CalculatorFieldInput>
          )}
        </SummaryComponentTableCell>
        <SummaryComponentTableCell uiFlex={1}>
          <CalculatorFieldInput uiWidth={100}>
            <CalculatorInput
              type="text"
              id={
                targetName
                  ? `${targetName}.po`
                  : `draperyOrderSummaryComponents.${id}.po`
              }
              name={
                targetName
                  ? `${targetName}.po`
                  : `draperyOrderSummaryComponents.${id}.po`
              }
              value={component.po || ""}
              onChange={this.props.handleInputChangeForString}
              uiDisabled={this.props.draperyOrder.archived}
            />
          </CalculatorFieldInput>
        </SummaryComponentTableCell>
        <SummaryComponentTableCell uiFlex={1.5}>
          <DatePicker
            className="w-full custom_date_picker"
            selected={componentOrderedDate}
            isClearable={!this.props.draperyOrder.archived}
            placeholderText="MM/DD/YYYY"
            onChange={(v: Date | null) =>
              this.props.handleChangeDates(
                v,
                targetName
                  ? `${targetName}.orderedDate`
                  : `draperyOrderSummaryComponents.${id}.orderedDate`
              )
            }
            disabled={this.props.draperyOrder.archived}
          />
        </SummaryComponentTableCell>
        <SummaryComponentTableCell uiFlex={1.5}>
          <DatePicker
            className="w-full custom_date_picker"
            selected={componentETADate}
            isClearable={!this.props.draperyOrder.archived}
            placeholderText="MM/DD/YYYY"
            onChange={(v: Date | null) =>
              this.props.handleChangeDates(
                v,
                targetName
                  ? `${targetName}.eta`
                  : `draperyOrderSummaryComponents.${id}.eta`
              )
            }
            disabled={this.props.draperyOrder.archived}
          />
        </SummaryComponentTableCell>
        <SummaryComponentTableCell uiFlex={1.5}>
          <DatePicker
            className="w-full custom_date_picker"
            selected={componentReceivedDate}
            isClearable={!this.props.draperyOrder.archived}
            placeholderText="MM/DD/YYYY"
            onChange={(v: Date | null) =>
              this.props.handleChangeDates(
                v,
                targetName
                  ? `${targetName}.receivedDate`
                  : `draperyOrderSummaryComponents.${id}.receivedDate`
              )
            }
            disabled={this.props.draperyOrder.archived}
          />
        </SummaryComponentTableCell>
        <SummaryComponentTableCell uiFlex={1}>
          <CalculatorFieldInput uiWidth={100}>
            <CalculatorInput
              type="text"
              id={
                targetName
                  ? `${targetName}.notes`
                  : `draperyOrderSummaryComponents.${id}.notes`
              }
              name={
                targetName
                  ? `${targetName}.notes`
                  : `draperyOrderSummaryComponents.${id}.notes`
              }
              value={component.notes || ""}
              onChange={this.props.handleInputChangeForString}
              uiDisabled={this.props.draperyOrder.archived}
            />
          </CalculatorFieldInput>
          {typeof id === "number" && (
            <DeleteBtn
              disabled={this.props.draperyOrder.archived}
              uiDisabled={this.props.draperyOrder.archived}
              uiColor="primary"
              type="button"
              onClick={() =>
                this.props.handleDeleteSummaryComponent(id)
              }
            >
              &times;
            </DeleteBtn>
          )}
        </SummaryComponentTableCell>
      </SummaryComponentTableRow>
    );
  }
}

export default RomanShadeSummaryTab;