import {
    // AddLiningButton,
    CalculatorCheckbox,
    CalculatorExtraDiv,
    CalculatorFieldInput,
    CalculatorFormField,
    CalculatorFormFieldHeader,
    CalculatorFormFieldLabel,
    CalculatorFormLabel,
    CalculatorFormQuestionNumber,
    CalculatorImageRadio,
    CalculatorImageRadioImg,
    CalculatorInput,
    CalculatorRadio,
    CalculatorRadioGroup,
    CalculatorRadioGroupWrap,
    CalculatorRadioWrapper,
    CalculatorRadioWrapperWithImg,
    CheckboxInputWrap,
    DraperyHeader,
    DrawingContainer,
    FooterCancelBtn,
    HContainer,
    NoteLetters,
    NotesContainer,
    PrependAppendInput,
    PrependAppendInputGroup,
    PrependAppendInputGroupLabel,
    RegularInputGroup,
    RegularInputGroupLabel,
    TooltipContainer,
    TooltipHeader,
    TooltipPopup,
    TooltipText,
    RadioLabel,
    RadioInput,
} from "./styles";
import { DraperyModule, WorkroomSettingModule } from "../../redux";
import {
    DraperyOrderDesignEmbellishment,
    DraperyOrderFabric,
    EmbellishmentCheckInfo,
    highlightStyle,
} from "./calculator";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import React, { ChangeEvent, Component, Dispatch } from "react";
import SelectList from "../../components/UI/select-list";
import {
    FivePanelsSvg,
    FourPanelsSvg,
    LeftSvg,
    PairSvg,
    RightSvg,
    SingleSvg,
    ThreePanelsSvg,
} from "../UI/drawing/bk_svgs";
import AdditionalForm from "./additional-form";
import { Config } from "../../utils";
import DraperyConfiguration3Panels from "../../assets/icons/configuration/panels-3.svg";
import DraperyConfiguration4Panels from "../../assets/icons/configuration/panels-4.svg";
import DraperyConfiguration5Panels from "../../assets/icons/configuration/panels-5.svg";
import DraperyConfigurationPair from "../../assets/icons/configuration/panels-pair.svg";
import DraperyConfigurationSingleCenterPanel from "../../assets/icons/configuration/panels_single.svg";
import DraperyConfigurationSingleLeftPanel from "../../assets/icons/configuration/panels-left.svg";
import DraperyConfigurationSingleRightPanel from "../../assets/icons/configuration/panels-right.svg";
import DraperyTypeCafeCurtain from "../../assets/icons/drapery_type/cafe.svg";
import DraperyTypeFullLength from "../../assets/icons/drapery_type/full_length.svg";
import { DrapperyOrderWorkroomSettings } from "./customize-construction";
import Drawing from "../UI/drawing/drawing";
import HeadingTypeRodRings from "../../assets/images/heading_type/rodd_rings.png";
import HeadingTypeRipplefold from "../../assets/images/heading_type/ripplefold.png";
import PleatTypeEuro2Finger from "../../assets/icons/pleats/Euro 2 Finger 133x70-01.svg";
import PleatTypeEuro3Finger from "../../assets/icons/pleats/Euro 3b Finger 133x70-01.svg";
import PleatTypeGoblet from "../../assets/icons/pleats/Goblet 133x70-01.svg";
import PleatTypeInverted from "../../assets/icons/pleats/Inverted 133x70-01.svg";
import PleatTypeNoneFlat from "../../assets/icons/pleats/None Flat 133x70-01.svg";
import PleatTypePinch2Finger from "../../assets/icons/pleats/Pinch 2 Finger 133x70-01.svg";
import PleatTypePinch3Finger from "../../assets/icons/pleats/Pinch 3 Finger 133x70-01.svg";
import { TextArea } from "@ramble/ramble-ui";
import { ValidationErrorInfo } from "./interfaces-common";
import { duplicateFabric } from "./fabrics-tab";
import styled from "../../theme";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { DraperyItemDetailsFields } from "../../redux/modules/my-shop/drapery-item-details-fields-reducer";
// import AddButton from "../UI/buttons/add-button";
// import PleatModal from "../my-shop/pleat-modal/pleat-modal";
import { FormFieldsAction } from "../../redux/modules/my-shop/form-fields-reducer";

export const viewBox = {
    x: 0,
    y: 20,
    width: 500,
    height: 200,
};

export const defaultDraperyOrderDesign =
    (): DraperyModule.DraperyOrderDesign => ({
        id: 0,
        category: -1,
        panelType: "",
        numOfPanels: 1,
        panelJson: defaultWidthEachPanelList(),
        draperyType: "",
        draperyFullness: 0,
        headingType: "",
        pleatType: "",
        ripplefoldOptions: defaultRipplefoldOptions(),
        isPinSet: false,
        isWeight: true,
        weightRadio: "standard-corner",
        otherPleatImageId: undefined,
        finishedLengthOfPanels: -1,
        drawing: "",
        notes: "",
        drawingShapeJson: [],
        customIsYardageAddOneExtra: false,
        customIsYardageAddExcess: false,
        buckram: "",
        customEmbellishmentJson: {
            ...WorkroomSettingModule.defaultWorkroomPresetEmbellishmentJson,
        },
        customHEMSJson: {
            ...WorkroomSettingModule.defaultWorkroomPresetHEMSJson,
        },
        customReturnOverlapJson: {
            ...WorkroomSettingModule.defaultWorkroomPresetReturnOverlapJson,
        },
        customCutLengthJson: {
            ...WorkroomSettingModule.defaultWorkroomPresetCutLengthJson,
        },
        customDraperyFullnessJson: {
            ...WorkroomSettingModule.defaultWorkroomPresetDraperyFullnessJson,
        },
        isNoneLinings: false,
        isNoneEmbellishment: false,
        draperyOrderDesignEmbellishments: [],
        draperyOrderDesignLinings: [],
        description: '',
        romanShadeJson: {
            style: {
                type: '',
                function: -1,
                windowMount: -1,
                boardMount: -1,
            },
            dimensions: {
                finishedWidth: null,
                finishedLength: null,
                foldSize: null,
                numOfPermanentFolds: null,
                bottomSkirit: -1,
                bottomSkirtLength: null,
            },
            valanceAndReturns: {
                frontValance: -1, 
                frontValanceLength: null,
                sides: null,
                sidesValanceLength: null,
                backValance: -1, 
                backValanceLength: null,
            },
            headrail: {
                liftSystem: null,
                boardFace: null,
                boardDepth: null,
                controlType: null,
                controlLocationLeft: null,
                controlLocationRight: null,
            }
        },
    });

export interface DefaultRipplefoldOptions {
    controlType: string | undefined | null;
    controlLocation: string | undefined | null;
    masterCarrier: string | undefined | null;
    mount: string | undefined | null;
    headerTape: string | undefined | null;
}

export const defaultRipplefoldOptions = (): DefaultRipplefoldOptions => {
    return {
        controlType: null,
        controlLocation: null,
        masterCarrier: null,
        mount: null,
        headerTape: null,
    };
};

export const defaultWidthEachPanelList =
    (): DraperyModule.DraperyOrderDesignPanelJson => ({});

export const draperyLiningsList =
    (): DraperyModule.DraperyOrderDesignLining[] => [
        {
            id: 0,
            name: "Lining",
            active: false,
            description: "",
            draperyOrderDesignId: null,
        },
        {
            id: 0,
            name: "Interlining",
            active: false,
            description: "",
            draperyOrderDesignId: null,
        },
    ];

export const defaultEmbellishmentsList = (): EmbellishmentCheckInfo[] => [
    { checked: false, title: "Banding", stateName: "bandings" },
    { checked: false, title: "Cording", stateName: "cordings" },
    { checked: false, title: "Flange", stateName: "flanges" },
    { checked: false, title: "Trim", stateName: "trims" },
    { checked: false, title: "Other", stateName: "othersEmbellishments" },
];

export const defaultEmbellishment = (
    targetArray: string
): DraperyOrderDesignEmbellishment | undefined => {
    switch (targetArray) {
        case "bandings": {
            return defaultBanding();
        }
        case "cordings": {
            return defaultCording();
        }
        case "flanges": {
            return defaultFlange();
        }
        case "trims": {
            return defaultTrim();
        }
        case "othersEmbellishments": {
            return defaultOtherEmbellishment();
        }
    }
};

export const defaultBanding = (): DraperyOrderDesignEmbellishment => ({
    id: 0,
    name: "",
    type: 1,
    leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
    topEdge: { active: false, insetActive: false, insetSize: "" },
    bottomEdge: { active: false, insetActive: false, insetSize: "" },
    totalStrips: undefined,
    draperyOrderDesignId: null,
    itemId: 0,
});

export const defaultCording = (): DraperyOrderDesignEmbellishment => ({
    id: 0,
    name: "",
    type: 2,
    subType: undefined,
    leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
    topEdge: { active: false, insetActive: false, insetSize: "" },
    bottomEdge: { active: false, insetActive: false, insetSize: "" },
    totalStrips: undefined,
    draperyOrderDesignId: null,
    itemId: 0,
});

export const defaultFlange = (): DraperyOrderDesignEmbellishment => ({
    id: 0,
    name: "",
    type: 3,
    subType: undefined,
    styleType: "Flat",
    leadReturnEdge: { active: false },
    topEdge: { active: false },
    bottomEdge: { active: false },
    totalStrips: undefined,
    draperyOrderDesignId: null,
    itemId: 0,
});

export const defaultTrim = (): DraperyOrderDesignEmbellishment => ({
    id: 0,
    name: "",
    type: 4,
    subType: undefined,
    appliedType: undefined,
    leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
    topEdge: { active: false, insetActive: false, insetSize: "" },
    bottomEdge: { active: false, insetActive: false, insetSize: "" },
    totalStrips: undefined,
    draperyOrderDesignId: null,
    itemId: 0,
});

export const defaultOtherEmbellishment =
    (): DraperyOrderDesignEmbellishment => ({
        id: 0,
        name: "",
        type: 5,
        details: "",
        leadReturnEdge: {},
        topEdge: {},
        bottomEdge: {},
        draperyOrderDesignId: null,
        price: "",
        itemId: 0,
    });

// duplicate
export const duplicateDraperyOrderDesign = (
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
): DraperyModule.DraperyOrderDesign => {
    return {
        ...draperyOrderDesign,
        id: 0,
        drawing: undefined,
    };
};

export const duplicateLining = (lining: any) => {
    return {
        ...lining,
        id: 0,
        draperyOrderDesignId: 0,
        draperyOrderLiningsFabric: {
            ...lining.draperyOrderLiningsFabric,
            id: 0,
        },
    };
};

export const duplicateEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment
) => {
    return {
        ...embellishment,
        id: 0,
        draperyOrderDesignId: 0,
        draperyOrderFabric: embellishment.draperyOrderFabric
            ? duplicateFabric(embellishment.draperyOrderFabric)
            : undefined,
    };
};

const validateDODTCustomHEMSFinishedSize = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (
        draperyOrderDesign.customHEMSJson.topHemFinishedSize === undefined ||
        draperyOrderDesign.customHEMSJson.topHemFinishedSize <= 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.topHemFinishedSize",
            message: "Top hem size must be a value greater than 0.",
        });
    }
    if (
        draperyOrderDesign.customHEMSJson.bottomHemFinishedSize === undefined ||
        draperyOrderDesign.customHEMSJson.bottomHemFinishedSize <= 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.bottomHemFinishedSize",
            message: "Bottom hem size must be a value greater than 0.",
        });
    }
    if (
        draperyOrderDesign.customHEMSJson.sideHemFinishedSize === undefined ||
        draperyOrderDesign.customHEMSJson.sideHemFinishedSize <= 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.sideHemFinishedSize",
            message: "Side hem size must be a value greater than 0.",
        });
    }
    return errors;
};
const validateBuckram = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (
        (draperyOrderDesign &&
            draperyOrderDesign.buckram === "Ask for each order") ||
        !draperyOrderDesign.buckram
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "buckram",
            message: "Choose whether to include buckram.",
        });
    }
    return errors;
};

const validateDODTCustomHEMSFabricAllowance = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (
        draperyOrderDesign.customHEMSJson.topHemFabricAllowance !== undefined &&
        draperyOrderDesign.customHEMSJson.topHemFinishedSize !== undefined &&
        draperyOrderDesign.customHEMSJson.topHemFabricAllowance <
            draperyOrderDesign.customHEMSJson.topHemFinishedSize
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.topHemFinishedSize",
            message: "Top hem size cannot exceed the top hem allowance.",
        });
    }

    if (
        draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance !==
            undefined &&
        draperyOrderDesign.customHEMSJson.bottomHemFinishedSize !== undefined &&
        draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance <
            draperyOrderDesign.customHEMSJson.bottomHemFinishedSize
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.bottomHemFinishedSize",
            message: "Bottom hem size cannot exceed the bottom hem allowance.",
        });
    }

    if (
        draperyOrderDesign.customHEMSJson.sideHemFabricAllowance !==
            undefined &&
        draperyOrderDesign.customHEMSJson.sideHemFinishedSize !== undefined &&
        draperyOrderDesign.customHEMSJson.sideHemFabricAllowance <
            draperyOrderDesign.customHEMSJson.sideHemFinishedSize
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.sideHemFinishedSize",
            message: "Side hem size cannot exceed the side hem allowance.",
        });
    }
    return errors;
};

const validateDODTSizes = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (
        draperyOrderDesign.customReturnOverlapJson.leadSize !== undefined &&
        draperyOrderDesign.customHEMSJson.sideHemFinishedSize !== undefined &&
        draperyOrderDesign.customReturnOverlapJson.leadSize <
            draperyOrderDesign.customHEMSJson.sideHemFinishedSize
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customReturnOverlapJson.leadSize",
            message: "Leading edge size cannot be less than the side hem size.",
        });
    }
    if (
        draperyOrderDesign.customReturnOverlapJson.returnSize !== undefined &&
        draperyOrderDesign.customHEMSJson.sideHemFinishedSize !== undefined &&
        draperyOrderDesign.customReturnOverlapJson.returnSize <
            draperyOrderDesign.customHEMSJson.sideHemFinishedSize
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customReturnOverlapJson.returnSize",
            message: "Return edge size cannot be less than the side hem size.",
        });
    }
    if (
        draperyOrderDesign.customReturnOverlapJson.overlapSize !== undefined &&
        draperyOrderDesign.customReturnOverlapJson.leadSize !== undefined &&
        (draperyOrderDesign.customReturnOverlapJson.overlapSize < 0 ||
            draperyOrderDesign.customReturnOverlapJson.overlapSize >
                draperyOrderDesign.customReturnOverlapJson.leadSize)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customReturnOverlapJson.overlapSize",
            message:
                "Overlap size must be a value between 0 and the leading edge size.",
        });
    }
    return errors;
};

const validateDODTReturnOverlap = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (
        draperyOrderDesign.customReturnOverlapJson.leadSize === undefined ||
        draperyOrderDesign.customReturnOverlapJson.leadSize <= 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customReturnOverlapJson.leadSize",
            message: "Leading edge size must be a value greater than 0.",
        });
    }

    if (
        draperyOrderDesign.customReturnOverlapJson.returnSize === undefined ||
        draperyOrderDesign.customReturnOverlapJson.returnSize <= 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customReturnOverlapJson.returnSize",
            message: "Return edge size must be a value greater than 0.",
        });
    }

    if (
        draperyOrderDesign.customReturnOverlapJson.overlapSize === undefined ||
        draperyOrderDesign.customReturnOverlapJson.overlapSize < 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customReturnOverlapJson.overlapSize",
            message: "Overlap must be zero or greater.",
        });
    }

    return errors;
};

const validateDODTCustomYardage = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (draperyOrderDesign.customIsYardageAddExcess) {
        if (draperyOrderDesign.customAddExcessValue === undefined) {
            errors.push({
                tabIndex,
                name: prefixName + "customAddExcessValue",
                message:
                    "Please specify the amount of excess fabric for main fabric.",
            });
        } else if (draperyOrderDesign.customAddExcessValue < 0) {
            errors.push({
                tabIndex,
                name: prefixName + "customAddExcessValue",
                message: "Amount of excess main fabric must be 0 or greater.",
            });
        }
        if (
            draperyOrderDesign.customAddExcessUnit === undefined ||
            draperyOrderDesign.customAddExcessUnit === "" ||
            draperyOrderDesign.customAddExcessUnit === null
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "customAddExcessUnit",
                message:
                    "Please specify the unit of excess fabric for main fabric.",
            });
        }
    }
    return errors;
};

const validateDraperyOrderDesignDraperyType = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign,
    embellishmentList: EmbellishmentCheckInfo[]
) => {
    // const cordingEmbellishment = embellishmentList[1].checked;
    // const flangeEmbellishment = embellishmentList[2].checked;
    const trimEmbellishment = embellishmentList[3].checked;

    errors = validateDODTCustomYardage(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign
    );

    errors = validateDODTCustomHEMSFinishedSize(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign
    );

    errors = validateDODTReturnOverlap(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign
    );

    if (trimEmbellishment) {
        if (
            draperyOrderDesign.customEmbellishmentJson.trimVertical ===
                undefined ||
            draperyOrderDesign.customEmbellishmentJson.trimVertical < 0
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "customEmbellishmentJson.trimVertical",
                message:
                    "Vertical trim allowance must be a value of 0 or more.",
            });
        }
        if (
            draperyOrderDesign.customEmbellishmentJson.trimHorizontal ===
                undefined ||
            draperyOrderDesign.customEmbellishmentJson.trimHorizontal < 0
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "customEmbellishmentJson.trimHorizontal",
                message:
                    "Horizontal trim allowance must be a value of 0 or more.",
            });
        }
    }

    if (
        draperyOrderDesign.customHEMSJson.topHemFinishedSize !== undefined &&
        draperyOrderDesign.customHEMSJson.bottomHemFinishedSize !== undefined
    ) {
        if (
            draperyOrderDesign.finishedLengthOfPanels >= 1 &&
            draperyOrderDesign.customHEMSJson.topHemFinishedSize +
                draperyOrderDesign.customHEMSJson.bottomHemFinishedSize >
                draperyOrderDesign.finishedLengthOfPanels
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "customHEMSJson.topHemFinishedSize",
                message:
                    "Top + Bottom Hem size cannot exceed the finished length.",
            });
        }
    }

    errors = validateDODTCustomHEMSFabricAllowance(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign
    );

    if (
        draperyOrderDesign.customHEMSJson.topHemFabricAllowance === undefined ||
        draperyOrderDesign.customHEMSJson.topHemFabricAllowance < 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.topHemFabricAllowance",
            message: "Please specify top hem allowance.",
        });
    }

    if (
        draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance ===
            undefined ||
        draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance < 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.bottomHemFabricAllowance",
            message: "Please specify bottom hem allowance.",
        });
    }

    if (
        draperyOrderDesign.customHEMSJson.sideHemFabricAllowance ===
            undefined ||
        draperyOrderDesign.customHEMSJson.sideHemFabricAllowance < 0
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customHEMSJson.sideHemFabricAllowance",
            message: "Please specify side hem allowance.",
        });
    }

    // if (flangeEmbellishment) {
    //     if (
    //         draperyOrderDesign.customEmbellishmentJson.flangeVertical ===
    //         undefined ||
    //         draperyOrderDesign.customEmbellishmentJson.flangeVertical < 0
    //     ) {
    //         errors.push({
    //             tabIndex,
    //             name: prefixName + "customEmbellishmentJson.flangeVertical",
    //             message:
    //                 "Vertical flange allowance must be a value of 0 or more.",
    //         });
    //     }

    //     if (
    //         draperyOrderDesign.customEmbellishmentJson.flangeHorizontal ===
    //         undefined ||
    //         draperyOrderDesign.customEmbellishmentJson.flangeHorizontal < 0
    //     ) {
    //         errors.push({
    //             tabIndex,
    //             name: prefixName + "customEmbellishmentJson.flangeHorizontal",
    //             message:
    //                 "Horizontal flange allowance must be a value of 0 or more.",
    //         });
    //     }
    // }

    // if (cordingEmbellishment) {
    //     if (
    //         draperyOrderDesign.customEmbellishmentJson.cordingVertical ===
    //         undefined ||
    //         draperyOrderDesign.customEmbellishmentJson.cordingVertical < 0
    //     ) {
    //         errors.push({
    //             tabIndex,
    //             name: prefixName + "customEmbellishmentJson.cordingVertical",
    //             message:
    //                 "Vertical cording allowance must be a value of 0 or more.",
    //         });
    //     }
    // }

    errors = validateDODTSizes(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign
    );

    return errors;
};

const validateDraperyOrderDesignTypes = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign,
    draperyItemDetail: any,
    embellishmentList: EmbellishmentCheckInfo[],
    liningsList: DraperyModule.DraperyOrderDesignLining[]
) => {
    if (draperyOrderDesign.headingType === "ripplefold") {
        if (!draperyOrderDesign.panelType) {
            errors.push({
                tabIndex,
                name: prefixName + "panelType",
                message: "Please select a panel configuration.",
            });
        }
        if (!draperyOrderDesign.headingType) {
            errors.push({
                tabIndex,
                name: prefixName + "ripplefoldOptions." + "headingType",
                message: "Please select heading type.",
            });
        }

        if (
            draperyOrderDesign.headingType &&
            !draperyOrderDesign.ripplefoldOptions.controlType
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "ripplefoldOptions." + "controlType",
                message: "Please select Ripplefold Control Type.",
            });
        }

        if (
            draperyOrderDesign.headingType &&
            !draperyOrderDesign.ripplefoldOptions.masterCarrier
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "ripplefoldOptions." + "masterCarrier",
                message: "Please select Ripplefold Master Carrier.",
            });
        }
        errors = validateDraperyOrderDesignDraperyType(
            tabIndex,
            errors,
            prefixName,
            draperyOrderDesign,
            embellishmentList
        );
        errors = validateBuckram(
            tabIndex,
            errors,
            prefixName,
            draperyOrderDesign
        );
        errors = validateDraperyOrderDesignCutLengths(
            tabIndex,
            errors,
            prefixName,
            draperyOrderDesign,
            embellishmentList,
            liningsList
        );
    } else {
        if (!draperyOrderDesign.panelType) {
            errors.push({
                tabIndex,
                name: prefixName + "panelType",
                message: "Please select a panel configuration.",
            });
        }

        if (!draperyOrderDesign.headingType) {
            errors.push({
                tabIndex,
                name: prefixName + "headingType",
                message: "Please select heading type.",
            });
        }
        if (draperyOrderDesign.headingType && !draperyOrderDesign.pleatType) {
            errors.push({
                tabIndex,
                name: prefixName + "pleatType",
                message: "Please select pleat type.",
            });
        }

        if (draperyOrderDesign.headingType && !draperyOrderDesign.draperyType) {
            errors.push({
                tabIndex,
                name: prefixName + "draperyType",
                message: "Please select the drapery type.",
            });
        }
        if (draperyOrderDesign.draperyType) {
            errors = validateBuckram(
                tabIndex,
                errors,
                prefixName,
                draperyOrderDesign
            );
            errors = validateDraperyOrderDesignCutLengths(
                tabIndex,
                errors,
                prefixName,
                draperyOrderDesign,
                embellishmentList,
                liningsList
            );
            errors = validateDraperyOrderDesignDraperyType(
                tabIndex,
                errors,
                prefixName,
                draperyOrderDesign,
                embellishmentList
            );
        }
    }
    const selectedPleatType = draperyItemDetail.find(
        (item: DraperyItemDetailsFields) => {
            const foundPleatType = item.pleatTypesWithPricing.find((pleat) => {
                const isEqual =
                    pleat.newPleatId === draperyOrderDesign.pleatType;
                return isEqual;
            });
            return (
                foundPleatType &&
                (foundPleatType.isDeleted === true ||
                    foundPleatType.isActive === false)
            );
        }
    );

    if (selectedPleatType) {
        errors.push({
            tabIndex,
            name: prefixName + "pleatType",
            message: "Pleat type required.",
        });
    }

    return errors;
};
const checkAllPanelWidthSelected = (
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    const numOfPanels = draperyOrderDesign.numOfPanels;
    const panelJson = draperyOrderDesign.panelJson;
    let isAllPanelWidthSelected = true;

    if (
        isAllPanelWidthSelected &&
        !panelJson.pannel1Width &&
        numOfPanels >= 1
    ) {
        isAllPanelWidthSelected = false;
    }
    if (
        isAllPanelWidthSelected &&
        !panelJson.pannel2Width &&
        numOfPanels >= 2
    ) {
        isAllPanelWidthSelected = false;
    }
    if (
        isAllPanelWidthSelected &&
        !panelJson.pannel3Width &&
        numOfPanels >= 3
    ) {
        isAllPanelWidthSelected = false;
    }
    if (
        isAllPanelWidthSelected &&
        !panelJson.pannel4Width &&
        numOfPanels >= 4
    ) {
        isAllPanelWidthSelected = false;
    }
    if (
        isAllPanelWidthSelected &&
        !panelJson.pannel5Width &&
        numOfPanels >= 5
    ) {
        isAllPanelWidthSelected = false;
    }

    return isAllPanelWidthSelected;
};

const validateDraperyOrderDesignCategories = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign,
    clearHighlightSection?: (name: string) => void
) => {
    if (draperyOrderDesign.category === -1) {
        errors.push({
            tabIndex,
            name: prefixName + "category",
            message: "Please choose if draperies are functional or stationary.",
        });
    }
    if (
        draperyOrderDesign.category === 0 &&
        (!draperyOrderDesign.rodWidth || draperyOrderDesign.rodWidth < 1)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "rodWidth",
            message: 'Rod width must be a value of at least 1".',
        });
    }
    if (draperyOrderDesign.category === 1 && draperyOrderDesign.panelType) {
        const isAllPanelWidthSelected =
            checkAllPanelWidthSelected(draperyOrderDesign);
        if (!isAllPanelWidthSelected) {
            errors.push({
                tabIndex,
                name: prefixName + "panelJson.pannel1Width",
                message: "Please select the number widths per panel.",
            });
        } else if (clearHighlightSection) {
            clearHighlightSection(prefixName + "panelJson.pannel1Width");
        }
    }
    return errors;
};

const validateDraperyOrderDesignCutLengths = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign,
    embellishmentList: EmbellishmentCheckInfo[],
    liningsList: DraperyModule.DraperyOrderDesignLining[]
) => {
    const liningItem = liningsList.find((ll) => ll.name === "Lining");
    if (
        liningItem &&
        liningItem.active === true &&
        draperyOrderDesign.customCutLengthJson.lining === undefined
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customCutLengthJson.lining",
            message:
                "Please complete the lining cut length formula with the number of inches to be added to the Finished Length.",
        });
    }
    const interLiningItem = liningsList.find((ll) => ll.name === "Interlining");
    if (
        interLiningItem &&
        interLiningItem.active === true &&
        draperyOrderDesign.customCutLengthJson.interlining === undefined
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customCutLengthJson.interlining",
            message:
                "Please complete the interlining cut length formula with the number of inches to be added to or subtracted from the Finished Length.",
        });
    }
    const embellishmentCording = embellishmentList.find(
        (el) => el.title === "Cording"
    );
    const embellishmentFlange = embellishmentList.find(
        (el) => el.title === "Flange"
    );

    if (
        embellishmentCording &&
        embellishmentCording.checked === true &&
        (draperyOrderDesign.customEmbellishmentJson.cordingVertical ===
            undefined ||
            draperyOrderDesign.customEmbellishmentJson.cordingVertical < 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customEmbellishmentJson.cordingVertical",
            message: "Vertical Cording allowance must be a value of 0 or more.",
        });
    }

    if (
        embellishmentFlange &&
        embellishmentFlange.checked === true &&
        (draperyOrderDesign.customEmbellishmentJson.flangeVertical ===
            undefined ||
            draperyOrderDesign.customEmbellishmentJson.flangeVertical < 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "customEmbellishmentJson.flangeVertical",
            message: "Vertical Flange allowance must be a value of 0 or more.",
        });
    }
    return errors;
};

const validateDraperyOrderDesignPanelWidth = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    mainFabric: DraperyOrderFabric,
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    if (draperyOrderDesign.category === 1) {
        const widths = Object.entries(draperyOrderDesign.panelJson).map(
            ([key, value]) => value
        );
        const panelWidths = [...new Set(widths)];
        if (
            panelWidths &&
            panelWidths.length > 2 &&
            mainFabric.directionOfUse === 2
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "panelJson.pannel1Width",
                message:
                    "A maximum of 2 differing panel widths are allowed when the Main Fabric is railroaded.",
            });
        }
    }
    return errors;
};

// validate
export const validateDraperyOrderDesign = (
    draperyOrderDesign: DraperyModule.DraperyOrderDesign,
    draperyItemDetail: any,
    embellishmentList: EmbellishmentCheckInfo[],
    liningsList: DraperyModule.DraperyOrderDesignLining[],
    mainFabric: DraperyOrderFabric,
    clearHighlightSection?: (name: string) => void
): ValidationErrorInfo[] => {
    const prefixName = "draperyOrderDesign.";
    let tabIndex = 1;
    let errors: ValidationErrorInfo[] = [];

    errors = validateDraperyOrderDesignCategories(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign,
        clearHighlightSection
    );

    errors = validateDraperyOrderDesignPanelWidth(
        tabIndex,
        errors,
        prefixName,
        mainFabric,
        draperyOrderDesign
    );

    errors = validateDraperyOrderDesignTypes(
        tabIndex,
        errors,
        prefixName,
        draperyOrderDesign,
        draperyItemDetail,
        embellishmentList,
        liningsList
    );
    if (
        draperyOrderDesign.headingType === "ripplefold" &&
        !draperyOrderDesign.draperyFullness
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "draperyFullness",
            message: "Please select drapery fullness.",
        });
    } else if (
        draperyOrderDesign.draperyType &&
        !draperyOrderDesign.draperyFullness
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "draperyFullness",
            message: "Please select drapery fullness.",
        });
    }
    if (
        !draperyOrderDesign.finishedLengthOfPanels ||
        draperyOrderDesign.finishedLengthOfPanels < 1
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "finishedLengthOfPanels",
            message: "Finished length must be a value of at least 1.",
        });
    }
    if (draperyOrderDesign.notes && draperyOrderDesign.notes.length > 150) {
        errors.push({
            tabIndex,
            name: prefixName + "notes",
            message: "Notes letters must be less than 150.",
        });
    }

    // if (draperyOrderDesign.draperyType) {
    //     tabIndex = -2;
    //     errors = validateDraperyOrderDesignDraperyType(
    //         tabIndex,
    //         errors,
    //         prefixName,
    //         draperyOrderDesign,
    //         embellishmentList
    //     );
    // }
    // errors = validateDraperyOrderDesignCutLengths(
    //     tabIndex,
    //     errors,
    //     prefixName,
    //     draperyOrderDesign,
    //     embellishmentList,
    //     liningsList
    // );

    return errors;
};

export const validateLining = (
    index: number,
    lining: DraperyModule.DraperyOrderDesignLining
): ValidationErrorInfo[] => {
    // const prefixName = "liningsList." + index + ".";
    // const tabIndex = 0;
    const errors: ValidationErrorInfo[] = [];
    // if (lining.active && !lining.description) { errors.push({tabIndex, name: prefixName + "description", message: "Please specify the " + lining.name.toLowerCase() + " name."}); }
    return errors;
};

const validateBandingLeadReturnEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { totalStrips, leadReturnEdge } = embellishment;
    if (!totalStrips || totalStrips < 1) {
        errors.push({
            tabIndex,
            name: prefixName + "totalStrips",
            message:
                "Total number of vertical strips of banding must be at least 1.",
        });
    }

    if (leadReturnEdge.size === undefined || leadReturnEdge.size === "") {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.size",
            message:
                "Please specify the banding width for the lead/return placement.",
        });
    } else if (isNaN(+leadReturnEdge.size!) || leadReturnEdge.size! <= 0) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.size",
            message: "Banding width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateBandingTopEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { topEdge } = embellishment;
    if (topEdge.size === undefined || topEdge.size === "") {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.size",
            message: "Please specify the banding width for the top placement.",
        });
    } else if (isNaN(+topEdge.size!) || topEdge.size! <= 0) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.size",
            message: "Banding width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateBandingBottomEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { bottomEdge } = embellishment;
    if (bottomEdge.size === undefined || bottomEdge.size === "") {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.size",
            message:
                "Please specify the banding width for the bottom placement.",
        });
    } else if (isNaN(+bottomEdge.size!) || bottomEdge.size! <= 0) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.size",
            message: "Banding width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateBandingEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    tabIndex: number,
    prefixName: string,
    list: DraperyOrderDesignEmbellishment[]
) => {
    let errors: ValidationErrorInfo[] = [];
    const { leadReturnEdge, topEdge, bottomEdge } = embellishment;
    const index = Number(prefixName.split(".")[1]);
    const numEmbellishment = list.filter(
        (embellishment) => embellishment.type === 1
    ).length;
    let embellishmentName = `Banding ${numEmbellishment > 1 ? index + 1 : ""}`;
    // banding validation
    if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.active",
            message: "Please specify the location for banding placement.",
        });
    }
    if (leadReturnEdge.active) {
        errors = validateBandingLeadReturnEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        leadReturnEdge.insetActive &&
        (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.insetSize",
            message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
        });
    }

    if (topEdge.active) {
        errors = validateBandingTopEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        topEdge.insetActive &&
        (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.insetSize",
            message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
        });
    }

    if (bottomEdge.active) {
        errors = validateBandingBottomEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        bottomEdge.insetActive &&
        (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.insetSize",
            message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
        });
    }

    return errors;
};

const validateCordingEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    tabIndex: number,
    prefixName: string,
    list: DraperyOrderDesignEmbellishment[]
) => {
    const errors: ValidationErrorInfo[] = [];
    const { leadReturnEdge, topEdge, bottomEdge, totalStrips, subType } =
        embellishment;
    const index = Number(prefixName.split(".")[1]);
    const numEmbellishment = list.filter(
        (embellishment) => embellishment.type === 2
    ).length;
    let embellishmentName = `Cording ${numEmbellishment > 1 ? index + 1 : ""}`;
    // cording validation
    if (subType === undefined) {
        errors.push({
            tabIndex,
            name: prefixName + "subType",
            message: "Please select the cording type.",
        });
    }
    if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.active",
            message: "Please specify the location for cording placement.",
        });
    }
    if (leadReturnEdge.active) {
        if (!totalStrips || totalStrips < 1) {
            errors.push({
                tabIndex,
                name: prefixName + "totalStrips",
                message:
                    "Total number of vertical strips of cording must be at least 1.",
            });
        }
    }

    if (
        leadReturnEdge.insetActive &&
        (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.insetSize",
            message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
        });
    }

    if (
        topEdge.insetActive &&
        (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.insetSize",
            message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
        });
    }

    if (
        bottomEdge.insetActive &&
        (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.insetSize",
            message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
        });
    }

    return errors;
};

const validateFlangeLeadReturnEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { totalStrips, leadReturnEdge, subType } = embellishment;
    if (!totalStrips || totalStrips < 1) {
        errors.push({
            tabIndex,
            name: prefixName + "totalStrips",
            message:
                "Total number of vertical strips of flange must be at least 1.",
        });
    }
    if (
        (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
        subType === 1
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.size",
            message:
                "Please specify the flange width for the lead/return placement.",
        });
    } else if (
        subType === 1 &&
        (isNaN(+leadReturnEdge.size!) || leadReturnEdge.size! <= 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.size",
            message: "Flange width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateFlangeTopEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { topEdge, subType } = embellishment;
    if ((topEdge.size === undefined || topEdge.size === "") && subType === 1) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.size",
            message: "Please specify the flange width for the top placement.",
        });
    } else if (subType === 1 && (isNaN(+topEdge.size!) || topEdge.size! <= 0)) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.size",
            message: "Flange width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateFlangeBottomEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { bottomEdge, subType } = embellishment;
    if (
        (bottomEdge.size === undefined || bottomEdge.size === "") &&
        subType === 1
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.size",
            message:
                "Please specify the flange width for the bottom placement.",
        });
    } else if (
        subType === 1 &&
        (isNaN(+bottomEdge.size!) || bottomEdge.size! <= 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.size",
            message: "Flange width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateFlangeEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    tabIndex: number,
    prefixName: string,
    list: DraperyOrderDesignEmbellishment[]
) => {
    let errors: ValidationErrorInfo[] = [];
    const index = Number(prefixName.split(".")[1]);
    const { leadReturnEdge, topEdge, bottomEdge, subType } = embellishment;
    const numEmbellishment = list.filter(
        (embellishment) => embellishment.type === 3
    ).length;
    let embellishmentName = `Flange ${numEmbellishment > 1 ? index + 1 : ""}`;
    // flange validation
    errors = checkUndefinedTypes(
        errors,
        tabIndex,
        prefixName,
        "subType",
        "Please select the flange type.",
        subType
    );

    if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.active",
            message: "Please specify the location for flange placement.",
        });
    }
    if (leadReturnEdge.active) {
        errors = validateFlangeLeadReturnEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (topEdge.active) {
        errors = validateFlangeTopEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (bottomEdge.active) {
        errors = validateFlangeBottomEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        leadReturnEdge.insetActive &&
        (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.insetSize",
            message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
        });
    }

    if (
        topEdge.insetActive &&
        (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.insetSize",
            message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
        });
    }

    if (
        bottomEdge.insetActive &&
        (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.insetSize",
            message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
        });
    }

    return errors;
};

const checkUndefinedTypes = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    prefixName: string,
    suffix: string,
    message: string,
    typeParam?: number
) => {
    if (typeParam === undefined) {
        errors.push({
            tabIndex,
            name: prefixName + suffix,
            message,
        });
    }
    return errors;
};

const validateTrimLeadReturnEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { totalStrips, leadReturnEdge, subType } = embellishment;
    if (!totalStrips || totalStrips < 1) {
        errors.push({
            tabIndex,
            name: prefixName + "totalStrips",
            message:
                "Total number of vertical strips of trim must be at least 1.",
        });
    }
    if (
        (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
        subType === 1
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.size",
            message:
                "Please specify the trim width for the lead/return placement.",
        });
    } else if (
        subType === 1 &&
        (isNaN(+leadReturnEdge.size!) || leadReturnEdge.size! <= 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.size",
            message: "Trim width must be a value greater than 0.",
        });
    }
    return errors;
};

const validateTrimTopEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { topEdge, subType } = embellishment;
    if ((topEdge.size === undefined || topEdge.size === "") && subType === 1) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.size",
            message: "Please specify the trim width for the top placement.",
        });
    } else if (subType === 1 && (isNaN(+topEdge.size!) || topEdge.size! <= 0)) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.size",
            message: "Trim width must be a value greater than 0.",
        });
    }
    return errors;
};
const validateName = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { name } = embellishment;
    if (!name) {
        errors.push({
            tabIndex,
            name: prefixName + "name",
            message: "Please specify the Other Embellishment name.",
        });
    }
    return errors;
};

const validateUniqueName = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    unique: boolean,
    prefixName: string
  ) => {
    if (unique) {
      errors.push({
        tabIndex,
        name: prefixName + "name",
        message: "Other Embellishment name is already in use; please specify a different name.",
      });
    }
    return errors;
  }

const validatePrice = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { price } = embellishment;
    if (!price) {
        errors.push({
            tabIndex,
            name: prefixName + "price",
            message: "Please specify the price for the Other Embellishment.",
        });
    }
    return errors;
};
const validateOtherEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    tabIndex: number,
    prefixName: string,
    list: DraperyOrderDesignEmbellishment[],
    targetArrayIndex?: number
) => {
    let errors: ValidationErrorInfo[] = [];
    const foundSameName = list && list.length > 0 ? list.find((i, idx) => {
        if(i.name){
          return i.name === embellishment.name && idx !== targetArrayIndex
        }
        return false
      }) : false
    const { name, price } = embellishment;
    if (!name) {
        errors = validateName(errors, tabIndex, embellishment, prefixName);
    }
    if (foundSameName){
        errors = validateUniqueName(errors, tabIndex, foundSameName ? true : false, prefixName)
    }
    if (!price) {
        errors = validatePrice(errors, tabIndex, embellishment, prefixName);
    }

    return errors;
};

const validateTrimEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    tabIndex: number,
    prefixName: string,
    list: DraperyOrderDesignEmbellishment[]
) => {
    let errors: ValidationErrorInfo[] = [];
    const { leadReturnEdge, topEdge, bottomEdge, subType } = embellishment;
    const index = Number(prefixName.split(".")[1]);
    const numEmbellishment = list.filter(
        (embellishment) => embellishment.type === 4
    ).length;
    let embellishmentName = `Trim ${numEmbellishment > 1 ? index + 1 : ""}`;
    // trim validation
    errors = checkUndefinedTypes(
        errors,
        tabIndex,
        prefixName,
        "subType",
        "Please select the trim type.",
        subType
    );

    // errors = checkUndefinedTypes(
    //     errors,
    //     tabIndex,
    //     prefixName,
    //     "appliedType",
    //     "Please select how the trim will be applied.",
    //     appliedType
    // );

    if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.active",
            message: "Please specify the location for trim placement.",
        });
    }

    if (leadReturnEdge.active) {
        errors = validateTrimLeadReturnEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        leadReturnEdge.insetActive &&
        (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "leadReturnEdge.insetSize",
            message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
        });
    }

    if (topEdge.active) {
        errors = validateTrimTopEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        topEdge.insetActive &&
        (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "topEdge.insetSize",
            message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
        });
    }

    if (bottomEdge.active) {
        errors = validateTrimBottomEdge(
            errors,
            tabIndex,
            embellishment,
            prefixName
        );
    }

    if (
        bottomEdge.insetActive &&
        (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.insetSize",
            message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
        });
    }
    return errors;
};

const validateTrimBottomEdge = (
    errors: ValidationErrorInfo[],
    tabIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    prefixName: string
) => {
    const { bottomEdge, subType } = embellishment;
    if (
        (bottomEdge.size === undefined || bottomEdge.size === "") &&
        subType === 1
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.size",
            message: "Please specify the trim width for the bottom placement.",
        });
    } else if (
        subType === 1 &&
        (isNaN(+bottomEdge.size!) || bottomEdge.size! <= 0)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "bottomEdge.size",
            message: "Trim width must be a value greater than 0.",
        });
    }
    return errors;
};

export const validateEmbellishment = (
    targetArray: string,
    targetArrayIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    list: DraperyOrderDesignEmbellishment[]
): ValidationErrorInfo[] => {
    const prefixName = `${targetArray}.${targetArrayIndex}.`;
    const tabIndex = 1;
    let errors: ValidationErrorInfo[] = [];
    const embelType = embellishment.type;

    switch (embelType) {
        case 1: {
            errors = validateBandingEmbellishment(
                embellishment,
                tabIndex,
                prefixName,
                list
            );
            break;
        }
        case 2: {
            errors = validateCordingEmbellishment(
                embellishment,
                tabIndex,
                prefixName,
                list
            );
            break;
        }
        case 3: {
            errors = validateFlangeEmbellishment(
                embellishment,
                tabIndex,
                prefixName,
                list
            );
            break;
        }
        case 4: {
            errors = validateTrimEmbellishment(
                embellishment,
                tabIndex,
                prefixName,
                list
            );
            break;
        }
        case 5: {
            errors = validateOtherEmbellishment(
                embellishment,
                tabIndex,
                prefixName,
                list,
                targetArrayIndex
            );
            break;
        }
        default:
            break;
    }

    return errors;
};

export const validateEmbellishmentList = (
    embellishmentsInfo: EmbellishmentCheckInfo,
    list: DraperyOrderDesignEmbellishment[],
    targetArray: string
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    if (embellishmentsInfo.checked) {
        for (const [index, embellishment] of list.entries()) {
            errors = errors.concat(
                validateEmbellishment(targetArray, index, embellishment, list)
            );
        }
    }
    return errors;
};

export const validateLiningsList = (
    list: DraperyModule.DraperyOrderDesignLining[],
    isNoneLinings?: boolean
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    let isSelected: boolean = false;
    for (const [index, lining] of list.entries()) {
        errors = errors.concat(validateLining(index, lining));
        isSelected = isSelected || lining.active;
    }
    if (!isNoneLinings && !isSelected) {
        errors.push({
            tabIndex: 1,
            name: "isNoneLinings",
            message: "Please select a lining option.",
        });
    }
    if (isNoneLinings && isSelected) {
        errors.push({
            tabIndex: 1,
            name: "isNoneLinings",
            message: "You cannot select a lining and none at the same time.",
        });
    }
    return errors;
};

export const validateEmbellishmentChecked = (
    list: EmbellishmentCheckInfo[],
    isNoneEmbellishment?: boolean
): ValidationErrorInfo[] => {
    const errors: ValidationErrorInfo[] = [];
    let isSelected: boolean = false;
    for (const embellishmentInfo of list) {
        isSelected = isSelected || embellishmentInfo.checked;
    }
    if (!isNoneEmbellishment && !isSelected) {
        errors.push({
            tabIndex: 1,
            name: "isNoneEmbellishment",
            message: "Please select an embellishment option.",
        });
    }
    if (isNoneEmbellishment && isSelected) {
        errors.push({
            tabIndex: 1,
            name: "isNoneEmbellishment",
            message:
                "You cannot select a embellishment and none at the same time.",
        });
    }
    return errors;
};

const PanelsContainer = styled.div`
    position: relative;
    width: 100%;
    height: inherit;
    padding-top: ${(viewBox.height * 100) / viewBox.width}%;
`;
const RatioPanelsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;
const PanelSVGContainer = styled.div`
    width: 100%;
    height: 100%;
`;
const PanelConfWrapper = styled.div`
    border: 1px solid #e8e9e6;
    padding: 10px 20px 10px 20px;
    max-width: 1000px;
    margin: 10px 10px 10px 0;
    min-width: 520px;
    .choose-panel-width-desc {
        color: #376c83;
        font-size: 16px;
        font-weight: 500;
    }
`;
const PanelConfRow = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    .subtitle {
        color: #376c83;
        margin-right: 20px;
        font-size: 16px;
        font-weight: 500;
    }
`;
interface PanelWidthContainerProps {
    left: number;
    right: number;
}
const PanelWidthContainer = styled.div<PanelWidthContainerProps>`
    width: 100%;
    display: flex;
    margin-top: -10px;
    padding-left: ${(props) => props.left}%;
    padding-right: ${(props) => props.right}%;
`;
const PanelWidthSelectWrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-flow: wrap;
    > div:first-child {
        width: 56px;
    }
    > div:last-child {
        align-self: center;
    }
`;

const LiningsWrap = styled.div`
    display: flex;
    align-items: flex-end;
`;

const AdditionalFormContainer = styled.div`
    border: 1px solid #bebebe;
    background: #fff;
    width: 100%;
    margin: 5px auto 0 auto;
    max-width: 900px;
    min-width: 570px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    > div:first-child {
        margin-top: 0;
    }

    > div {
        margin-top: 20px;
    }
`;

export const CustomizeConstructionDiv = styled.div`
    display: flex;
    align-items: center;
    color: #3b97b1;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    padding-bottom: 8px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 40px 0px;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 1444px;
        height: 1px;
        background-color: #545454;

        @media (max-width: 1634px) {
            min-width: 90%;
        }
    }
`;

const RipplefoldOptions = styled.div``;

const RipplefoldOptionsHeader = styled.div`
    padding-left: 10px;
    margin-bottom: 16px;
`;
interface RowProps {
    isError?: boolean;
}
const RipplefoldOptionsRow = styled.div<RowProps>`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding-left: 10px;
    min-height: 43px;

    ${(props) => props.isError && "background: rgba(255,0,0,.4);"}
`;

interface PanelConf {
    name: string;
    title: string;
    numberPanels: number;
    size: string;
}

interface PleatType {
    image: string;
    type: string;
    title: string;
    value: any;
    active: boolean;
    isDeleted?: boolean;
}

export const panelsConfiguration: PanelConf[] = [
    { name: "center", title: "SINGLE CENTER", numberPanels: 1, size: "small" },
    { name: "left", title: "LEFT", numberPanels: 1, size: "small" },
    { name: "right", title: "RIGHT", numberPanels: 1, size: "small" },
    { name: "pair", title: "PAIR", numberPanels: 2, size: "small" },
    {
        name: "three-panels",
        title: "3 PANELS",
        numberPanels: 3,
        size: "middle",
    },
    { name: "four-panels", title: "4 PANELS", numberPanels: 4, size: "long" },
    { name: "five-panels", title: "5 PANELS", numberPanels: 5, size: "long" },
];

export const pleatTypeList: PleatType[] = [
    {
        type: "rodandrings",
        title: "NONE (FLAT)",
        value: "NONE (FLAT)",
        image: "",
        active: true,
    },
    {
        type: "rodandrings",
        title: "EURO (2 FINGER)",
        value: "EURO (2 FINGER)",
        image: "",
        active: true,
    },
    {
        type: "rodandrings",
        title: "EURO (3 FINGER)",
        value: "EURO (3 FINGER)",
        image: "",
        active: true,
    },
    {
        type: "rodandrings",
        title: "GOBLET",
        value: "GOBLET",
        image: "",
        active: true,
    },
    {
        type: "rodandrings",
        title: "INVERTED BOX",
        value: "INVERTED BOX",
        image: "",
        active: true,
    },
    {
        type: "rodandrings",
        title: "PINCH (2 FINGER)",
        value: "PINCH (2 FINGER)",
        image: "",
        active: true,
    },
    {
        type: "rodandrings",
        title: "PINCH (3 FINGER)",
        value: "PINCH (3 FINGER)",
        image: "",
        active: true,
    },
];

interface Props {
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    liningsList: DraperyModule.DraperyOrderDesignLining[];
    isNoneLinings?: boolean;
    embellishmentsList: EmbellishmentCheckInfo[];
    isNoneEmbellishment?: boolean;
    isCustomizeConstructionOpen: boolean;
    disabled: boolean;
    highlightedSection: string | null | undefined;
    data?: any;
    workroomSetting: WorkroomSetting | null;
    formErrorID?: string;

    openImageModal(name: string, option: "add" | "delete"): void;
    resetPanelConf(
        val?: string | number | undefined,
        name?: string | undefined
    ): any;
    resetPleatType(): void;
    addNewLining(): void;
    addEmbellishment(targetArray: string): void;
    deleteEmbellishment(stateName: string, index: number): void;
    toggleCustomizeConstruction(): void;
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleChangeRipplefoldOptions(e: ChangeEvent<HTMLInputElement>): void;
    handleRemoveRipplefoldDetail(
        key: string,
        value: string | undefined | null
    ): void;
    handleInputChangeForNumberPrice(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(e: any): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    updateStateByName(
        name: string,
        value:
            | string
            | number
            | boolean
            | number[]
            | undefined
            | { otherPleatImageId?: string }
    ): void;
    onShapeChanged(shapes: DraperyModule.Shape[]): void;
    getEmbellishmentsByIndex(index: number): {
        name: string;
        list: DraperyOrderDesignEmbellishment[];
    };
    handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void;
    handleFullnessSelect(e: ChangeEvent<HTMLSelectElement>): void;
    handleInputBlur?(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    draperyItemDetail?: any;
    dispatch: Dispatch<FormFieldsAction>;
    workOrderEditMode: boolean;
    handleRemoveDraperyDesignCategory(value: number): void;
    openConstructionDetailsEditModal?(): void;
    resetDraperyOrderEmbellishments(): void;
    resetLinings(): void;
    orderType: string | undefined;
    numberingTrims(arg?: string | undefined): void;
}

interface State {
    widthList: string[];
    dynamicPleatTypesAdded: boolean;
    pleatTypeListNew: PleatType[];
    isPleatModalOpen: boolean;
}

class DesignTab extends Component<Props, State> {
    private drawingRef = React.createRef<Drawing>();

    public constructor(props: Props) {
        super(props);

        this.state = {
            widthList: [
                "0",
                "0.5",
                "1",
                "1.5",
                "2",
                "2.5",
                "3",
                "3.5",
                "4",
                "4.5",
                "5",
                "5.5",
                "6",
                "6.5",
                "7",
                "7.5",
                "8",
            ],
            dynamicPleatTypesAdded: false,
            pleatTypeListNew: [],
            isPleatModalOpen: false,
        };
    }

    public componentDidUpdate(prevProps: Props) {
        const {
            data,
            toggleCustomizeConstruction,
            isCustomizeConstructionOpen,
        } = this.props;
        const draperyTypeErrors = (data && data.draperyOrderDesignErrors) || [];

        if (
            !isCustomizeConstructionOpen &&
            draperyTypeErrors.some(
                (error: any) =>
                    error.name === "draperyOrderDesign.draperyFullness"
            ) &&
            this.props.draperyOrderDesign.headingType &&
            this.props.draperyOrderDesign.draperyType
        ) {
            toggleCustomizeConstruction();
        }

        const { draperyItemDetail } = this.props;
        let dynamicPleatTypes: PleatType[] = [];

        if (
            draperyItemDetail &&
            draperyItemDetail[0] &&
            draperyItemDetail[0].pleatTypesWithPricing &&
            !this.state.dynamicPleatTypesAdded
        ) {
            draperyItemDetail[0].pleatTypesWithPricing.forEach(
                (pleatType: {
                    image: string;
                    newPleatId: any;
                    name: any;
                    isActive: boolean;
                    isDeleted: boolean;
                }) => {
                    if (pleatType.newPleatId) {
                        dynamicPleatTypes.push({
                            type: "rodandrings",
                            title: pleatType.name,
                            value: pleatType.newPleatId,
                            image: pleatType.image,
                            active: pleatType.isActive,
                            isDeleted: pleatType.isDeleted,
                        });
                    }
                }
            );

            this.setState(
                {
                    dynamicPleatTypesAdded: true,
                    pleatTypeListNew: [
                        ...this.state.pleatTypeListNew,
                        ...pleatTypeList,
                        ...dynamicPleatTypes,
                    ],
                },
                () => {}
            );
        }
        if (
            data.draperyOrderDesign.headingType === "ripplefold" &&
            data.draperyOrderDesign.pleatType !== ""
        ) {
            data.draperyOrderDesign.pleatType = "";
        }
    }

    public drawingBlob = async (): Promise<Blob | null> => {
        if (this.drawingRef.current) {
            return this.drawingRef.current.convertToImage();
        } else {
            return null;
        }
    };

    public render() {
        return (
            <>
                <DraperyHeader>TREATMENT FUNCTION</DraperyHeader>
                {this.renderFirstQuestion()}
                {this.renderSecondQuestion()}

                <DraperyHeader>STYLE</DraperyHeader>
                {/* {this.renderThirdQuestion()} */}
                {this.renderFifthQuestion()}
                {this.renderSixthQuestion()}

                <DraperyHeader>LININGS</DraperyHeader>
                {this.renderSeventhQuestion()}
                {this.renderDrawingSection()}

                <DraperyHeader>EMBELLISHMENTS & ADD-ONS</DraperyHeader>
                {this.renderEighthQuestion()}
                {this.renderNinthQuestion()}
                {this.renderConstructionDiv()}

                <DraperyHeader>NOTES</DraperyHeader>
                {this.renderNotesSection()}
            </>
        );
    }

    private renderFirstQuestion = (): JSX.Element => {
        const { formErrorID, draperyOrderDesign, highlightedSection } =
            this.props;
        return (
            <>
                <CalculatorFormField
                    id="1"
                    uiInline
                    uiNoControlWrap
                    uiNoMargin
                    isError={
                        highlightedSection === "draperyOrderDesign.category"
                    }
                >
                    <CalculatorFormFieldHeader>
                        <CalculatorFormQuestionNumber
                            uiError={formErrorID === "1"}
                        >
                            1
                        </CalculatorFormQuestionNumber>
                        <CalculatorFormFieldLabel>
                            These draperies are...
                        </CalculatorFormFieldLabel>
                        <TooltipPopup
                            text={
                                <TooltipContainer>
                                    <TooltipHeader>
                                        FUNCTIONAL DRAPERIES
                                    </TooltipHeader>
                                    <TooltipText>
                                        Panels that need to be fully opened and
                                        closed.
                                    </TooltipText>
                                    <TooltipHeader>
                                        STATIONARY DRAPERIES
                                    </TooltipHeader>
                                    <TooltipText>
                                        Draperies for decorational purposes that
                                        do not need to fully open and close.
                                    </TooltipText>
                                </TooltipContainer>
                            }
                            position="top left"
                            className="normal"
                        />
                    </CalculatorFormFieldHeader>

                    <CalculatorRadioGroup>
                        <RadioLabel
                            className={`design_category ${
                                draperyOrderDesign.category === 0
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <RadioInput
                                type="radio"
                                id="draperyType-functional"
                                name="draperyOrderDesign.category"
                                value={0}
                                checked={draperyOrderDesign.category === 0}
                                onClick={() =>
                                    this.props.handleRemoveDraperyDesignCategory(
                                        draperyOrderDesign.category
                                    )
                                }
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Functional
                        </RadioLabel>
                        <RadioLabel
                            className={`design_category ${
                                draperyOrderDesign.category === 1
                                    ? "active"
                                    : ""
                            }`}
                        >
                            <RadioInput
                                type="radio"
                                id="draperyType-stationary"
                                name="draperyOrderDesign.category"
                                value={1}
                                checked={draperyOrderDesign.category === 1}
                                onClick={() =>
                                    this.props.handleRemoveDraperyDesignCategory(
                                        draperyOrderDesign.category
                                    )
                                }
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Stationary
                        </RadioLabel>
                    </CalculatorRadioGroup>
                </CalculatorFormField>
                {draperyOrderDesign.category === 0 && (
                    <CalculatorExtraDiv>
                        <PrependAppendInputGroup
                            style={
                                highlightedSection ===
                                `draperyOrderDesign.rodWidth`
                                    ? highlightStyle
                                    : {}
                            }
                        >
                            <PrependAppendInputGroupLabel>
                                Rod Width
                            </PrependAppendInputGroupLabel>
                            <PrependAppendInput
                                autoFocus
                                type="number"
                                id="draperyOrderDesign.rodWidth"
                                name="draperyOrderDesign.rodWidth"
                                autoComplete="off"
                                value={draperyOrderDesign.rodWidth || ""}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                onKeyDown={(evt) => {
                                    const key = evt.key;
                                    const isNumericKey = /\d/.test(key);
                                    const isDotKey = key === ".";
                                    const isBackspaceKey = key === "Backspace";
                                    if (
                                        !isNumericKey &&
                                        !isDotKey &&
                                        !isBackspaceKey
                                    ) {
                                        evt.preventDefault();
                                    }
                                }}
                            />
                            <PrependAppendInputGroupLabel>
                                inches
                            </PrependAppendInputGroupLabel>
                        </PrependAppendInputGroup>
                    </CalculatorExtraDiv>
                )}
            </>
        );
    };

    private renderSecondQuestion = (): JSX.Element => {
        const { formErrorID, draperyOrderDesign, highlightedSection } =
            this.props;
        const selectedPanel = panelsConfiguration.filter(
            (el) => el.name === draperyOrderDesign.panelType
        )[0];
        return (
            <>
                <CalculatorFormField
                    id="2"
                    uiInline
                    uiNoControlWrap
                    uiNoMargin
                    isError={
                        highlightedSection === "draperyOrderDesign.panelType"
                    }
                >
                    <CalculatorFormFieldHeader>
                        <CalculatorFormQuestionNumber
                            uiError={formErrorID === "2"}
                        >
                            2
                        </CalculatorFormQuestionNumber>
                        <CalculatorFormFieldLabel>
                            Choose your panel configuration
                        </CalculatorFormFieldLabel>
                        {selectedPanel && draperyOrderDesign.category === 1 && (
                            <PanelConfRow>
                                <span className="subtitle">
                                    {selectedPanel.title}
                                </span>
                                <FooterCancelBtn
                                    type="button"
                                    uiColor="primary"
                                    disabled={
                                        this.props.workOrderEditMode
                                            ? false
                                            : this.props.disabled
                                    }
                                    uiDisabled={
                                        this.props.workOrderEditMode
                                            ? false
                                            : this.props.disabled
                                    }
                                    onClick={() =>
                                        this.props.resetPanelConf(
                                            draperyOrderDesign.category,
                                            draperyOrderDesign.panelType
                                        )
                                    }
                                >
                                    Change
                                </FooterCancelBtn>
                            </PanelConfRow>
                        )}
                    </CalculatorFormFieldHeader>
                    {!draperyOrderDesign.panelType
                        ? this.renderPanelConfSelection()
                        : draperyOrderDesign.category === 1
                        ? this.renderPanelConfCheked()
                        : this.renderPanelConfSelection()}
                </CalculatorFormField>
            </>
        );
    };

    private renderThirdQuestion = (): JSX.Element => {
        const { /*formErrorID,*/ draperyOrderDesign, highlightedSection } =
            this.props;
        return (
            <CalculatorFormField
                id="3"
                uiInline
                uiNoControlWrap
                uiNoMargin
                isError={
                    highlightedSection === "draperyOrderDesign.draperyType"
                }
            >
                <CalculatorFormFieldHeader>
                    <span
                        className="subtitle"
                        style={{ display: "inline-flex", alignItems: "center" }}
                    >
                        Drapery Type
                    </span>
                </CalculatorFormFieldHeader>
                <CalculatorRadioGroupWrap>
                    <CalculatorRadioWrapperWithImg
                        uiChecked={
                            draperyOrderDesign.draperyType === "full-length"
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    >
                        <CalculatorImageRadio
                            type="radio"
                            id="draperyStyleType-full-length"
                            name="draperyOrderDesign.draperyType"
                            value="full-length"
                            checked={
                                draperyOrderDesign.draperyType === "full-length"
                            }
                            onClick={this.props.handleInputChangeForString}
                            disabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                            uiDisabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                        />
                        <DraperyTypeFullLength />
                        FULL LENGTH
                    </CalculatorRadioWrapperWithImg>
                    <CalculatorRadioWrapperWithImg
                        uiChecked={
                            draperyOrderDesign.draperyType === "cafe-curtain"
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    >
                        <CalculatorImageRadio
                            type="radio"
                            id="draperyStyleType-cafe-curtain"
                            name="draperyOrderDesign.draperyType"
                            value="cafe-curtain"
                            checked={
                                draperyOrderDesign.draperyType ===
                                "cafe-curtain"
                            }
                            onClick={this.props.handleInputChangeForString}
                            disabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                            uiDisabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                        />
                        <DraperyTypeCafeCurtain />
                        CAFE CURTAIN
                    </CalculatorRadioWrapperWithImg>
                </CalculatorRadioGroupWrap>
            </CalculatorFormField>
        );
    };

    private renderFifthQuestion = (): JSX.Element => {
        const { formErrorID, draperyOrderDesign, highlightedSection } =
            this.props;
        return (
            <CalculatorFormField
                id="5"
                uiInline
                uiNoControlWrap
                uiNoMargin
                isError={
                    highlightedSection === "draperyOrderDesign.headingType"
                }
            >
                <CalculatorFormFieldHeader>
                    <CalculatorFormQuestionNumber uiError={formErrorID === "5"}>
                        3
                    </CalculatorFormQuestionNumber>
                    <CalculatorFormFieldLabel>
                        Heading Type
                    </CalculatorFormFieldLabel>
                </CalculatorFormFieldHeader>
                <CalculatorRadioGroupWrap>
                    <CalculatorRadioWrapperWithImg
                        uiChecked={
                            draperyOrderDesign.headingType === "ripplefold"
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    >
                        <CalculatorImageRadio
                            type="radio"
                            id="headingType"
                            name="draperyOrderDesign.headingType"
                            value="ripplefold"
                            checked={
                                draperyOrderDesign.headingType === "ripplefold"
                            }
                            onClick={this.props.handleInputChangeForString}
                            disabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                        />
                        <img
                            style={{ height: "66px", objectFit: "contain" }}
                            src={HeadingTypeRipplefold}
                        />
                        RIPPLEFOLD
                    </CalculatorRadioWrapperWithImg>

                    <CalculatorRadioWrapperWithImg
                        uiChecked={
                            draperyOrderDesign.headingType === "rodandrings"
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    >
                        <CalculatorImageRadio
                            type="radio"
                            id="headingType"
                            name="draperyOrderDesign.headingType"
                            value="rodandrings"
                            checked={
                                draperyOrderDesign.headingType === "rodandrings"
                            }
                            onClick={this.props.handleInputChangeForString}
                            disabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                        />
                        <img src={HeadingTypeRodRings} />
                        ROD AND RINGS
                    </CalculatorRadioWrapperWithImg>
                </CalculatorRadioGroupWrap>
                {draperyOrderDesign.headingType === "rodandrings" && (
                    <>
                        {this.renderPleatTypeList()}
                        {this.renderThirdQuestion()}
                    </>
                )}
                {draperyOrderDesign.headingType === "ripplefold" &&
                    this.renderRipplefoldDetails()}
            </CalculatorFormField>
        );
    };
    private renderSixthQuestion = (): JSX.Element => {
        const { formErrorID, draperyOrderDesign, highlightedSection } =
            this.props;
        return (
            <CalculatorFormField
                id="6"
                uiInline
                uiNoMargin
                isError={
                    highlightedSection ===
                    "draperyOrderDesign.finishedLengthOfPanels"
                }
            >
                <CalculatorFormFieldHeader>
                    <CalculatorFormQuestionNumber uiError={formErrorID === "6"}>
                        4
                    </CalculatorFormQuestionNumber>
                    <CalculatorFormFieldLabel>
                        Finished Length of Panels
                    </CalculatorFormFieldLabel>
                </CalculatorFormFieldHeader>
                <RegularInputGroup>
                    <PrependAppendInput
                        type="number"
                        id="draperyOrderDesign.finishedLengthOfPanels"
                        name="draperyOrderDesign.finishedLengthOfPanels"
                        autoComplete="off"
                        value={
                            draperyOrderDesign.finishedLengthOfPanels ===
                                undefined ||
                            draperyOrderDesign.finishedLengthOfPanels === -1
                                ? ""
                                : draperyOrderDesign.finishedLengthOfPanels
                        }
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        onKeyDown={(evt) => {
                            const key = evt.key;
                            const isNumericKey = /\d/.test(key);
                            const isDotKey = key === ".";
                            const isBackspaceKey = key === "Backspace";
                            if (!isNumericKey && !isDotKey && !isBackspaceKey) {
                                evt.preventDefault();
                            }
                        }}
                    />
                    <RegularInputGroupLabel>inches</RegularInputGroupLabel>
                </RegularInputGroup>
            </CalculatorFormField>
        );
    };

    private renderSeventhQuestion = (): JSX.Element => {
        // const { formErrorID, addNewLining, liningsList, highlightedSection } =
        //     this.props;
        const { formErrorID, liningsList, highlightedSection } = this.props;

        const isLiningActive = liningsList.some((lining) => lining.active);
        const isNoneLinings = this.props.isNoneLinings;

        return (
            <CalculatorFormField
                id="7"
                uiInline
                uiNoMargin
                isError={highlightedSection === "isNoneLinings"}
            >
                <CalculatorFormFieldHeader>
                    <CalculatorFormQuestionNumber uiError={formErrorID === "7"}>
                        5
                    </CalculatorFormQuestionNumber>
                    <CalculatorFormFieldLabel>
                        Select Linings
                    </CalculatorFormFieldLabel>
                </CalculatorFormFieldHeader>
                <LiningsWrap>
                    <CheckboxInputWrap>
                        <CalculatorFieldInput>
                            <CalculatorFormLabel>
                                <CalculatorCheckbox
                                    id="isNoneLinings"
                                    name="isNoneLinings"
                                    checked={isNoneLinings}
                                    onChange={(e) => {
                                        this.props.handleInputChangeForBoolean(
                                            e
                                        );
                                        this.props.resetLinings();
                                    }}
                                    disabled={
                                        this.props.workOrderEditMode
                                            ? false
                                            : this.props.disabled
                                    }
                                    uiDisabled={
                                        this.props.workOrderEditMode
                                            ? false
                                            : this.props.disabled
                                    }
                                />
                                None
                            </CalculatorFormLabel>
                        </CalculatorFieldInput>
                        {liningsList.map((el, i) => {
                            return (
                                <CalculatorFieldInput key={el.name}>
                                    <CalculatorFormLabel>
                                        <CalculatorCheckbox
                                            id={"liningsList." + i + ".active"}
                                            name={
                                                "liningsList." + i + ".active"
                                            }
                                            checked={
                                                el.active && isLiningActive
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForBoolean
                                            }
                                            disabled={
                                                this.props.workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                this.props.workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        {el.name}
                                    </CalculatorFormLabel>
                                </CalculatorFieldInput>
                            );
                        })}
                    </CheckboxInputWrap>
                    {/* <AddLiningButton
                        type="button"
                        onClick={addNewLining}
                        uiColor="primary"
                        disabled={this.props.disabled || isNoneLinings}
                        uiDisabled={this.props.disabled || isNoneLinings}
                    >
                        Add More
                    </AddLiningButton> */}
                </LiningsWrap>
            </CalculatorFormField>
        );
    };

    private renderEighthQuestion = (): JSX.Element => {
        const {
            formErrorID,
            embellishmentsList,
            isNoneEmbellishment,
            highlightedSection,
            orderType,
        } = this.props;

        const isEmbellishmentActive = embellishmentsList.some(
            (embellishment) => embellishment.checked
        );

          // if roman shades
          let embelishmentsFilter = []
          if (orderType === 'roman-shade-order') {
              embelishmentsFilter = embellishmentsList.filter((item: any) => !['bandings', 'cordings', 'flanges'].includes(item.stateName))
          } else {
              embelishmentsFilter = [...embellishmentsList]
          }

        return (
            <CalculatorFormField
                id="8"
                uiInline
                uiNoMargin
                isError={highlightedSection === "isNoneEmbellishment"}
            >
                <CalculatorFormFieldHeader>
                    <CalculatorFormQuestionNumber uiError={formErrorID === "8"}>
                        6
                    </CalculatorFormQuestionNumber>
                    <CalculatorFormFieldLabel>
                        Select Embellishments
                    </CalculatorFormFieldLabel>
                    <TooltipPopup
                        text={
                            <TooltipContainer>
                                <TooltipHeader>BANDING</TooltipHeader>
                                <TooltipText>
                                    Fabric (usually a contrast fabric) seamed to
                                    the main fabric around the border or inset
                                    within the drapery.
                                </TooltipText>
                                <TooltipHeader>Flange</TooltipHeader>
                                <TooltipText>
                                    A strip of fabric that is folded in half and
                                    sewn into the edge of the drapery.
                                </TooltipText>
                                <TooltipHeader>Trim</TooltipHeader>
                                <TooltipText>
                                    Strips of fabric or pre-made trim applied on
                                    top of the main fabric.
                                </TooltipText>
                                <TooltipHeader>Cording</TooltipHeader>
                                <TooltipText>
                                    Rope that is sewn into a seam or edge. This
                                    can be a pre-made decorative cording or welt
                                    cording (non-decorative cording covered with
                                    fabric).
                                </TooltipText>
                            </TooltipContainer>
                        }
                        position="top left"
                        className="floatRight"
                        size="500"
                    />
                </CalculatorFormFieldHeader>
                <HContainer
                    style={{ padding: "30px 0px" }}
                    className="flex-col lg:flex-row items-start lg:items-center"
                >
                    <CalculatorFormLabel key={-1}>
                        <CalculatorCheckbox
                            id="isNoneEmbellishment"
                            name="isNoneEmbellishment"
                            checked={isNoneEmbellishment}
                            onChange={(e) => {
                                this.props.handleInputChangeForBoolean(e);
                                this.props.resetDraperyOrderEmbellishments();
                            }}
                            disabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                            uiDisabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                        />
                        None
                    </CalculatorFormLabel>
                    {embelishmentsFilter.map((el, i) => (
                        <CalculatorFormLabel key={i}>
                            <CalculatorCheckbox
                                id={`embellishmentsList.${i}.checked`}
                                name={`embellishmentsList.${i}.checked`}
                                checked={el.checked && isEmbellishmentActive}
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            {el.title}
                        </CalculatorFormLabel>
                    ))}
                </HContainer>
                {embelishmentsFilter.some((el) => el.checked === true) && (
                    <AdditionalFormContainer>
                        {embelishmentsFilter.map(this.renderEmbellishments)}
                    </AdditionalFormContainer>
                )}
            </CalculatorFormField>
        );
    };

    private renderNinthQuestion = () => {
        const { formErrorID, highlightedSection, draperyOrderDesign } =
            this.props;

        return (
            <CalculatorFormField
                id="9"
                uiInline
                uiNoMargin
                isError={highlightedSection === "isNoneEmbellishment"}
            >
                <CalculatorFormFieldHeader>
                    <CalculatorFormQuestionNumber uiError={formErrorID === "8"}>
                        7
                    </CalculatorFormQuestionNumber>
                    <CalculatorFormFieldLabel>
                        Select Add-Ons
                    </CalculatorFormFieldLabel>
                </CalculatorFormFieldHeader>
                <HContainer
                    style={{ padding: "30px 0px" }}
                    className="flex-col lg:flex-col items-start lg:items-start"
                >
                    <div className="flex">
                        <CalculatorFormLabel>
                            <CalculatorCheckbox
                                name="draperyOrderDesign.isPinSet"
                                checked={draperyOrderDesign.isPinSet}
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Workroom to add drapery pins
                        </CalculatorFormLabel>
                        {draperyOrderDesign.isPinSet && (
                            <CalculatorFieldInput uiWidth={250}>
                                <CalculatorFormLabel className="ml">
                                    Pin Set
                                </CalculatorFormLabel>
                                <CalculatorInput
                                    id="draperyOrderDesign.pinSetText"
                                    name="draperyOrderDesign.pinSetText"
                                    type="text"
                                    value={draperyOrderDesign.pinSetText || ""}
                                    onChange={
                                        this.props.handleInputChangeForString
                                    }
                                    placeholder="Please specify..."
                                    pattern="banana|cherry"
                                    maxLength={10}
                                    disabled={
                                        this.props.workOrderEditMode
                                            ? false
                                            : this.props.disabled
                                    }
                                    uiDisabled={
                                        this.props.workOrderEditMode
                                            ? false
                                            : this.props.disabled
                                    }
                                />
                            </CalculatorFieldInput>
                        )}
                    </div>
                    <div className="flex">
                        <CalculatorFormLabel>
                            <CalculatorCheckbox
                                name="draperyOrderDesign.isWeight"
                                checked={draperyOrderDesign.isWeight}
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                onChange={
                                    this.props.handleInputChangeForBoolean
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Weights
                        </CalculatorFormLabel>

                        <CalculatorRadioWrapper>
                            <CalculatorRadio
                                type="radio"
                                name="draperyOrderDesign.weightRadio"
                                value={"standard-corner"}
                                checked={
                                    draperyOrderDesign.isWeight &&
                                    draperyOrderDesign.weightRadio ===
                                        "standard-corner"
                                }
                                onChange={this.props.handleInputChangeForString}
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Standard (corner)
                        </CalculatorRadioWrapper>

                        <CalculatorRadioWrapper>
                            <CalculatorRadio
                                type="radio"
                                name="draperyOrderDesign.weightRadio"
                                value={"beaded-chain"}
                                checked={
                                    draperyOrderDesign.isWeight &&
                                    draperyOrderDesign.weightRadio ===
                                        "beaded-chain"
                                }
                                onChange={this.props.handleInputChangeForString}
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Beaded chain
                        </CalculatorRadioWrapper>
                    </div>
                </HContainer>
            </CalculatorFormField>
        );
    };

    private renderRipplefoldDetails = () => {
        const { draperyOrderDesign, highlightedSection } = this.props;

        return (
            <RipplefoldOptions>
                <RipplefoldOptionsHeader>
                    <span style={{ fontWeight: "600" }}>
                        Ripplefold Details
                    </span>
                </RipplefoldOptionsHeader>

                <RipplefoldOptionsRow
                    isError={
                        highlightedSection ===
                        "draperyOrderDesign.ripplefoldOptions.controlType"
                    }
                >
                    <div style={{ minWidth: "150px" }}>
                        <span>Control Type</span>
                    </div>
                    <div style={{ gap: "15px" }} className="flex items-center">
                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="control-type-cord"
                                name="draperyOrderDesign.ripplefoldOptions.controlType"
                                value={"cord"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlType === "cord"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlType",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlType
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Cord
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="controlType"
                                name="draperyOrderDesign.ripplefoldOptions.controlType"
                                value={"motor"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlType === "motor"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlType",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlType
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Motor
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="control-type-wand"
                                name="draperyOrderDesign.ripplefoldOptions.controlType"
                                value={"wand"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlType === "wand"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlType",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlType
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Wand
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="control-type-hand"
                                name="draperyOrderDesign.ripplefoldOptions.controlType"
                                value={"hand"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlType === "hand"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlType",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlType
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Hand (no wand)
                        </RadioLabel>
                    </div>
                </RipplefoldOptionsRow>

                <RipplefoldOptionsRow>
                    <div style={{ minWidth: "150px" }}>
                        <span>Control Location</span>
                    </div>
                    <div style={{ gap: "15px" }} className="flex items-center">
                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="control-location-left"
                                name="draperyOrderDesign.ripplefoldOptions.controlLocation"
                                value={"left"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlLocation === "left"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlLocation",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlLocation
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Left
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="control-location-right"
                                name="draperyOrderDesign.ripplefoldOptions.controlLocation"
                                value={"right"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlLocation === "right"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlLocation",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlLocation
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Right
                        </RadioLabel>

                        <RadioLabel style={{ marginRight: "5px" }}>
                            <RadioInput
                                type="radio"
                                id="control-location-other"
                                name="draperyOrderDesign.ripplefoldOptions.controlLocation"
                                value={"other"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlLocation !== null &&
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlLocation !== "left" &&
                                    draperyOrderDesign.ripplefoldOptions
                                        .controlLocation !== "right"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "controlLocation",
                                        draperyOrderDesign.ripplefoldOptions
                                            .controlLocation
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Other
                        </RadioLabel>
                        {draperyOrderDesign.ripplefoldOptions
                            .controlLocation !== null &&
                            draperyOrderDesign.ripplefoldOptions
                                .controlLocation !== "left" &&
                            draperyOrderDesign.ripplefoldOptions
                                .controlLocation !== "right" && (
                                <CalculatorFieldInput uiWidth={200}>
                                    <CalculatorInput
                                        id="draperyOrderDesign.ripplefoldOptions.controlLocation"
                                        name="draperyOrderDesign.ripplefoldOptions.controlLocation"
                                        type="text"
                                        value={
                                            draperyOrderDesign.ripplefoldOptions
                                                ? draperyOrderDesign
                                                      .ripplefoldOptions
                                                      .controlLocation
                                                : ""
                                        }
                                        onChange={
                                            this.props
                                                .handleInputChangeForString
                                        }
                                        placeholder="Enter"
                                        disabled={
                                            this.props.workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                        uiDisabled={
                                            this.props.workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                        maxLength={20}
                                    />
                                </CalculatorFieldInput>
                            )}
                    </div>
                </RipplefoldOptionsRow>

                <RipplefoldOptionsRow
                    isError={
                        highlightedSection ===
                        "draperyOrderDesign.ripplefoldOptions.masterCarrier"
                    }
                >
                    <div style={{ minWidth: "150px" }}>
                        <span>Master Carrier</span>
                    </div>
                    <div style={{ gap: "15px" }} className="flex items-center">
                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="master-carrier-butt"
                                name="draperyOrderDesign.ripplefoldOptions.masterCarrier"
                                value={"butt"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .masterCarrier === "butt"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "masterCarrier",
                                        draperyOrderDesign.ripplefoldOptions
                                            .masterCarrier
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Butt
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="master-carrier-overlap"
                                name="draperyOrderDesign.ripplefoldOptions.masterCarrier"
                                value={"overlap"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .masterCarrier === "overlap"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "masterCarrier",
                                        draperyOrderDesign.ripplefoldOptions
                                            .masterCarrier
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Overlap
                        </RadioLabel>
                    </div>
                </RipplefoldOptionsRow>

                <RipplefoldOptionsRow>
                    <div style={{ minWidth: "150px" }}>
                        <span>Mount</span>
                    </div>
                    <div style={{ gap: "15px" }} className="flex items-center">
                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="mount-ceiling"
                                name="draperyOrderDesign.ripplefoldOptions.mount"
                                value={"ceiling"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .mount === "ceiling"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "mount",
                                        draperyOrderDesign.ripplefoldOptions
                                            .mount
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Ceiling
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="mount-wall"
                                name="draperyOrderDesign.ripplefoldOptions.mount"
                                value={"wall"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .mount === "wall"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "mount",
                                        draperyOrderDesign.ripplefoldOptions
                                            .mount
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Wall
                        </RadioLabel>
                    </div>
                </RipplefoldOptionsRow>

                <RipplefoldOptionsRow>
                    <div style={{ minWidth: "150px" }}>
                        <span>Header Tape</span>
                    </div>
                    <div style={{ gap: "15px" }} className="flex items-center">
                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="header-type-snap-tape"
                                name="draperyOrderDesign.ripplefoldOptions.headerTape"
                                value={"snap_tape"}
                                checked={
                                    draperyOrderDesign &&
                                    draperyOrderDesign.ripplefoldOptions &&
                                    draperyOrderDesign.ripplefoldOptions
                                        .headerTape === "snap_tape"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "headerTape",
                                        draperyOrderDesign.ripplefoldOptions
                                            .headerTape
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Snap Tape (standard)
                        </RadioLabel>

                        <RadioLabel>
                            <RadioInput
                                type="radio"
                                id="header-type-wave-tape"
                                name="draperyOrderDesign.ripplefoldOptions.headerTape"
                                value={"wave_tape"}
                                checked={
                                    draperyOrderDesign &&
                                    draperyOrderDesign.ripplefoldOptions &&
                                    draperyOrderDesign.ripplefoldOptions
                                        .headerTape === "wave_tape"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "headerTape",
                                        draperyOrderDesign.ripplefoldOptions
                                            .headerTape
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Wave Tape
                        </RadioLabel>

                        <RadioLabel style={{ marginRight: "5px" }}>
                            <RadioInput
                                type="radio"
                                id="header-type-other"
                                name="draperyOrderDesign.ripplefoldOptions.headerTape"
                                value={"other"}
                                checked={
                                    draperyOrderDesign.ripplefoldOptions
                                        .headerTape !== null &&
                                    draperyOrderDesign.ripplefoldOptions
                                        .headerTape !== "wave_tape" &&
                                    draperyOrderDesign.ripplefoldOptions
                                        .headerTape !== "snap_tape"
                                }
                                onClick={() =>
                                    this.props.handleRemoveRipplefoldDetail(
                                        "headerTape",
                                        draperyOrderDesign.ripplefoldOptions
                                            .headerTape
                                    )
                                }
                                onChange={
                                    this.props.handleChangeRipplefoldOptions
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            Other
                        </RadioLabel>

                        {draperyOrderDesign.ripplefoldOptions.headerTape !==
                            null &&
                            draperyOrderDesign.ripplefoldOptions.headerTape !==
                                "snap_tape" &&
                            draperyOrderDesign.ripplefoldOptions.headerTape !==
                                "wave_tape" && (
                                <CalculatorFieldInput uiWidth={140}>
                                    <CalculatorInput
                                        id="draperyOrderDesign.ripplefoldOptions.headerTape"
                                        name="draperyOrderDesign.ripplefoldOptions.headerTape"
                                        type="text"
                                        value={
                                            draperyOrderDesign &&
                                            draperyOrderDesign.ripplefoldOptions &&
                                            draperyOrderDesign.ripplefoldOptions
                                                .headerTape
                                                ? draperyOrderDesign
                                                      .ripplefoldOptions
                                                      .headerTape
                                                : ""
                                        }
                                        onChange={
                                            this.props
                                                .handleInputChangeForString
                                        }
                                        placeholder="Enter"
                                        disabled={
                                            this.props.workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                        uiDisabled={
                                            this.props.workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                        maxLength={12}
                                    />
                                </CalculatorFieldInput>
                            )}
                    </div>
                </RipplefoldOptionsRow>
            </RipplefoldOptions>
        );
    };

    private renderConstructionDiv = () => {
        const { highlightedSection } = this.props;
        return (
            <div>
                <CustomizeConstructionDiv
                    id="customize-construction-div"
                    onClick={this.handleCustomizeConstruction}
                >
                    {this.props.isCustomizeConstructionOpen && (
                        <>
                            <FaAngleUp />
                            &nbsp; Construction Details
                        </>
                    )}
                    {!this.props.isCustomizeConstructionOpen && (
                        <>
                            <FaAngleDown />
                            &nbsp; Construction Details
                        </>
                    )}
                </CustomizeConstructionDiv>
                {this.props.isCustomizeConstructionOpen && (
                    <DrapperyOrderWorkroomSettings
                        draperyOrderDesign={this.props.draperyOrderDesign}
                        handleBuckramSelect={this.props.handleBuckramSelect}
                        handleFullnessSelect={this.props.handleFullnessSelect}
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        highlightedSection={highlightedSection}
                        handleInputChangeForNumber={
                            this.props.handleInputChangeForNumber
                        }
                        handleInputChangeForString={
                            this.props.handleInputChangeForString
                        }
                        handleInputChangeForBoolean={
                            this.props.handleInputChangeForBoolean
                        }
                        handleSelectListChange={
                            this.props.handleSelectListChange
                        }
                        formErrorID={this.props.formErrorID}
                        embellishmentsList={this.props.embellishmentsList}
                        data={this.props.data}
                        workroomSetting={this.props.workroomSetting}
                        openConstructionDetailsEditModal={
                            this.props.openConstructionDetailsEditModal
                        }
                    />
                )}
            </div>
        );
    };

    private renderEmbellishments = (
        embellishmentInfo: EmbellishmentCheckInfo,
        index: number
    ) => {
        const { list, name } = this.props.getEmbellishmentsByIndex(index);
        if (!embellishmentInfo.checked || !list.length) {
            return <></>;
        }
        return list.map((e, i) => this.renderEmbellishment(e, i, name, list));
    };

    private renderEmbellishment = (
        embellishment: DraperyOrderDesignEmbellishment,
        index: number,
        targetArray: string,
        list: DraperyOrderDesignEmbellishment[]
    ) => {
        return (
            <AdditionalForm
                key={targetArray + index}
                targetArray={targetArray}
                targetArrayIndex={index}
                embellishment={embellishment}
                addEmbellishment={this.props.addEmbellishment}
                deleteEmbellishment={this.props.deleteEmbellishment}
                differentiate={list.length > 1 && index + 1}
                disabled={
                    this.props.workOrderEditMode ? false : this.props.disabled
                }
                handleInputChangeForNumber={
                    this.props.handleInputChangeForNumber
                }
                handleInputChangeForString={
                    this.props.handleInputChangeForString
                }
                handleInputChangeForBoolean={
                    this.props.handleInputChangeForBoolean
                }
                handleInputChangeForNumberPrice={
                    this.props.handleInputChangeForNumberPrice
                }
                handleInputBlur={this.props.handleInputBlur}
                handleSelectListChange={this.props.handleSelectListChange}
                updateStateByName={this.props.updateStateByName}
                highlightedSection={this.props.highlightedSection}
                numberingTrims={this.props.numberingTrims}
            />
        );
    };

    private renderDrawingSection = (): JSX.Element => {
        return (
            <CalculatorFormField
                id="drawing-section"
                uiInline
                uiNoMargin
                noPadding
            >
                <DrawingContainer>
                    <Drawing
                        shapes={this.props.draperyOrderDesign.drawingShapeJson}
                        category={this.props.draperyOrderDesign.category}
                        panelType={this.props.draperyOrderDesign.panelType}
                        panelJson={this.props.draperyOrderDesign.panelJson}
                        rodWidth={this.props.draperyOrderDesign.rodWidth}
                        finishedLengthOfPanels={
                            this.props.draperyOrderDesign.finishedLengthOfPanels
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        onShapeChanged={this.props.onShapeChanged}
                        ref={this.drawingRef}
                    />
                </DrawingContainer>
            </CalculatorFormField>
        );
    };

    private renderNotesSection = (): JSX.Element => {
        const { draperyOrderDesign } = this.props;
        const leftLetters = 140 - (draperyOrderDesign.notes || "").length;
        return (
            <CalculatorFormField id="notes-section" uiInline uiNoMargin>
                <NotesContainer>
                    <TextArea
                        style={{
                            border: "1px solid #B3B3B3",
                            width: "76%",
                            height: "211px",
                        }}
                        name="draperyOrderDesign.notes"
                        onChange={this.props.handleInputChangeForString}
                        value={draperyOrderDesign.notes}
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <NoteLetters>{leftLetters} letters left</NoteLetters>
                </NotesContainer>
            </CalculatorFormField>
        );
    };

    private renderPanelConfSelection = (): JSX.Element => {
        const { draperyOrderDesign } = this.props;
        return (
            <CalculatorRadioGroupWrap>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "center"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-center"
                        name="draperyOrderDesign.panelType"
                        value="center"
                        checked={draperyOrderDesign.panelType === "center"}
                        onClick={this.props.handleInputChangeForString}
                        onChange={() => {
                            draperyOrderDesign.category === 0 &&
                                this.props.resetPanelConf(
                                    draperyOrderDesign.panelType
                                );
                        }}
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfigurationSingleCenterPanel />
                    SINGLE CENTER
                </CalculatorRadioWrapperWithImg>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "left"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-left"
                        name="draperyOrderDesign.panelType"
                        value="left"
                        checked={draperyOrderDesign.panelType === "left"}
                        onClick={this.props.handleInputChangeForString}
                        onChange={() =>
                            draperyOrderDesign.category === 0 &&
                            this.props.resetPanelConf(
                                draperyOrderDesign.panelType
                            )
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfigurationSingleLeftPanel />
                    LEFT
                </CalculatorRadioWrapperWithImg>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "right"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-right"
                        name="draperyOrderDesign.panelType"
                        value="right"
                        checked={draperyOrderDesign.panelType === "right"}
                        onClick={this.props.handleInputChangeForString}
                        onChange={() =>
                            draperyOrderDesign.category === 0 &&
                            this.props.resetPanelConf(
                                draperyOrderDesign.panelType
                            )
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfigurationSingleRightPanel />
                    RIGHT
                </CalculatorRadioWrapperWithImg>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "pair"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-pair"
                        name="draperyOrderDesign.panelType"
                        value="pair"
                        checked={draperyOrderDesign.panelType === "pair"}
                        onClick={this.props.handleInputChangeForString}
                        onChange={() =>
                            draperyOrderDesign.category === 0 &&
                            this.props.resetPanelConf(
                                draperyOrderDesign.panelType
                            )
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfigurationPair />
                    PAIR
                </CalculatorRadioWrapperWithImg>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "three-panels"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-three-panels"
                        name="draperyOrderDesign.panelType"
                        value="three-panels"
                        checked={
                            draperyOrderDesign.panelType === "three-panels"
                        }
                        onClick={this.props.handleInputChangeForString}
                        onChange={() =>
                            draperyOrderDesign.category === 0 &&
                            this.props.resetPanelConf(
                                draperyOrderDesign.panelType
                            )
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfiguration3Panels />3 PANELS
                </CalculatorRadioWrapperWithImg>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "four-panels"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-four-panels"
                        name="draperyOrderDesign.panelType"
                        value="four-panels"
                        checked={draperyOrderDesign.panelType === "four-panels"}
                        onClick={this.props.handleInputChangeForString}
                        onChange={() =>
                            draperyOrderDesign.category === 0 &&
                            this.props.resetPanelConf(
                                draperyOrderDesign.panelType
                            )
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfiguration4Panels />4 PANELS
                </CalculatorRadioWrapperWithImg>
                <CalculatorRadioWrapperWithImg
                    uiChecked={draperyOrderDesign.panelType === "five-panels"}
                    uiDisabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="panelConf-five-panels"
                        name="draperyOrderDesign.panelType"
                        value="five-panels"
                        checked={draperyOrderDesign.panelType === "five-panels"}
                        onClick={this.props.handleInputChangeForString}
                        onChange={() =>
                            draperyOrderDesign.category === 0 &&
                            this.props.resetPanelConf(
                                draperyOrderDesign.panelType
                            )
                        }
                        disabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                        uiDisabled={
                            this.props.workOrderEditMode
                                ? false
                                : this.props.disabled
                        }
                    />
                    <DraperyConfiguration5Panels />5 PANELS
                </CalculatorRadioWrapperWithImg>
            </CalculatorRadioGroupWrap>
        );
    };

    private renderPanelConfCheked = (): JSX.Element => {
        const { draperyOrderDesign } = this.props;
        const selectedPanel = panelsConfiguration.filter(
            (el) => el.name === draperyOrderDesign.panelType
        )[0];
        return (
            <PanelConfWrapper>
                {draperyOrderDesign.category === 1 && !this.props.disabled && (
                    <span className="choose-panel-width-desc">
                        Choose number of widths for each panel below.
                    </span>
                )}
                <PanelsContainer>
                    <RatioPanelsContainer>
                        <PanelSVGContainer>
                            {this.renderPanelsBackground(selectedPanel)}
                        </PanelSVGContainer>
                    </RatioPanelsContainer>
                </PanelsContainer>
                {this.props.draperyOrderDesign.category === 1 &&
                    this.renderPanelWidthContainer(selectedPanel)}
            </PanelConfWrapper>
        );
    };

    private renderPleatTypeList = (): JSX.Element => {
        const { draperyOrderDesign } = this.props;
        const { pleatTypeListNew } = this.state;
        // const draperyItemDetailsFields = this.props.draperyItemDetail[0];
        // const openPleatModal = (event: { preventDefault: () => void; }) => {
        //     event.preventDefault();
        //     this.setState({isPleatModalOpen: true})
        // };

        // const closePleatModal = () => {
        //     this.setState({isPleatModalOpen: false});
        // };

        return (
            <CalculatorRadioGroupWrap
                className={
                    this.props.highlightedSection ===
                    "draperyOrderDesign.pleatType"
                        ? "isError"
                        : ""
                }
            >
                <span
                    className="subtitle"
                    style={{ display: "inline-flex", alignItems: "center" }}
                >
                    Pleat Type
                    <TooltipPopup
                        text={
                            <TooltipContainer>
                                <TooltipText>
                                    Custom Pleats can be added through My Shop →
                                    Fabrication
                                    <span
                                        style={{
                                            borderLeft: "1px solid black",
                                            margin: "0 5px",
                                        }}
                                    ></span>
                                    Drapery → Pricing Tab → Header/Pleat type →
                                    Add New Pleat
                                </TooltipText>
                            </TooltipContainer>
                        }
                        position="top left"
                        className="normal"
                    />
                </span>
                {pleatTypeListNew
                    .filter((pleatType) => pleatType.active === true)
                    .map((el, i) => {
                        if (el.type === draperyOrderDesign.headingType) {
                            if (i <= 6) {
                                return (
                                    <CalculatorRadioWrapperWithImg
                                        uiChecked={
                                            draperyOrderDesign.pleatType ===
                                            el.value
                                        }
                                        key={el.value}
                                        uiDisabled={
                                            this.props.workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                    >
                                        <CalculatorImageRadio
                                            type="radio"
                                            id="draperyPleatType"
                                            name="draperyOrderDesign.pleatType"
                                            value={el.value}
                                            checked={
                                                draperyOrderDesign.pleatType ===
                                                el.value
                                            }
                                            onClick={
                                                el.value === "OTHER"
                                                    ? () =>
                                                          this.handleOtherPleatType(
                                                              el.image
                                                          )
                                                    : this.props
                                                          .handleInputChangeForString
                                            }
                                            disabled={
                                                this.props.workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                this.props.workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        {i === 0 && (
                                            <>
                                                <PleatTypeNoneFlat />
                                                NONE (FLAT)
                                            </>
                                        )}
                                        {i === 1 && (
                                            <>
                                                <PleatTypeEuro2Finger />
                                                EURO (2 FINGER)
                                            </>
                                        )}
                                        {i === 2 && (
                                            <>
                                                <PleatTypeEuro3Finger />
                                                EURO (3 FINGER)
                                            </>
                                        )}
                                        {i === 3 && (
                                            <>
                                                <PleatTypeGoblet />
                                                GOBLET
                                            </>
                                        )}
                                        {i === 4 && (
                                            <>
                                                <PleatTypeInverted />
                                                INVERTED BOX
                                            </>
                                        )}
                                        {i === 5 && (
                                            <>
                                                <PleatTypePinch2Finger />
                                                PINCH (2 FINGER)
                                            </>
                                        )}
                                        {i === 6 && (
                                            <>
                                                <PleatTypePinch3Finger />
                                                PINCH (3 FINGER)
                                            </>
                                        )}
                                    </CalculatorRadioWrapperWithImg>
                                );
                            } else {
                                return (
                                    <CalculatorRadioWrapperWithImg
                                        uiChecked={
                                            draperyOrderDesign.pleatType ===
                                            el.value
                                        }
                                        key={el.value}
                                        uiDisabled={
                                            this.props.workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                    >
                                        <CalculatorImageRadio
                                            type="radio"
                                            id="draperyPleatType"
                                            name="draperyOrderDesign.pleatType"
                                            value={el.value}
                                            checked={
                                                draperyOrderDesign.pleatType ===
                                                el.value
                                            }
                                            onClick={(e) => {
                                                this.handleOtherPleatType(
                                                    el.image
                                                );
                                                this.props.handleInputChangeForString(
                                                    e
                                                );
                                            }}
                                            disabled={
                                                this.props.workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                this.props.workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        {el.image && (
                                            <CalculatorImageRadioImg
                                                src={
                                                    Config.fileStoreUrl +
                                                    el.image
                                                }
                                                alt=""
                                                style={{
                                                    width: "133px",
                                                    height: "72px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        )}
                                        <span
                                            style={{
                                                textTransform: "uppercase",
                                                margin: el.image
                                                    ? "0 0 0 8px"
                                                    : "auto 0",
                                                textAlign: "center",
                                            }}
                                        >
                                            {el.title.length > 14
                                                ? el.title.slice(0, 14) + ""
                                                : el.title}
                                        </span>
                                    </CalculatorRadioWrapperWithImg>
                                );
                            }
                        }
                    })}
                {this.props.data &&
                    this.props.data.workOrder &&
                    this.props.data.workOrder.id > 0 &&
                    this.props.data.workOrderPdfContentSummery &&
                    pleatTypeListNew &&
                    pleatTypeListNew.find(
                        (pleatType) =>
                            pleatType.value === draperyOrderDesign.pleatType &&
                            pleatType.isDeleted === true
                    ) && (
                        <CalculatorRadioWrapperWithImg
                            uiChecked={
                                draperyOrderDesign.pleatType ===
                                this.props.data.workOrderPdfContentSummery
                                    .pleatType
                            }
                            uiDisabled={
                                this.props.workOrderEditMode
                                    ? false
                                    : this.props.disabled
                            }
                        >
                            <CalculatorImageRadio
                                type="radio"
                                id="draperyPleatType"
                                name="draperyOrderDesign.pleatType"
                                value="OTHER"
                                checked={
                                    draperyOrderDesign.pleatType === "OTHER"
                                }
                                onChange={() =>
                                    this.handleOtherPleatType(
                                        this.props.data
                                            .workOrderPdfContentSummery
                                            .otherPleatImageId
                                    )
                                }
                                disabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                                uiDisabled={
                                    this.props.workOrderEditMode
                                        ? false
                                        : this.props.disabled
                                }
                            />
                            {this.props.data.workOrderPdfContentSummery
                                .otherPleatImageId && (
                                <CalculatorImageRadioImg
                                    src={
                                        Config.fileStoreUrl +
                                        this.props.data
                                            .workOrderPdfContentSummery
                                            .otherPleatImageId
                                    }
                                    alt=""
                                    style={{ width: "133px", height: "72px" }}
                                />
                            )}
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    margin: this.props.data
                                        .workOrderPdfContentSummery
                                        .otherPleatImageId
                                        ? "0 0 0 8px"
                                        : "auto",
                                }}
                            >
                                {(() => {
                                    const pleat =
                                        this.props.draperyItemDetail[0].pleatTypesWithPricing.find(
                                            (pleat: any) =>
                                                pleat.id ===
                                                this.props.data
                                                    .workOrderPdfContentSummery
                                                    .pleatType
                                        );
                                    return pleat
                                        ? pleat.name
                                        : "Pleat Type Not Found";
                                })()}
                            </span>
                        </CalculatorRadioWrapperWithImg>
                    )}
                {/* <AddButton
                            title="Other"
                            onClick={openPleatModal}
                    />     */}
                {/* <>
                    {this.state.isPleatModalOpen && (
                    <PleatModal
                        isPleatModalOpen={this.state.isPleatModalOpen}
                        onPleatModalClose={closePleatModal}
                        dispatch={this.props.dispatch} 
                        formFields={this.props.draperyItemDetail}  
                        draperyItemDetailsFields={draperyItemDetailsFields}
                        itemId={this.props.data.draperyItemDetail[0].itemId}   
                        pleatTypeListNew={this.state.pleatTypeListNew}                                   
                    />
                    )}        
                    </> */}
            </CalculatorRadioGroupWrap>
        );
    };

    private renderPanelWidthSelect = (i: number, name: string) => {
        const { draperyOrderDesign } = this.props;
        const width =
            draperyOrderDesign.panelJson &&
            draperyOrderDesign.panelJson[`pannel${i + 1}Width`];
        const value = width === undefined ? "" : width.toString();
        return (
            <PanelWidthSelectWrap>
                <SelectList
                    label={""}
                    selectList={this.state.widthList}
                    handleClickSelect={(value, name) => {
                        this.props.handleSelectListChange(+value, name);
                    }}
                    valueSelect={value}
                    flexDirection={"column"}
                    name={`draperyOrderDesign.panelJson.pannel${i + 1}Width`}
                    disabled={
                        this.props.workOrderEditMode
                            ? false
                            : this.props.disabled
                    }
                    smallPadding
                    noClearBox
                    noArrow
                    editable
                    type="number"
                    panelCustomWidths
                />
                <PrependAppendInputGroupLabel>
                    Width(s)
                </PrependAppendInputGroupLabel>
            </PanelWidthSelectWrap>
        );
    };

    private renderPanelWidthContainer = (selectedPanel: PanelConf) => {
        const { highlightedSection } = this.props;
        switch (selectedPanel.name) {
            case "center":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={32}
                        right={32}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                    </PanelWidthContainer>
                );
            case "left":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={25}
                        right={45}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                    </PanelWidthContainer>
                );
            case "right":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={45}
                        right={25}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                    </PanelWidthContainer>
                );
            case "pair":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={25}
                        right={25}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                        {this.renderPanelWidthSelect(1, selectedPanel.name)}
                    </PanelWidthContainer>
                );
            case "three-panels":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={23}
                        right={25}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                        {this.renderPanelWidthSelect(1, selectedPanel.name)}
                        {this.renderPanelWidthSelect(2, selectedPanel.name)}
                    </PanelWidthContainer>
                );
            case "four-panels":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={15}
                        right={17}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                        {this.renderPanelWidthSelect(1, selectedPanel.name)}
                        {this.renderPanelWidthSelect(2, selectedPanel.name)}
                        {this.renderPanelWidthSelect(3, selectedPanel.name)}
                    </PanelWidthContainer>
                );
            case "five-panels":
                return (
                    <PanelWidthContainer
                        style={
                            highlightedSection ===
                            "draperyOrderDesign.panelJson.pannel1Width"
                                ? highlightStyle
                                : {}
                        }
                        left={5}
                        right={10}
                    >
                        {this.renderPanelWidthSelect(0, selectedPanel.name)}
                        {this.renderPanelWidthSelect(1, selectedPanel.name)}
                        {this.renderPanelWidthSelect(2, selectedPanel.name)}
                        {this.renderPanelWidthSelect(3, selectedPanel.name)}
                        {this.renderPanelWidthSelect(4, selectedPanel.name)}
                    </PanelWidthContainer>
                );
        }
    };

    private renderPanelsBackground = (selectedPanel: PanelConf) => {
        switch (selectedPanel.name) {
            case "center":
                return (
                    <SingleSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
            case "left":
                return (
                    <LeftSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
            case "right":
                return (
                    <RightSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
            case "pair":
                return (
                    <PairSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
            case "three-panels":
                return (
                    <ThreePanelsSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
            case "four-panels":
                return (
                    <FourPanelsSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
            case "five-panels":
                return (
                    <FivePanelsSvg
                        category={this.props.draperyOrderDesign.category}
                        height={150}
                        hideBorder
                        viewBox={viewBox}
                    />
                );
        }
    };

    private handleCustomizeConstruction = () => {
        if (
            !this.props.draperyOrderDesign.headingType ||
            (this.props.draperyOrderDesign.headingType === "rodandrings" &&
                !this.props.draperyOrderDesign.draperyType)
        ) {
            return;
        }
        if (this.props.workOrderEditMode ? false : this.props.disabled) {
            this.props.toggleCustomizeConstruction();
            return;
        }
        this.props.toggleCustomizeConstruction();
    };

    private handleOtherPleatType = (image: string) => {
        this.props.updateStateByName("draperyOrderDesign", {
            ...this.props.draperyOrderDesign,
            otherPleatImageId: image,
        });
    };
}

export default DesignTab;
